
import React from 'react'
import PropTypes from 'prop-types'
import {  FormInput } from "shards-react";

class FormSearch extends React.Component {

    state = {
        keyword: ''
    }

    handleChange = (e) => {
        let {value} = e.target
        this.setState({
            keyword: value
        })
    }

    handleSearch = () => {
        if(this.props.onSearch) {
            this.props.onSearch(this.state.keyword)
        }
    }

    handleKeyDown = (e) => {
        if(e.key === 'Enter') {
            this.handleSearch()
        }
    }

    render() {
        let {keyword} = this.state
        return (
            <div className="input-group">
                <FormInput placeholder="ค้นหา..." value={keyword} onChange={this.handleChange} onKeyDown={this.handleKeyDown}/>
                <span className="input-group-btn">
                    <button className="btn btn-default" type="button" onClick={this.handleSearch}><i className="fa fa-search"></i> ค้นหา</button>
                </span>
            </div>

        )
    }
}


FormSearch.propTypes = {
    onSearch: PropTypes.func
}

FormSearch.defaultProps = {

}


export default FormSearch