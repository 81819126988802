import React, {useState} from 'react'
import { Formik, FieldArray } from 'formik';
import * as Yup from "yup";
import {Link} from 'react-router-dom'
import Modal from 'react-modal';
import DatePicker from 'react-datetime'
import TextareaAutosize from 'react-autosize-textarea'
import 'react-datetime/css/react-datetime.css'
import InputAutocomplete from './../common/InputAutocomplete'
import config from './../../config'
import {getCookie} from './../../utils/cookie'

const customStyles = {
    content : {
      top                   : '40%',
      left                  : '50%',
      width: '100%',
      right                 : 'auto',
      bottom                : 'auto',
    //   marginRight           : '-30%',
      transform             : 'translate(-50%, -50%)',
      zIndex: '9999'
    }
  };
  
  // Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
  Modal.setAppElement('#modal')

const calTotal = (sale) => {
    let total = sale.items ? sale.items.reduce((acc, cur) => acc + (cur.price * cur.quantity ? ((cur.price * cur.quantity) - (cur.discount || 0)) + (cur.vat_method === 'out' ? ((cur.price * cur.quantity) - (cur.discount || 0)) * 0.07 : 0) : 0) , 0) : 0
    return total.toFixed(2)
}

const FormSale = ({onSubmit, initialValues}) => {
    if(!initialValues.items) initialValues.items = [{}] 
    if(initialValues.items && initialValues.items.length === 0) initialValues.items = [{}] 
    
    const [modalIsOpen,setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function closeModal(){
        setIsOpen(false);
    }
    return (
        <Formik 
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values) => onSubmit(values)}
            validationSchema={
                Yup.object().shape({
                    customer_name: Yup.mixed().required().label('ชื่อลูกค้า'),
                    pay_with: Yup.mixed().required().label('วิธีการชำระเงิน'),
                })
            }
        >
            {({ values, handleSubmit, setFieldValue, handleChange, errors }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-5">
                                <div className={`form-group mb-3 ${errors.customer_name ? 'has-error' : ''}`}>
                                    <span className="has-float-label">
                                        <input placeholder="ชื่อลูกค้า" className="form-control" type="text" id="customer_name" name="customer_name" value={values.customer_name} onChange={handleChange}/>
                                        <label for="customer_name">ชื่อลูกค้า</label>
                                    </span>
                                    {errors.customer_name && <span className="help-block">{errors.customer_name}</span>}
                                </div>
                                <div className="form-group d-flex mb-3">
                                    <div className="flex-1 pr-1">
                                        <span className="has-float-label">
                                            <input className="form-control" id="customer_tel" type="text" placeholder="เบอร์โทร" name="customer_tel" value={values.customer_tel} onChange={handleChange}/>
                                            <label for="customer_tel">เบอร์โทร</label>
                                        </span>
                                    </div>
                                    <div className="flex-1 pl-1">
                                        <span className="has-float-label">
                                            <input placeholder="อีเมล" className="form-control" type="email" id="customer_email" name="customer_email" value={values.customer_email} onChange={handleChange}/>
                                            <label for="customer_email">อีเมล</label>
                                        </span>
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <span className="has-float-label">
                                        <TextareaAutosize rows={3} name="customer_address" placeholder="ที่อยู่..." value={values.customer_address} className="form-control" onChange={handleChange} ></TextareaAutosize>
                                        <label for="customer_address">ที่อยู่</label>
                                    </span>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="form-group mb-3">
                                    <span className="has-float-label">
                                        <DatePicker value={values.sale_date ? new Date(values.sale_date) : new Date()} onChange={(v) => setFieldValue('sale_date', v.format("YYYY-MM-DD HH:mm:ss"))}/>
                                        <label for="sale_date">วันที่</label>
                                    </span>
                                </div>
                                <div>
                                    <div className={`${errors.pay_with ? 'has-error' : ''}`}>วิธีการชำระเงิน</div>
                                </div>
                                <div className={`mb-3 ${errors.pay_with ? 'has-error' : ''}`}>
                                    <input type="radio" name="pay_with" value="cash" checked={values.pay_with === 'cash'} onChange={handleChange}/> เงินสด
                                    {' '}
                                    <input type="radio" name="pay_with" value="credit" checked={values.pay_with === 'credit'} onChange={handleChange}/> เงินเชื่อ
                                    {errors.pay_with && <span className="help-block">{errors.pay_with}</span>}
                                </div>
                                {
                                    values.pay_with === 'credit' ?
                                        <>
                                            <div className="form-group d-flex">
                                                <div className="flex-1 pr-1">
                                                    <span className="has-float-label">
                                                        <select className="form-control" value={values.credit_period} name="credit_period" onChange={handleChange}>
                                                            <option value="">--  --</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                            <option value="9">9</option>
                                                            <option value="10">10</option>
                                                            <option value="11">11</option>
                                                            <option value="12">12</option>
                                                        </select>
                                                        <label for="credit_period">แบ่งจ่าย (งวด)</label>
                                                    </span>
                                                    {errors.credit_period && <span className="help-block">{errors.credit_period}</span>}
                                                </div>
                                                <div className="flex-1 pl-1">
                                                    <span className="has-float-label">
                                                        <input type="text" className="form-control text-right"  disabled placeholder="ยอดต่องวด" value={ values.credit_period && calTotal(values) ? (calTotal(values) / values.credit_period).toFixed(2) : ''}/>
                                                        <label for="credit_period">ยอดต่องวด</label>
                                                    </span>
                                                </div>
                                            </div>
                                        </>
                                    : null
                                }
                            </div>
                            <div className="col-sm-3">

                                {/* <div className="form-group" style={{marginBottom: 5}}>
                                    <div>วิธีคำนวณภาษีมูลค่าเพิ่ม (VAT)</div>
                                </div>
                                <div className="form-group" style={{marginBottom: 5, display: 'flex'}}>
                                    <div style={{flex: 1}}>
                                        <Radio
                                            name="vat_method"
                                            radioClass="iradio_minimal-blue"
                                            label=" ไม่คิดภาษี "
                                            value={'no'}
                                            checked={values.vat_method === 'no' ? true : false}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div style={{flex: 1}}>
                                        <Radio
                                            name="vat_method"
                                            radioClass="iradio_minimal-blue"
                                            label=" รวมภาษี "
                                            value={'in'}
                                            checked={values.vat_method === 'in' ? true : false}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div style={{flex: 1}}>
                                        <Radio
                                            name="vat_method"
                                            radioClass="iradio_minimal-blue"
                                            label=" แยกภาษี "
                                            value={'out'}
                                            checked={values.vat_method === 'out' ? true : false}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <FieldArray
                            name="items"
                            render={arrayHelpers => (
                                <div>
                                    <table className="table table-bordered" style={{marginBottom: 10}}>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th width="15%">รหัสสินค้า</th>
                                                <th width="15%">สินค้า</th>
                                                <th width="10%" className="text-center">จำนวน</th>
                                                <th width="15%" className="text-center">ราคาต่อหน่วย</th>
                                                <th width="12%" className="text-center">ส่วนลด</th>
                                                <th width="10%" className="text-center">รวม</th>
                                                <th width="10%" className="text-center">Vat</th>
                                                <th width="10%" className="text-center">สุทธิ</th>
                                                <th width="5%"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                values.items && values.items.length > 0 ?
                                                    values.items.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td><div className="text">{index + 1}</div></td>
                                                                <td>
                                                                    <InputAutocomplete 
                                                                        type="select"
                                                                        placeholder="รหัสสินค้า..."
                                                                        items={[]}
                                                                        defaultValue={item.item_code}
                                                                        itemLabelKey={['product_full_code']}
                                                                        itemValueKey={['product_full_code']}
                                                                        onSelect={(item) => {
                                                                            if(item.id) setFieldValue(`items[${index}].product_id`, item.id);
                                                                            setFieldValue(`items[${index}].item_code`, item.product_full_code);
                                                                            setFieldValue(`items[${index}].name`, item.name);
                                                                            setFieldValue(`items[${index}].quantity`, item.quantity ? item.quantity : 1);
                                                                            setFieldValue(`items[${index}].price`, item.sell_price);
                                                                        }}
                                                                        onChange={(e, value) => setFieldValue(`items[${index}].item_code`, value)}
                                                                        isAjax={true}
                                                                        ajaxUrl="/api/products"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <InputAutocomplete 
                                                                        type="select"
                                                                        placeholder="สินค้า..."
                                                                        items={[]}
                                                                        defaultValue={item.name}
                                                                        itemLabelKey={['name']}
                                                                        itemValueKey={['name']}
                                                                        onSelect={(item) => {
                                                                            if(item.id) setFieldValue(`items[${index}].product_id`, item.id);
                                                                            setFieldValue(`items[${index}].item_code`, item.product_full_code);
                                                                            setFieldValue(`items[${index}].name`, item.name);
                                                                            setFieldValue(`items[${index}].quantity`, item.quantity ? item.quantity : 1);
                                                                            setFieldValue(`items[${index}].price`, item.sell_price);
                                                                        }}
                                                                        onChange={(e, value) => setFieldValue(`items[${index}].name`, value)}
                                                                        isAjax={true}
                                                                        ajaxUrl="/api/products"
                                                                    />
                                                                </td>
                                                                <td className="text-center">
                                                                    <input type="text" className="form-control text-right" name={`items[${index}].quantity`} value={item.quantity} onChange={handleChange}/>
                                                                </td>
                                                                <td className="text-right">
                                                                    <input type="text" className="form-control text-right" name={`items[${index}].price`} value={item.price} onChange={handleChange}/>
                                                                </td>
                                                                <td className="text-right">
                                                                    <input type="text" className="form-control text-right" name={`items[${index}].discount`} value={item.discount} onChange={handleChange}/>
                                                                </td>
                                                                <td className="text-right">
                                                                    <div className="text">{item.price * item.quantity ? (item.price * item.quantity) - (item.discount || 0) : 0}</div>
                                                                </td>
                                                                <td className="text-right">
                                                                    <div className="input-group">
                                                                        <input disabled type="text" className="form-control text-right" name={`items[${index}].vat`} value={(item.price * item.quantity) && item.vat_method === 'out' ? (item.price * item.quantity) * 0.07 : ''} onChange={handleChange}/>
                                                                        <span className="input-group-btn">
                                                                            <button 
                                                                                className="btn btn-default" 
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    setFieldValue(`items[${index}].vat_method`, item.vat_method === 'out' ? 'no' : 'out');
                                                                                    setFieldValue(`items[${index}].vat`, item.vat_method === 'no' ? 0 : (item.price * item.quantity ? (item.price * item.quantity) * 0.07 : 0) );
                                                                                }}
                                                                            >
                                                                                <i className={`fa fa-${ item.vat_method && item.vat_method === 'out' ? 'check' : 'remove'}`}></i>
                                                                            </button>
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td className="text-right">
                                                                    <div className="text">{item.price * item.quantity ? (item.vat_method === 'out' ? ((item.price * item.quantity) - (item.discount || 0)) + (( (item.price * item.quantity) - (item.discount || 0) ) * 0.07) : (item.price * item.quantity) - (item.discount || 0)) : 0}</div>
                                                                </td>
                                                                <td className="text-right">
                                                                    <button className="btn btn-danger" type="button" onClick={() => arrayHelpers.remove(index)}><i className="fa fa-remove"></i></button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                : null
                                            }
                                        </tbody>
                                    </table>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <button className="btn btn-default" onClick={() => arrayHelpers.push({})} type="button"><i className="fa fa-plus"></i> เพิ่มรายการ</button>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="row">
                                                <div className="col-sm-6 text-right">
                                                    ยอดรวม
                                                </div>
                                                <div className="col-sm-6 text-right" style={{paddingRight: 30, fontSize: '1.8rem'}}>
                                                    {calTotal(values)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        />
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <Link className="btn btn-default" to="/sales"><i className="fa fa-ban"></i> ยกเลิก</Link>
                                {' '}
                                <button type="submit" className="btn btn-primary"><i className="fa fa-save"></i> บันทึก</button>
                                {' '}
                                {
                                    values.id ?
                                        <button type="button" onClick={openModal} className="btn btn-primary"><i className="fa fa-print"></i> พิมพ์</button>
                                    : null
                                }
                                <Modal
                                    isOpen={modalIsOpen}
                                    onAfterOpen={afterOpenModal}
                                    onRequestClose={closeModal}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                >
                                    <div>
                                        <iframe id="iPrint" style={{ overflow: 'hidden', height: 600, width: '100%', border: 0}} allowFullScreen height="100%" width="100%" src={`${config.BASE_API}/print/sales/${values.id}?act=${getCookie("token")}`} frameBorder="0">Loading...</iframe>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 text-right">
                                            <button className="btn btn-primary" type="button" onClick={() => document.getElementById('iPrint').contentWindow.print()}><i className="fa fa-print"></i> พิมพ์</button>
                                            {' '}
                                            <button className="btn btn-default" type="button" onClick={closeModal}><i className="fa fa-remove"></i> ปิด</button>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </form>
                );
            }} 
        </Formik>
        
    )
}

export default FormSale