import types from './../actionTypes'
import config from './../../config'
import {getCookie} from './../../utils/cookie'
import axios from 'axios'
import swal from 'sweetalert'

export const AddSaleItem = (item, goToSale) => {
    return async (dispatch) => {
        dispatch({
            type: types.SALE_ADD_ITEM,
            payload: {
                product_id: item.id,
                item_code: item.product_full_code,
                name: item.name,
                price: item.sell_price,
                quantity: 1,
            }
        });
        swal("เพิ่มรายการขายเสร็จสิน", {
            icon: "success",
            buttons: ["ปิด", "ไปหน้าขายสินค้า"],
        })
        .then((willDelete) => {
            if (willDelete) {
                goToSale()
            } 
        });
        
    }
}

export function NewSale() {
    return (dispatch) => {
        dispatch({
            type: types.SALE_NEW
        })
    }
}

export function FetchSales(query) {
    return async (dispatch) => {
        // try {
            dispatch({
                type: types.SALE_PENDING,
                payload: null
            })

            axios.get(config.BASE_API + '/api/sales', {
                params: {
                    // '_limit': 10,
                    ...query
                },
                headers: {
                    Authorization: `Bearer ${getCookie("token")}`
                }
            })
            .then(response => {
                dispatch({
                    type: types.FETCH_SALES_SUCCESS,
                    payload: response.data
                });
            })
            .catch(error => {
                // Handle error.
                console.log('An error occurred:', error);
            });
       
    }
}


export function FetchSaleInstallments(query) {
    return async (dispatch) => {
        // try {
            dispatch({
                type: types.SALE_PENDING,
                payload: null
            })

            axios.get(config.BASE_API + '/api/sales/installments', {
                params: {
                    // '_limit': 10,
                    ...query
                },
                headers: {
                    Authorization: `Bearer ${getCookie("token")}`
                }
            })
            .then(response => {
                dispatch({
                    type: types.FETCH_SALES_SUCCESS,
                    payload: response.data
                });
            })
            .catch(error => {
                // Handle error.
                console.log('An error occurred:', error);
            });
       
    }
}


export const FetchSaleByID = (id) => {
    return async (dispatch) => {
        dispatch({
            type: types.SALE_PENDING,
            payload: null
        })
        axios.get(config.BASE_API + '/api/sales/' + id, {
            headers: {
                Authorization: `Bearer ${getCookie("token")}`
            }
        })
        .then(response => {
            dispatch({
                type: types.FETCH_SALE_SUCCESS,
                payload: {
                    sale: response.data
                }
            });
        })
        .catch(error => {
          // Handle error.
          console.log('An error occurred:', error);
        });
    }
}

export const StoreSale = (data) => {
    return async (dispatch) => {

        dispatch({
            type: types.SALE_PENDING,
            payload: null
        });
    
        axios.post(config.BASE_API + '/api/sales', data, {
            headers: {
                Authorization: `Bearer ${getCookie("token")}`
            }
        })
        .then(response => {

            dispatch({
                type: types.STORE_SALE_SUCCESS,
                payload: {
                    sale: response.data && response.data.data ? response.data.data : {}
                }
            });

        })
        .catch(error => {
            // Handle error.
            console.log('An error occurred:', error);
        });
    }
}


export const StoreSaleInstallment = (data) => {
    return async (dispatch) => {

        dispatch({
            type: types.SALE_PENDING,
            payload: null
        });
    
        return axios.post(config.BASE_API + '/api/sales/installments', data, {
            headers: {
                Authorization: `Bearer ${getCookie("token")}`
            }
        })
        .then(response => {

            dispatch({
                type: types.STORE_SALE_SUCCESS,
                payload: {
                    sale: response.data && response.data.data ? response.data.data : {}
                }
            });

            return response.data

        })
        .catch(error => {
            // Handle error.
            console.log('An error occurred:', error);
        });
    }
}


export const UpdateSale = (data) => {
    return async (dispatch) => {

        dispatch({
            type: types.SALE_PENDING,
            payload: null
        });
    
        axios.put(config.BASE_API + '/api/sales/' + data.id, data, {
            headers: {
                Authorization: `Bearer ${getCookie("token")}`
            }
        })
        .then(response => {
            dispatch({
                type: types.STORE_SALE_SUCCESS,
                payload: {
                    sale: response.data && response.data.data ? response.data.data : {}
                }
            });

        })
        .catch(error => {
            // Handle error.
            console.log('An error occurred:', error);
        });
    }
}


export const DeleteSale = (data) => {
    return async (dispatch) => {
        dispatch({
            type: types.SALE_PENDING,
            payload: null
        });

        axios.delete(config.BASE_API + '/api/sales/' + data.id, {
            headers: {
                Authorization: `Bearer ${getCookie("token")}`
            }
        })
        .then(response => {
            dispatch({
                type: types.STORE_SALE_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
          // Handle error.
          console.log('An error occurred:', error);
        });
    }
}