import initialState from './initialState'
import types from './../actionTypes'

export default (state = initialState.reportStore, action = {}) => {

    switch(action.type) {

        case types.REPORT_PENDING: {
            return {
                ...state,
                is_pending: true,
                is_success: false,
                is_fail: false
            }
        }

        case types.FETCH_REPORT_SALE: {
            return {
                ...state,
                is_pending: true,
                is_success: false,
                is_fail: false,
                sale: action.payload.sale
            }
        }

        default: 
            return state
    }
}