import types from './../actionTypes'
import config from './../../config'
import {getCookie} from './../../utils/cookie'
import axios from 'axios'
// import swal from 'sweetalert'

export const FetchAccountings = (params) => {
    return async (dispatch) => {
        // try {
            dispatch({
                type: types.ACCOUNTING_PENDING,
                payload: null
            })

            axios.get(config.BASE_API + '/api/accountings', {
                params: {
                    ...params
                },
                headers: {
                    Authorization: `Bearer ${getCookie("token")}`
                }
            })
            .then(res => res.data)
            .then(response => {
                dispatch({
                    type: types.FETCH_ACCOUNTINGS_SUCCESS,
                    payload: {
                        accountings: response.data,
                        accountings_count: response.total
                    }
                });
            })
            .catch(error => {
                // Handle error.
                console.log('An error occurred:', error);
            });
       
    }
}

export const FetchAccountingByID = (id) => {
    return async (dispatch) => {
        // try {
            dispatch({
                type: types.ACCOUNTING_PENDING,
                payload: null
            })

            axios.get(config.BASE_API + '/api/accountings/' + id, {
                headers: {
                    Authorization: `Bearer ${getCookie("token")}`
                }
            })
            .then(response => {
                dispatch({
                    type: types.FETCH_ACCOUNTING_SUCCESS,
                    payload: {
                        accounting: response.data
                    }
                });
            })
            .catch(error => {
                // Handle error.
                console.log('An error occurred:', error);
            });
       
    }
}


export const StoreAccounting = (data) => {
    return async (dispatch) => {

        dispatch({
            type: types.ACCOUNTING_PENDING,
            payload: null
        });
    
        axios.post(config.BASE_API + '/api/accountings', data, {
            headers: {
                Authorization: `Bearer ${getCookie("token")}`
            }
        })
        .then(response => {

            dispatch({
                type: types.STORE_ACCOUNTING_SUCCESS,
                payload: {
                    accouting: response.data && response.data.data ? response.data.data : {}
                }
            });

        })
        .catch(error => {
            // Handle error.
            console.log('An error occurred:', error);
        });
    }
}

export const StoreAccountingMultiple = (data) => {
    return async (dispatch) => {

        dispatch({
            type: types.ACCOUNTING_PENDING,
            payload: null
        });
    
        axios.post(config.BASE_API + '/api/accountings/multiple', data, {
            headers: {
                Authorization: `Bearer ${getCookie("token")}`
            }
        })
        .then(response => {

            dispatch({
                type: types.STORE_ACCOUNTING_SUCCESS,
                payload: {
                    accouting: response.data && response.data.data ? response.data.data : {}
                }
            });

        })
        .catch(error => {
            // Handle error.
            console.log('An error occurred:', error);
        });
    }
}


export const UpdateAccounting = (data) => {
    return async (dispatch) => {

        dispatch({
            type: types.ACCOUNTING_PENDING,
            payload: null
        });
    
        axios.put(config.BASE_API + '/api/accountings/' + data.id, data, {
            headers: {
                Authorization: `Bearer ${getCookie("token")}`
            }
        })
        .then(response => {
            dispatch({
                type: types.STORE_ACCOUNTING_SUCCESS,
                payload: {
                    sale: response.data && response.data.data ? response.data.data : {}
                }
            });

        })
        .catch(error => {
            // Handle error.
            console.log('An error occurred:', error);
        });
    }
}
