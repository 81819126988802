import React from "react";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import numeral from 'numeral'
import actions from './../redux/actions'
import ChartSaleSummary from './../components/report/ChartSaleSummary'

class Dashboard extends React.Component {

	componentDidMount() {
        this.props.actions.SetTitle({
            title: 'Dashboard',
            subtitle: '',
            breadcrumbs: [
                {active: true, label: 'Dashboard'}
            ]
        })
        this.props.actions.FetchReportSale()
	}

	render() {
        const {sale} = this.props

		return (
            <>
                <div className="row">
                    <div className="col-sm-3">
                        <div className="info-box">
                            <span className="info-box-icon bg-green"><i className="fa fa-shopping-cart"></i></span>

                            <div className="info-box-content">
                                <span className="info-box-text">รายได้รวม</span>
                                <span className="info-box-number">{sale.sale_total ? numeral(sale.sale_total).format('0,0.00') : ''}</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-3">
                        <div className="info-box">
                            <span className="info-box-icon bg-aqua"><i className="fa fa-file-text"></i></span>

                            <div className="info-box-content">
                                <span className="info-box-text">จำนวนรายการขายทั้งหมด</span>
                                <span className="info-box-number">{sale.sale_order_total ? numeral(sale.sale_order_total).format('0,0') : ''}</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="box box-primary">
                            <div className="box-header with-border">
                                <h3 className="box-title">รายได้รวม</h3>
                            </div>

                            <div className="box-body">
                                <ChartSaleSummary data={sale.sale_months}/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
		)
	}
}


const mapStateToProps = (state, ownProps) => {
    return {
        sale: state.reportStore.sale,
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
