import React from 'react'
import { FormInput } from "shards-react";

const FieldInput = ({label, name, value, error, type, handleChange}) => {

    return (
        <div className={`row form-group ${error ? 'has-error' : ''}`}>
            <div className="col-sm-4 control-label text-right">{label}</div>
            <div className="col-sm-8">
                <FormInput name={name} type={type} placeholder={label} value={value} className="form-control mb-3" onChange={handleChange} />
                {error && <span className="help-block">{error}</span>}
            </div>
        </div>
    )
}

export default FieldInput