import React from "react";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import actions from './../../redux/actions'
import FormPurchase from './../../components/purchase/FormPurchase'
import swal from 'sweetalert'

class PurchaseForm extends React.Component {

    componentDidMount() {
        const { match } = this.props;
        this.props.actions.NewPurchase()
        this.props.actions.SetTitle({
            title: 'เพิ่มรายการ',
            subtitle: '',
            breadcrumbs: [
                {path: '/purchases', label: 'รายการซื้อ'},
                {active: true, label: 'เพิ่มรายการ'}
            ]
        })
        if(match.params.id) {
            this.props.actions.FetchPurchaseByID(match.params.id)
        } 
    }

    componentWillReceiveProps = (nextProps) => {
        if(nextProps.is_success) {
            swal("บัยทึกข้อมูลเสร็จสิ้น!", {
                icon: "success",
            });
            this.props.history.push('/purchases')
        }
    }
    
    submit = (values) => {
        const { match } = this.props;
        if(match.params.id) {
            this.props.actions.UpdatePurchase(values)
        } else {
            this.props.actions.StorePurchase(values)
        }
    }

    render() {
        let { is_pending, purchase } = this.props
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="box box-default" id="frmProduct">
                        <div className="box-header with-border">
                            <h3 className="box-title"> {purchase && purchase.id ? 'แก้ไขรายการซื้อ' : 'ซื้อสินค้า'}</h3>
                        </div>
                        <div className="box-body">
                            <FormPurchase onSubmit={this.submit} initialValues={purchase}/>
                        </div>
                        {
                            is_pending &&
                            <div className="overlay">
                                <i className="fa fa-refresh fa-spin"></i>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        purchase: state.purchasesStore.purchase,
        is_success: state.purchasesStore.is_success,
        is_pending: state.purchasesStore.is_pending,
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseForm);