import initialState from './initialState'
import types from './../actionTypes'

export default (state = initialState.producttypeStore, action = {}) => {

    switch(action.type) {

        case types.PRODUCT_TYPE_PENDING: {
            return {
                ...state,
                pending: true,
                is_success: false
            }
        }

        case types.PRODUCT_TYPE_FORM_CHANGE: {

            return {
                ...state,
                producttype: action.payload
            }
        }

        case types.NEW_PRODUCT_TYPE: {

            return {
                ...state,
                producttype: {},
                pending: false,
                is_success: false
            }
        }

        case types.FETCH_PRODUCT_TYPES_SUCCESS: {
            return {
                ...state,
                pending: false,
                producttypes: action.payload.data,
                producttypes_count: action.payload.total,
            }
        }

        case types.FETCH_PRODUCT_TYPE_SUCCESS: {
            return {
                ...state,
                pending: false,
                is_success: false,
                producttype: action.payload,
                form: action.payload,
            }
        }

        case types.STORE_PRODUCT_TYPE_SUCCESS: {
            return {
                ...state,
                pending: false,
                is_success: true,
                producttype: action.payload
            }
        }

        default: 
            return state
    }
}