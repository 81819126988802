import React from "react";
import {Link} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import swal from 'sweetalert'
import dayjs from 'dayjs'
import actions from './../../redux/actions'
import Pagination from './../../components/common/Pagination'
import FormSearch from './../../components/common/FormSearch'

class SaleList extends React.Component {

	state = {
        page: 1,
        toggleItem: {
            date: { label: 'วันที่', active: true },
            code: { label: 'รหัสรายการ', active: true },
            customer_name: { label: 'ชื่อลูกค้า', active: true },
            pay_with: { label: 'ชำระด้วย', active: true },
            total: { label: 'ยอดรวม', active: true },
        },
	}

	componentDidMount() {
        this.props.actions.SetTitle({
            title: 'รายการขาย',
            subtitle: '',
            breadcrumbs: [
                {active: true, label: 'รายการขาย'}
            ]
        })
		this.props.actions.FetchSales()
	}

	
	handlePageClick = (obj) => {
		// let {caregivers_count} = this.state
		// let start = (caregivers_count - (caregivers_count - ((obj.selected + 1) * 10))) - 10
		this.props.actions.FetchSales({'page': obj.selected + 1})
    }
    
    allToggle = (e) => {
        let {toggleItem} = this.state
        Object.keys(this.toggleItem).forEach((t) => {
            this.toggleItem[t].active = e.target.checked
        })
        this.setState({ toggleItem })
    }

    changeToggle = (k) => {
        let {toggleItem} = this.state
        toggleItem[k].active = !toggleItem[k].active
        this.setState({ toggleItem })
    }

    deleteData = (item) => {
        swal({
            title: "Are you sure?",
            text: "Confirm delete!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                this.props.actions.DeleteSale(item)
                swal("Your data has been deleted!", {
                    icon: "success",
                });
                this.props.actions.FetchSales()
            }
        });
    }

    calTotal = (item) => {
        let total = item.items ? item.items.reduce((acc, cur) => acc + (cur.price * cur.quantity ? ((cur.price * cur.quantity) - (cur.discount || 0)) + (cur.vat_method === 'out' ? ((cur.price * cur.quantity) - (cur.discount || 0)) * 0.07 : 0) : 0) , 0) : 0
        return total.toFixed(2)
    }

	render() {
        let {toggleItem} = this.state
        let {is_pending, sales, sales_count} = this.props
		return (
			<div className="row">
                <div className="col-sm-12">
                    <div className="box box-default">
                        <div className="box-header with-border">
                            <h3 className="box-title">รายการขาย</h3>
                        </div>

                        <div className="box-body">
                            <div className="row form-group">
                                <div className="col-sm-6">
                                    <FormSearch onSearch={(keyword) => this.props.actions.FetchSales({search: keyword})}/>
                                </div>
                                <div className="col-sm-6 text-right">
                                    <Link to="/sales/create" className="btn btn-primary"><i className="fa fa-plus"></i> เพิ่มรายการ</Link>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12" style={{position: 'relative'}}>
                                    <div style={{position: 'absolute', top: '0px', right: '0px'}}>
                                        <div className="btn-group" role="group" style={{position: 'relative'}}>
                                            <button type="button" className="btn btn-default" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></button>
                                            <ul className="dropdown-menu pull-right">
                                                <li style={{padding: '5px 10px'}}>
                                                    <input type="checkbox" name="all" value="on" onChange={this.allToggle}/> All
                                                </li>
                                                {
                                                    Object.keys(toggleItem).map(item => (
                                                        <li style={{padding: '5px 10px'}} key={item}>
                                                            <input type="checkbox" name="item" checked={toggleItem[item].active} value="on" onChange={(e) => this.changeToggle(item)}/>
                                                            {toggleItem[item].label}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="table-responsive" style={{minHeight: 500}}>
                                        <table id="tbProduct" className="table table-bordered table-hover" cellSpacing="0" width="100%">
                                            <thead>
                                                <tr>
                                                    {toggleItem.date.active && <th>วันที่</th> }
                                                    {toggleItem.code.active && <th>รหัสรายการ</th> }
                                                    {toggleItem.customer_name.active && <th>ชื่อลูกค้า</th>}
                                                    {toggleItem.pay_with.active && <th className="">ชำระด้วย</th>}
                                                    {toggleItem.total.active && <th className="text-right">ยอดรวม</th>}
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    sales && sales.length > 0 ? 
                                                        sales.map((item, index) => {
                                                            return (
                                                                <tr key={item.id} >
                                                                    {toggleItem.date.active && <td style={{whiteSpace: 'nowrap'}} className="text-left"><div className="text">{item.sale_date ? dayjs(item.sale_date).format("DD/MM/YYYY HH:mm:ss") : ''}</div></td>}
                                                                    {toggleItem.code.active && <td style={{whiteSpace: 'nowrap'}} className="text-left"><div className="text">{item.sale_full_code}</div></td>}
                                                                    {toggleItem.customer_name.active && <td  className="text-left"><div className="text">{item.customer_name}</div></td>}
                                                                    {toggleItem.pay_with.active && <td className="text-left"><div className="text">{item.pay_with === 'cash' ? 'เงินสด' : (item.pay_with === 'credit' ? 'เงินเชื่อ' : '') }</div></td>}
                                                                    {toggleItem.total.active && <td className="text-right"><div className="text">{this.calTotal(item)}</div></td>}
                                                                    <td className="text-right">
                                                                        <div className="btn-group" style={{whiteSpace: 'nowrap'}}>
                                                                            <Link to={'/sales/edit/'+item.id} className="btn btn-default">View</Link>
                                                                            <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                                                <span className="caret"></span>
                                                                                <span className="sr-only">Toggle Dropdown</span>
                                                                            </button>
                                                                            <ul className="dropdown-menu" role="menu">
                                                                                <li><Link to={'/sales/edit/'+item.id}><i className="fa fa-pencil"></i> Edit</Link></li>
                                                                                <li><a onClick={() => this.deleteData(item)}><i className="fa fa-trash"></i> Del</a></li>
                                                                            </ul>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    : 
                                                        <tr>
                                                            <td className="danger" colSpan="18"> Data not found</td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                {/* <div className="col-sm-6" style="padding: 6px 20px;">Showing {{meta.from}} to {{meta.to}} of {{meta.total}} entries</div> */}
                                <div className="col-sm-6"></div>
                                <div className="col-sm-6 text-right">    
                                    <Pagination
                                        itemCount={sales_count}
                                        rowCount={10}
                                        onPageChange={this.handlePageClick}
                                    />
                                </div>
                            </div>
                        </div>

                        {
                            is_pending &&
                            <div className="overlay">
                                <i className="fa fa-refresh fa-spin"></i>
                            </div>
                        }

                    </div>

                       
			    </div>
			</div>
		)
	}
}
    


SaleList.propTypes = {

};

SaleList.defaultProps = {
  
};


const mapStateToProps = (state, ownProps) => {
	return {
		is_pending: state.salesStore.is_pending,
		sales: state.salesStore.sales,
		sales_count: state.salesStore.sales_count,
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	}
}
  
export default connect(mapStateToProps, mapDispatchToProps)(SaleList)
