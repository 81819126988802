import { EventEmitter } from "events";
import Dispatcher from "./../dispatcher";
import Constants from "./../constants";
import { setCookie } from "../../utils/cookie";

let _store = {
    login_pending: false,
    token: null,
    user: {},
    form: {}
};

class AuthenticateStore extends EventEmitter {
    constructor() {
		super();

		this.registerToActions = this.registerToActions.bind(this);
		this.authenticatePending = this.authenticatePending.bind(this);
		this.authenticateSuccess = this.authenticateSuccess.bind(this);
		this.handleChangeForm = this.handleChangeForm.bind(this);
		this.setUser = this.setUser.bind(this);

		Dispatcher.register(this.registerToActions.bind(this));
    }

    registerToActions({ actionType, payload }) {
		switch (actionType) {
			case Constants.AUTHENTICATE_PENDING:
				this.authenticatePending()
				break;
			case Constants.AUTHENTICATE_SUCCESS:
				this.authenticateSuccess(payload)
				break;
			case Constants.AUTHENTICATE_SET_USER:
				this.setUser(payload)
				break;
			case Constants.AUTHENTICATE_FORM_CHANGE:
				this.handleChangeForm(payload)
				break;
			default:
		}
    }
    
    authenticatePending() {
        _store.login_pending = !_store.login_pending
    }

    authenticateSuccess(payload) {
        _store.login_pending = !_store.login_pending
        _store.token = payload.token
        // _store.user = payload.user
		setCookie("token", payload.token, 1)
		setCookie("api_token", payload.token, 1)
		setCookie("laravel_token", payload.token, 1)
		this.emit(Constants.AUTHENTICATE_SUCCESS);
	}

	setUser(payload) {
		_store.user = payload
		this.emit(Constants.AUTHENTICATE_SET_USER);
	}
	
	handleChangeForm(payload) {
		_store.form = payload
	}

	getFormState() {
		return _store.form;
	}

	getTokenState() {
		return _store.token
	}

	getUserState() {
		return _store.user
	}

	addChangeListener(event, callback) {
		this.on(event, callback);
	}

	removeChangeListener(event, callback) {
		this.removeListener(event, callback);
	}
}

export default new AuthenticateStore()