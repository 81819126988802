import React, {useEffect} from "react"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import FormLogin from './../components/login/FormLogin'
import actions from './../redux/actions'

const Login = ({ actions, is_success, is_error, message }) => {

    useEffect(() => {
        document.body.classList.add('login-page');
        document.body.classList.remove('skin-blue');
        document.body.classList.remove('sidebar-mini');
        document.body.classList.remove('fixed');
    }, [])

    useEffect(() => {
        if(is_success) {
            setTimeout(() => {
                window.location = '/'
            }, 200)
        }
    }, [is_success])

    const submit = (values) => {
        actions.AuthLogin(values)
    }

    return (
        <div className="login-box-body">
            <p className="login-box-msg">Sign in to start your session</p>
            <FormLogin
                onSubmit={submit}
                initialValues={{}}
                success={is_success}
                error={is_error}
                message={message}
            />
        </div>
    )
}


const mapStateToProps = (state, ownProps) => {
    console.log(state.authStore)
    const {is_success, is_error, message} = state.authStore
	return {
        is_success,
        is_error,
        message
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	}
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Login)
