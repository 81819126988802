import React from "react";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import actions from '../../redux/actions'
import FormSetting from './../../components/setting/FormSetting'
import swal from 'sweetalert'

class Setting extends React.Component {

    componentDidMount = () => {
        this.props.actions.SetTitle({
            title: 'ตั้งค่า',
            subtitle: '',
            breadcrumbs: [
                {active: true, label: 'ตั้งค่า'}
            ]
        })
        this.props.actions.FetchSettings()
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.is_success) {
            swal("", "บันทึกเสร็จสิ้น", "success");
        }
    }

    submit = (values) => {
        this.props.actions.UpdateSetting(values)
    }

    render() {
        let {setting} = this.props
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="box box-default">
                        <div className="box-header with-border">
                            <h3 className="box-title">ตั้งค่า</h3>
                        </div>

                        <div className="box-body">
                            <FormSetting 
                                onSubmit={this.submit} 
                                initialValues={setting} 
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
} 


Setting.propTypes = {

};

Setting.defaultProps = {

};


const mapStateToProps = (state, ownProps) => {
    return {
        setting: state.settingStore.setting,
        is_success: state.settingStore.is_success,
    }
}
  
  const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Setting)
