import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'

const FormLogin = ({initialValues, onSubmit, error, success, message}) => {
    return (
        <Formik 
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values) => onSubmit(values)}
            validationSchema={
                Yup.object().shape({
                    email: Yup.string().email().required().label('Email'),
                    password: Yup.string().required().label('Password')
                })
            }
        >
            {({ values, handleSubmit, handleChange, errors }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        {error && <div className="alert alert-danger">{message}</div>}
                        {success && <div className="alert alert-success">{message}</div>}
                        <div className={`form-group has-feedback ${errors.email ? 'has-error' : ''}`}>
                            <input placeholder="Email" id="email" type="text" className="form-control" name="email" onChange={handleChange} value={values.email} autoFocus/>
                            <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
                            {   
                                errors.email &&
                                <span className="help-block" role="alert">
                                    <strong>{errors.email}</strong>
                                </span>
                            }
                        </div>
                        <div className={`form-group has-feedback ${errors.password ? 'has-error' : ''}`}>
                            <input placeholder="Password" id="password" type="password" className="form-control" name="password" onChange={handleChange} value={values.password}/>
                            <span className="glyphicon glyphicon-lock form-control-feedback"></span>
                            {   
                                errors.password &&
                                <span className="help-block" role="alert">
                                    <strong>{errors.password}</strong>
                                </span>
                            }
                        </div>
                        <div className="row">
                            {/* <div className="col-xs-1"></div>
                            <div className="col-xs-6">
                                <div className="checkbox icheck">
                                    <label>
                                        <input type="checkbox" name="remember" id="remember" {{ old('remember') ? 'checked' : '' }}> Remember Me
                                    </label>
                                </div>
                            </div> */}

                            <div className="col-xs-12 text-right">
                                <button type="submit" className="btn btn-primary btn-block btn-flat">Sign In</button>
                            </div>

                        </div>
                    </form>
                )
            }}
        </Formik>
    )
}

export default FormLogin