import React from "react";
// import PropTypes from "prop-types";
import { Redirect} from 'react-router-dom'
// import ReactQuill from "react-quill";
import { Row} from "shards-react";

import FormNewProduct from './../components/product/FormNewProduct'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import actions from './../redux/actions'
// import "react-quill/dist/quill.snow.css";
// import "../../assets/quill.css";


class ProductForm extends React.Component {

    constructor(props, state) {
        super(props, state);
        this.state = {
            value: '',
        };
    }

    componentWillMount() {

    }

    componentDidMount() {
        const { match } = this.props;
        this.props.actions.SetTitle({
            title: 'Product',
            subtitle: 'Update',
            breadcrumbs: [{path: '/products', label: 'Product'}, {active: true, label: (match.params.id ? 'Update' : 'Create') +' Product'}]
        })
        this.props.actions.FetchProductTypes()
        if(match.params.id) {
            this.props.actions.FetchProductByID(match.params.id)
        } else {
            this.props.actions.NewProduct()
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if(nextProps.is_success) {
            this.props.history.push('/products')
        }
    }
    
    submit = (values) => {
        const { match } = this.props;
        let files = {
            product_image: values.file_images,
            attachment: values.file_attachments,
            receipt: values.file_receipts,
        }
        delete values.file_images
        delete values.file_attachments
        delete values.file_receipts
        if(match.params.id) {
            this.props.actions.UpdateProduct(values, files)
        } else {
            this.props.actions.StoreProduct(values, files)
        }
    }

    goToSale = () => {
        this.props.history.push('/sales/create')
    }

    render() {
        let { is_success, product, pending, producttypes } = this.props
        return (
            <div className="row">
                <div className="col-sm-12">

                    { is_success ? <Redirect to="/products" push={true} /> : ''}
                    <div className="box box-default" id="frmProduct">
                        <div className="box-header with-border">
                            <h3 className="box-title">{product.id ? 'Update' : 'Create'} Product</h3>
                        </div>
                        <div className="box-body">
                            <FormNewProduct 
                                onSubmit={this.submit} 
                                addToSaleItem={this.props.actions.AddSaleItem}
                                goToSale={this.goToSale}
                                initialValues={product} 
                                pending={pending} 
                                producttypes={producttypes}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
} 


ProductForm.propTypes = {

};

ProductForm.defaultProps = {

};


const mapStateToProps = (state, ownProps) => {
    console.log(state)
    return {
        ...state.productsStore,
        producttypes: state.producttypesStore ? state.producttypesStore.producttypes : [],
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductForm);

