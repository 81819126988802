import React from "react";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import actions from './../../redux/actions'
import FormSale from './../../components/sale/FormSale'
import swal from 'sweetalert'

class SaleForm extends React.Component {

    componentDidMount() {
        const { match } = this.props;
        this.props.actions.NewSale()
        this.props.actions.SetTitle({
            title: 'เพิ่มรายการ',
            subtitle: '',
            breadcrumbs: [
                {path: '/sales', label: 'รายการขาย'},
                {active: true, label: 'เพิ่มรายการ'}
            ]
        })
        if(match.params.id) {
            this.props.actions.FetchSaleByID(match.params.id)
        } 
    }

    componentWillReceiveProps = (nextProps) => {
        if(nextProps.is_success) {
            swal("บันทึกข้อมูลเสร็จสิ้น!", {
                icon: "success",
            });
            this.props.history.push('/sales')
        }
    }
    
    submit = (values) => {
        const { match } = this.props;
        if(match.params.id) {
            this.props.actions.UpdateSale(values)
        } else {
            this.props.actions.StoreSale(values)
        }
    }

    render() {
        let { is_pending, sale } = this.props
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="box box-default" id="frmProduct">
                        <div className="box-header with-border">
                            <h3 className="box-title"> {sale.id ? 'แก้ไขรายการขาย' : 'ขายสินค้า'}</h3>
                        </div>
                        <div className="box-body">
                            <FormSale onSubmit={this.submit} initialValues={sale}/>
                        </div>
                        {
                            is_pending &&
                            <div className="overlay">
                                <i className="fa fa-refresh fa-spin"></i>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        sale: state.salesStore.sale,
        is_success: state.salesStore.is_success,
        is_pending: state.salesStore.is_pending,
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SaleForm);