import initialState from './initialState'
import types from './../actionTypes'

export default (state = initialState.roleStore, action = {}) => {

    switch(action.type) {

        case types.ROLE_PENDING: {
            return {
                ...state,
                is_pending: true,
                is_success: false
            }
        }

        case types.FETCH_ROLES_SUCCESS: {
            return {
                ...state,
                is_pending: false,
                is_success: false,
                roles: action.payload.data,
                roles_count: action.payload.total,
            }
        }

        case types.FETCH_ROLE_SUCCESS: {
		let role = Object.assign({}, action.payload)
		role.permissions = action.payload.permissions.map(p => p.name)
            return {
                ...state,
                is_pending: false,
                is_success: false,
                role: role,
                form: action.payload,
            }
        }

        case types.STORE_ROLE_SUCCESS: {
            return {
                ...state,
                is_pending: false,
                is_success: true,
                role: action.payload
            }
        }

        default: 
            return state
    }
}
