import React from 'react'
import PropTypes from 'prop-types'

const TableList = ({headers, items, listComponent}) => {
    return (
        <table className="table mb-0">
            <thead className="bg-light">
                <tr>
                    {
                        headers && headers.length > 0 ?
                            headers.map((h, index) => (
                                <th key={index} scope="col" className="border-0">{h}</th>
                            )) 
                        : null
                    }
                </tr>
            </thead>
            <tbody>
                {
                    items && items.length > 0 ? 
                        items.map((item, index) => listComponent(item, index))
                    : 
                        <tr><td colSpan={headers.length}>Data not found</td></tr>
                }
            </tbody>
        </table>
    )
}


TableList.propTypes = {
    headers: PropTypes.array,
    items: PropTypes.array,
    listComponent: PropTypes.func
}

TableList.defaultProps = {

}

export default TableList