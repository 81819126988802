import React from "react";
// import { Redirect} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import actions from '../../redux/actions'
import FormProductReceive from '../../components/product/FormProductReceive'
import swal from 'sweetalert'

class ProductReceiveForm extends React.Component {

    constructor(props, state) {
        super(props, state);
        this.state = {
            value: '',
        };
    }

    componentWillMount() {

    }

    componentDidMount() {
        const { match } = this.props;
        this.props.actions.SetTitle({
            title: 'เพิ่มรายการ',
            subtitle: '',
            breadcrumbs: [
                {path: '/sales', label: 'รายการขาย'},
                {active: true, label: 'เพิ่มรายการ'}
            ]
        })
        if(match.params.id) {
            this.props.actions.FetchSaleByID(match.params.id)
        } else {
            // this.props.actions.NewSale()
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if(nextProps.is_success) {
            swal("บัยทึกข้อมูลเสร็จสิ้น!", {
                icon: "success",
            });
            this.props.history.push('/products')
        }
    }
    
    submit = (values) => {
        this.props.actions.StoreProductReceive(values)
    }

    render() {
        let { product_receiving } = this.props
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="box box-default" id="frmProduct">
                        <div className="box-header with-border">
                            <h3 className="box-title">รับเข้าสินค้า</h3>
                        </div>
                        <div className="box-body">
                            <FormProductReceive onSubmit={this.submit} initialValues={product_receiving}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
} 


ProductReceiveForm.propTypes = {

};

ProductReceiveForm.defaultProps = {

};


const mapStateToProps = (state, ownProps) => {
    return {
        product_receiving: state.productsStore.product_receiving,
        is_success: state.productsStore.is_success,
        is_pending: state.productsStore.is_pending,
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductReceiveForm);