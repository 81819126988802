import React, { Component } from 'react'
import FormUser from './../components/user/FormUser'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import actions from './../redux/actions'

class UserForm extends Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        const { match } = this.props;
        this.props.actions.FetchRoles()
        if(match.params.id) {
            this.props.actions.FetchUserByID(match.params.id)
        } else {
            this.props.actions.NewUser()
        }
        this.props.actions.SetTitle({
            title: 'ผู้ใช้',
            subtitle: '',
            breadcrumbs: [
                {path: '/users', label: 'ผู้ใช้'},
                {active: true, label: (match.params.id? 'แก้ไข' : 'เพิ่ม') + 'ผู้ใช้'}
            ]
        })
    }

    componentWillReceiveProps = (nextProps) => {
        if(nextProps.is_success) {
            this.props.history.push('/users')
        }
    }
    
    submit = (values) => {
        const { match } = this.props;
        if(match.params.id) {
            this.props.actions.UpdateUser(values)
        } else {
            this.props.actions.StoreUser({...values, timezone: 'Asia/Bangkok'})
        }
    }

    render() {
        let { user, roles } = this.props
        return (
            <div className="box box-primary" id="frmProduct">
                <div className="box-header with-border">
                    <h3 className="box-title">{user.id ? 'แก้ไข' : 'เพิ่ม'}ผู้ใช้</h3>
                </div>
                <div className="box-body">
                    <FormUser onSubmit={this.submit} initialValues={user} roles={roles}/>
                </div>
            </div>
        )
    }
} 

const mapStateToProps = (state, ownProps) => {
    return {
        roles: state.rolesStore.roles,
        user: state.usersStore.user,
        is_success: state.usersStore.is_success
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);