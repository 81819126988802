import React from 'react'
import PropTypes from 'prop-types'
import { InputGroupText } from "shards-react";
import Autocomplete from 'react-autocomplete'
import axios from 'axios'
import {getCookie} from './../../utils/cookie'
import config from './../../config'

class InputAutocomplete extends React.Component {

    state = {
        value: '',
        menu_visible: false,
        is_select: false,
        ajax_items: []
    }

    requestTimer = null

    componentDidMount = () => {
        this.setState({
            value: this.props.defaultValue || '',
            is_select: this.props.defaultValue ? true : false
        })
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.defaultValue !== this.props.defaultValue) {
            this.setState({
                value: nextProps.defaultValue || '',
                is_select: nextProps.defaultValue ? true : false
            })
        }
    }

    matchStateToTerm = (state, value) => {
        if(!value)
            value = ''
        return (
            state.ValueKey.toLowerCase().indexOf(value.toLowerCase()) !== -1
        )
    }

    sortStates = (a, b, value) => {
        // console.log(a)
        // console.log(b)
        // console.log(value)
        const aLower = a.ValueKey.toLowerCase()
        const bLower = b.ValueKey.toLowerCase()
        const valueLower = value.toLowerCase()
        const queryPosA = aLower.indexOf(valueLower)
        const queryPosB = bLower.indexOf(valueLower)
        if (queryPosA !== queryPosB) {
          return queryPosA - queryPosB
        }
        return aLower < bLower ? -1 : 1
    }

    onSelectItem = (item) => {
        let {type, onSelect} = this.props
        if(type === 'select') {
            this.setState({ value: item.ValueKey, is_select: true })
        } else if (type === 'multiple') {
            this.setState({ value: '' })
        }

        if(onSelect) {
            onSelect(item)
        }
    }

    handleClickClear = (e) => {
        e.preventDefault()
        this.setState({ value: '', is_select: false })
        if(this.props.onSelect) {
            this.props.onSelect({})
        }
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            let {ajax_items} = this.state
            let {value} = e.target
            let i = ajax_items.findIndex(x => x.ValueKey.toLowerCase().indexOf(value.toLowerCase()) !== -1 )
            if(i >= 0) {
                this.onSelectItem(ajax_items[i])
            }
            // if(ajax_items.length === 0) {
            //     this.getData(() => {
            //         if(ajax_items && ajax_items[ajax_items.length - 1]) {
            //             this.onSelectItem(ajax_items[ajax_items.length - 1])
            //         }
            //     })
            // }
        }
    }

    handleChange = (e, value) => {
        this.setState({ value })
        if(this.props.isAjax) {
            this.getData(value)
        }
        if(this.props.onChange) {
            this.props.onChange(e, value)
        }
    }

    getData = (val, callback) => {
        let {itemValueKey} = this.props
        // let {value} = this.state
        clearTimeout(this.requestTimer)
        let query = {}
        if(val) {
            query.search = val
        } 
        // if(Array.isArray(itemValueKey)) {
        //     itemValueKey.forEach(l => {
        //         query[l+'_contains'] = value
        //     } )
        // } else {
        //     query[itemValueKey+'_contains'] = value
        // }
        this.requestTimer = axios.get(config.BASE_API + this.props.ajaxUrl, {
            params: {
                ...query
            },
            headers: {
                Authorization: `Bearer ${getCookie("token")}`
            }
        })
        .then(response => {
            this.setState({ 
                ajax_items: response.data.data.map((itm) => {
                    let txt = ''
                    // itemValueKey.map(l => txt += itm[l]+' ' )
                    Array.isArray(itemValueKey) ? itemValueKey.map(l => txt += ' '+itm[l] ) : txt += itm[itemValueKey]
                    return {...itm, ValueKey: txt, is_highlight: false}
                })
            })
            if(callback) callback()
        })
        .catch(error => {
            // Handle error.
            console.log('An error occurred:', error);
        });
    }

    render() {
        let {placeholder, items, itemValueKey, itemLabelKey, type} = this.props
        let {menu_visible, value, is_select, ajax_items} = this.state
        
        let newItems = (items && items.length > 0) ? [...items.map(itm => {
            let txt = ''
            // itemValueKey.map(l => txt += itm[l]+' ' )
            Array.isArray(itemValueKey) ? itemValueKey.map(l => txt += ' '+itm[l] ) : txt += itm[itemValueKey]
            return {...itm, ValueKey: txt}
        }), ...ajax_items ] : ajax_items
        
        return (
            <Autocomplete
                ref={el => this.input = el}
                getItemValue={(item) => item.ValueKey}
                inputProps={{className: 'form-control', placeholder, onKeyDown: this.handleKeyDown }}
                renderMenu={children => (
                    <div className="menu" style={{zIndex: 9999, position: 'absolute', width: '100%', borderLeft: '1px solid #007bff', borderRight: '1px solid #007bff', borderBottom: '1px solid #007bff' }}>
                        {children}
                    </div>
                )}
                open={menu_visible}
                onMenuVisibilityChange={(d) => this.setState({menu_visible: d})}
                wrapperStyle={{ position: 'relative', display: 'block' }}
                selectOnBlur={true}
                renderInput={(props) => {
                    return (
                        <div className="input-group" seamless>
                            <input {...props} style={{borderRight: 0}}/>
                            
                            {
                                type && type === 'select' && is_select ? 
                                    <div className="input-group-addon" type="append" style={{padding: '6px 12px 6px 3px', borderLeft: 0, borderRight: 0, borderColor: menu_visible ? '#605ca8' : '#d2d6de'}}>
                                        <InputGroupText>
                                            <i className="fa fa-remove text-danger" onClick={this.handleClickClear} style={{cursor: 'pointer'}}></i>
                                        </InputGroupText>
                                    </div>
                                : null
                            }
                            
                            <div className="input-group-addon" style={{padding: '6px 12px 6px 3px', borderLeft: 0, borderColor: menu_visible ? '#605ca8' : '#d2d6de'}} type="append" onClick={(e) => {this.getData(null);this.setState({menu_visible: !menu_visible})}}>
                                <InputGroupText>
                                    <i style={{cursor: 'pointer'}} className={`fa ${menu_visible ? 'fa-angle-down' : 'fa-angle-left' }`}></i>
                                </InputGroupText>
                            </div>
                        </div>
                    )
                } }
                items={newItems}
                renderItem={(item, isHighlighted) =>  (
                        <div key={item.id} style={{ padding: 10, background: isHighlighted ? 'rgb(237, 245, 255, 1)' : 'white' }}>
                            {
                                Array.isArray(itemLabelKey) ?
                                    itemLabelKey.map(l => {
                                        return item[l]+' '
                                    })
                                : item[itemLabelKey]
                            }
                        </div>
                    )
                }
                sortItems={this.sortStates}
                shouldItemRender={this.matchStateToTerm}
                value={value}
                onChange={this.handleChange}
                onSelect={(val, item) => this.onSelectItem(item)}
            />
        )
    }
}

InputAutocomplete.propTypes = {
    placeholder: PropTypes.string,
    onSelect: PropTypes.func,
    onChange: PropTypes.func,
    items: PropTypes.array,
    itemValueKey: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    itemLabelKey: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    defaultValue: PropTypes.string,
    type: PropTypes.oneOf(['select', 'multiple']),
    isAjax: PropTypes.bool,
    ajaxUrl: PropTypes.string
}

InputAutocomplete.defaultProps = {
    placeholder: 'Add an item...'
}


export default InputAutocomplete