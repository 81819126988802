import React from "react";
// import classNames from "classnames";
import PropTypes from "prop-types";
import {Link} from 'react-router-dom'
// import { Row, Col } from "shards-react";

const PageTitle = ({ title, subtitle, breadcrumbs, className, ...attrs }) => {
	// const classes = classNames(
	// 	className,
	// 	"text-center",
	// 	"text-md-left",
	// 	"mb-sm-0"
	// );

	return (
		<section className="content-header">
			<h1>
				{title}
				<small>{subtitle}</small>
			</h1>
			<ol className="breadcrumb" style={{marginBottom: 0, padding: 0, backgroundColor: 'transparent'}}>
				<li className="breadcrumb-item"><Link to="/">หน้าหลัก</Link></li>
				{
					breadcrumbs && breadcrumbs.length > 0 ?
						breadcrumbs.map(breed => (
							<li key={breed.label} className={`${breed.active ? 'active' : ''} breadcrumb-item`} aria-current="page">
								{breed.path ? <Link to={breed.path}>{breed.label}</Link> : breed.label}
							</li>			
						))
					: ''
				}
			</ol>
		</section>
	)
};

PageTitle.propTypes = {
  /**
   * The page title.
   */
  title: PropTypes.string,
  /**
   * The page subtitle.
   */
  subtitle: PropTypes.string,
  breadcrumbs: PropTypes.array
};

export default PageTitle;
