import React, {Fragment} from "react"
import {Link} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import actions from './../redux/actions'
import numeral from 'numeral'
import dayjs from 'dayjs'
import DatePicker from 'react-datetime'

const months = {
    m01: 'ม.ค',
    m02: 'ก.พ',
    m03: 'มี.ค',
    m04: 'เม.ย',
    m05: 'พ.ค',
    m06: 'มิ.ย',
    m07: 'ก.ค',
    m08: 'ส.ค',
    m09: 'ก.ย',
    m10: 'ต.ค',
    m11: 'พ.ย',
    m12: 'ธ.ค',
}

class AccountingList extends React.Component {

	state = {
        page: 1,
        keyword: dayjs().format("YYYY-MM")
	}

	componentDidMount() {
        this.props.actions.SetTitle({
            title: 'บัญชี',
            subtitle: '',
            breadcrumbs: [
                {active: true, label: 'สมุดรายวันทั่วไป'}
            ]
        })
		this.props.actions.FetchAccountings({keyword: dayjs().format("YYYY-MM")})
	}

	
	handlePageClick = (obj) => {
		// let {caregivers_count} = this.state
		// let start = (caregivers_count - (caregivers_count - ((obj.selected + 1) * 10))) - 10
		this.props.actions.FetchProducts({'page': obj.selected})
    }

    changeDate = (d) => {
        this.setState({keyword: d.format("YYYY-MM")})
        this.props.actions.FetchAccountings({keyword: d.format("YYYY-MM")})
    }

	render() {
        let {accountings, is_pending} = this.props
        let {keyword} = this.state
		return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="box box-primary">
                        <div className="box-header with-border">
                            <h3 className="box-title">สมุดรายวันทั่วไป</h3>
                        </div>

                        <div className="box-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="row form-group">
                                        <div className="col-sm-6">
                                            <DatePicker onChange={this.changeDate} value={keyword} viewMode="months" dateFormat="YYYY-MM" closeOnSelect inputProps={{placeholder: 'เดือน...'}} timeFormat={false}/>
                                        </div>
                                        <div className="col-sm-6 text-right">
                                            <Link to="/accountings/create" className="btn btn-primary"><i className="fa fa-plus"></i> เพิ่มรายการ</Link>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table id="tbProduct" className="table table-bordered" cellSpacing="0" width="100%">
                                            <thead>
                                                <tr>
                                                    <th width="10%" colSpan="2" className="text-center">พ.ศ. {keyword ? parseInt(dayjs(keyword).format("YYYY")) + 543 : ''}</th>
                                                    <th width="30%" rowSpan="2" colSpan="2" className="text-center">รายการ</th>
                                                    <th width="5%" rowSpan="2" className="text-center">เลขที่บัญชี</th>
                                                    <th width="10%" colSpan="2" className="text-center">เดบิต</th>
                                                    <th width="10%" colSpan="2" className="text-center">เครดิต</th>
                                                    <th width="5%" rowSpan="2" className="text-center"></th>
                                                </tr>
                                                <tr>
                                                    <th className="text-center">เดือน</th>
                                                    <th className="text-center">วันที่</th>
                                                    <th className="text-center">บาท.</th>
                                                    <th className="text-center">สต.</th>
                                                    <th className="text-center">บาท.</th>
                                                    <th className="text-center">สต.</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    accountings && accountings.length > 0 ?
                                                        accountings.map(acc => (
                                                            <Fragment key={acc.id}>
                                                                <tr>
                                                                    <td style={{borderBottom: '2px solid #efefef'}} rowSpan="3" className="text-center">{acc.journal_date ? months['m'+dayjs(acc.journal_date).format("MM")] : ''}</td>
                                                                    <td style={{borderBottom: '2px solid #efefef'}} rowSpan="3" className="text-center">{acc.journal_date ? dayjs(acc.journal_date).format("D") : ''}</td>
                                                                    <td colSpan="2">{acc.debit_name}</td>
                                                                    <td className="text-center">{acc.debit_no}</td>
                                                                    <td className="text-right">{acc.debit_price ? numeral(acc.debit_price).format('0,0') : ''}</td>
                                                                    <td>{acc.debit_price ? numeral(acc.debit_price).format('.00').substring(1) : ''}</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td rowSpan="3">
                                                                        {
                                                                            !acc.related_id ?
                                                                                <Link className="btn btn-warning" to={`/accountings/edit/${acc.id}`}><i className="fa fa-edit"></i> Edit</Link>
                                                                            : null
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="14%" style={{borderRight: 0}}></td>
                                                                    <td width="16%" style={{borderLeft: 0}}>{acc.credit_name}</td>
                                                                    <td className="text-center">{acc.credit_no}</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td className="text-right">{acc.credit_price ? numeral(acc.credit_price).format('0,0') : ''}</td>
                                                                    <td>{acc.credit_price ? numeral(acc.credit_price).format('.00').substring(1) : ''}</td>
                                                                </tr>
                                                                <tr style={{borderBottom: '2px solid #efefef'}}>
                                                                    <td colSpan="2">{acc.description}</td>
                                                                    <td className="text-center"></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td className="text-right"></td>
                                                                    <td></td>
                                                                </tr>
                                                            </Fragment>
                                                        ))
                                                    : <tr><td colSpan="10">ไม่พบข้อมูล</td></tr>
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                {/* <div className="col-sm-6" style="padding: 6px 20px;">Showing {{meta.from}} to {{meta.to}} of {{meta.total}} entries</div> */}
                                <div className="col-sm-6"></div>
                                <div className="col-sm-6 text-right">    
                                    {/* <Pagination
                                        itemCount={products_count}
                                        rowCount={10}
                                        onPageChange={this.handlePageClick}
                                    /> */}
                                </div>
                            </div>
                        </div>

                        {
                            is_pending &&
                            <div className="overlay">
                                <i className="fa fa-refresh fa-spin"></i>
                            </div>
                        }

                    </div>

                </div>
            </div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		accountings: state.accountingStore.accountings,
		is_pending: state.accountingStore.is_pending,
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	}
}
  
export default connect(mapStateToProps, mapDispatchToProps)(AccountingList)
