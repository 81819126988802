import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import swal from 'sweetalert'
import numeral from 'numeral'
import actions from '../../redux/actions'
import FormSearch from './../../components/common/FormSearch'

const useFetching = (someFetchActionCreator, params) => {
    useEffect( () => {
      someFetchActionCreator(params);
    }, [])
}

function PurchaseList({is_pending, purchases, actions}) {

    // const [keyword, setKeyword] = useState('')
    useFetching(actions.SetTitle, {
        title: 'รายการซื้อ',
        subtitle: '',
        breadcrumbs: [
            {active: true, label: 'รายการซื้อ'}
        ]
    })

    const deleteData = (item) => {
        swal({
            title: "Are you sure?",
            text: "Confirm delete!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                actions.DeletePurchase(item)
                swal("Your data has been deleted!", {
                    icon: "success",
                });
                actions.FetchPurchases()
            }
        });
    }

    const calTotal = (item) => {
        let total = item.items ? item.items.reduce((acc, cur) => acc + (cur.price * cur.quantity ? ((cur.price * cur.quantity) - (cur.discount || 0)) + (cur.vat_method === 'out' ? ((cur.price * cur.quantity) - (cur.discount || 0)) * 0.07 : 0) : 0) , 0) : 0
        return total.toFixed(2)
    }

    useFetching(actions.FetchPurchases)

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="box box-default">
                    <div className="box-header with-border">
                        <h3 className="box-title">รายการซื้อ</h3>
                    </div>

                    <div className="box-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row form-group">
                                    <div className="col-sm-6">
                                        <FormSearch onSearch={(keyword) => actions.FetchSales({search: keyword})}/>
                                    </div>
                                    <div className="col-sm-6 text-right">
                                        <Link to="/purchases/create" className="btn btn-primary"><i className="fa fa-plus"></i> เพิ่มรายการ</Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="table-responsive">
                                            <table className="table table-bordered" cellSpacing="0" width="100%">
                                                <thead>
                                                   <tr>
                                                       <th>วันที่</th>
                                                       <th>รหัสรายการ</th>
                                                       <th>ผู้จำหน่าย</th>
                                                       <th className="text-right">ยอดรวม</th>
                                                       <th className="text-right">Action</th>
                                                   </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        purchases && purchases.length > 0 ?
                                                            purchases.map(pur => {
                                                                return (
                                                                    <tr key={pur.id}>
                                                                        <td>{pur.purchase_date ? dayjs(pur.purchase_date).format("DD/MM/YYYY HH:mm:ss") : ''}</td>
                                                                        <td>{pur.purchase_full_code}</td>
                                                                        <td>{pur.supplier_name}</td>
                                                                        <td className="text-right">{numeral(calTotal(pur)).format('0,0.00')}</td>
                                                                        <td className="text-right">
                                                                            <div className="btn-group" style={{whiteSpace: 'nowrap'}}>
                                                                                <Link to={'/purchases/edit/'+pur.id} className="btn btn-default">View</Link>
                                                                                <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                                                    <span className="caret"></span>
                                                                                    <span className="sr-only">Toggle Dropdown</span>
                                                                                </button>
                                                                                <ul className="dropdown-menu" role="menu">
                                                                                    <li><Link to={'/purchases/edit/'+pur.id}><i className="fa fa-pencil"></i> Edit</Link></li>
                                                                                    <li><a onClick={() => deleteData(pur)}><i className="fa fa-trash"></i> Del</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        : <tr><td colSpan="5">Data not found</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        is_pending &&
                        <div className="overlay">
                            <i className="fa fa-refresh fa-spin"></i>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        is_pending: state.purchasesStore.is_pending,
        purchases: state.purchasesStore.purchases
    }
}
  
  const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(PurchaseList)
