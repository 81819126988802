import React from "react";
import {Link} from 'react-router-dom'
import { Container, Row, Col } from "shards-react";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import actions from '../redux/actions'
import Pagination from '../components/common/Pagination'
import FormSearch from '../components/common/FormSearch'

class Users extends React.Component {

	componentDidMount() {
        this.props.actions.NewUser()
        this.props.actions.FetchUsers()
        this.props.actions.SetTitle({
            title: 'ผู้ใช้',
            subtitle: '',
            breadcrumbs: [
                {active: true, label: 'ผู้ใช้'}
            ]
        })
	}
	
	handlePageClick = (obj) => {
		this.props.actions.FetchUsers({'page': obj.selected})
    }

	render() {
		let {users, users_count} = this.props
		return (
			<Container fluid className="main-content-container px-4">
				<Row>
					<Col className="mb-4 h-100">
                        <div className="box box-primary">
                            <div className="box-header with-border">
                                <h3 className="box-title">ผู้ใช้</h3>
                            </div>

                            <div className="box-body">
                                <div className="row form-group">
                                    <div className="col-sm-6">
                                        <FormSearch onSearch={(keyword) => this.props.actions.FetchUsers({search: keyword})}/>
                                    </div>
                                    <div className="col-sm-6 text-right">
                                        <Link to="/users/create" className="btn btn-primary"><i className="fa fa-plus"></i> เพิ่มผู้ใช้</Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12" style={{position: 'relative'}}>

                                        <div className="table-responsive">
                                            <table id="tbProduct" className="table table-bordered table-hover" cellSpacing="0" width="100%">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>ชื่อ</th>
                                                        <th>อีเมล</th>
                                                        <th>สิทธ์การเข้าใช้งาน</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        users && users.length > 0 ? 
                                                            users.map((item, index) => {
                                                                return (
                                                                    <tr key={item.id+index} >
                                                                        <td>
                                                                            <Link className="btn btn-warning btn-xs" to={'/users/edit/'+item.id}><i className="fa fa-pencil"></i> Edit</Link>
                                                                            {' '}
                                                                            <a className="btn btn-danger btn-xs" onClick={() => this.deleteData(item.id)}><i className="fa fa-trash"></i> Del</a>
                                                                        </td>
                                                                        <td className="text-center">{item.name}</td>
                                                                        <td className="text-center">{item.email}</td>
                                                                        <td className="text-center">
                                                                            {
                                                                                item.roles && item.roles.length > 0 ?
                                                                                    item.roles.map( (role, index) => {
                                                                                        return (
                                                                                            <span key={role.id}>{role.name} {index!==(item.roles.length-1) ? ',' : ''}</span>
                                                                                        )
                                                                                    })
                                                                                : null
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        : 
                                                            <tr>
                                                                <td className="danger" colSpan="18"> Data not found</td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" v-if="meta">
                                    {/* <div className="col-sm-6" style="padding: 6px 20px;">Showing {{meta.from}} to {{meta.to}} of {{meta.total}} entries</div> */}
                                    <div className="col-sm-6"></div>
                                    <div className="col-sm-6 text-right">    
                                        <Pagination
                                            itemCount={users_count}
                                            rowCount={10}
                                            onPageChange={this.handlePageClick}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>

					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
        users: state.usersStore.users,
        users_count: state.usersStore.users_count,
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	}
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Users)
