import React from "react";
import PropTypes from "prop-types";

const UserDetails = ({ userDetails }) => (
	<div class="box box-primary">
		<div className="box-body box-profile">
			{/* <img className="profile-user-img img-responsive img-circle" src={userDetails.avatar} alt="User profile picture"/> */}

			{/* <h3 className="profile-username text-center">{userDetails.name}</h3> */}

			{/* <p className="text-muted text-center">Software Engineer</p> */}

		</div>
	</div>
);

UserDetails.propTypes = {
	/**
	 * The user details object.
	 */
	userDetails: PropTypes.object
};

UserDetails.defaultProps = {
	userDetails: {
		name: "Sierra Brooks",
		// avatar: ,
		jobTitle: "Project Manager",
		performanceReportTitle: "Workload",
		performanceReportValue: 74,
		metaTitle: "Description",
		metaValue:
		"Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio eaque, quidem, commodi soluta qui quae minima obcaecati quod dolorum sint alias, possimus illum assumenda eligendi cumque?"
	}
};

export default UserDetails;
