import React from 'react'
import { Formik, FieldArray } from 'formik';
import * as Yup from "yup";
import {Link} from 'react-router-dom'
import DatePicker from 'react-datetime'
import TextareaAutosize from 'react-autosize-textarea'
import InputAutocomplete from './../common/InputAutocomplete'

const calTotal = (sale) => {
    let total = sale.items ? sale.items.reduce((acc, cur) => acc + (cur.price * cur.quantity ? (cur.price * cur.quantity) + (cur.vat_method === 'out' ? (cur.price * cur.quantity) * 0.07 : 0) : 0) , 0) : 0
    return total.toFixed(2)
}

const FormProductReceive = ({onSubmit, initialValues}) => {

    return (
        <Formik 
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values) => onSubmit(values)}
            validationSchema={
                Yup.object().shape({
                    receive_date: Yup.mixed().required().label('วันที่'),
                })
            }
        >
            {({ values, handleSubmit, setFieldValue, handleChange, errors, touched }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-3">
                                <div className="form-group" style={{marginBottom: 5}}>
                                    <div>วันที่</div>
                                </div>
                                <div className="form-group" style={{marginBottom: 10}}>
                                    <DatePicker value={values.receive_date ? new Date(values.receive_date) : new Date()} onChange={(v) => handleChange({target: {name: 'receive_date', value: v.format("YYYY-MM-DD HH:mm:ss")}})}/>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="form-group" style={{marginBottom: 5}}>
                                    <div>หมายเหตุ</div>
                                </div>
                                <div className="form-group" style={{marginBottom: 10}}>
                                    <TextareaAutosize rows={3} name="notes" placeholder="หมายเหตุ..." value={values.notes} className="form-control" onChange={handleChange}></TextareaAutosize>
                                </div>
                            </div>
                        </div>
                        <FieldArray
                            name="items"
                            render={arrayHelpers => (
                                <div>
                                    <table className="table table-bordered" style={{marginBottom: 10}}>
                                        <thead>
                                            <tr>
                                                <th width="5%">#</th>
                                                <th width="20%">รหัสสินค้า</th>
                                                <th>สินค้า</th>
                                                <th width="10%" className="text-center">จำนวน</th>
                                                <th width="10%" className="text-center">ราคา/ต่อหน่วย</th>
                                                <th width="15%" className="text-center">หน่วย</th>
                                                <th width="5%"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                values.items && values.items.length > 0 ?
                                                    values.items.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td><div className="text">{index + 1}</div></td>
                                                                <td>
                                                                    <InputAutocomplete 
                                                                        type="select"
                                                                        placeholder="รหัสสินค้า..."
                                                                        items={[]}
                                                                        defaultValue={item.item_code}
                                                                        itemLabelKey={['product_full_code']}
                                                                        itemValueKey={['product_full_code']}
                                                                        onSelect={(item) => {
                                                                            if(item.id) setFieldValue(`items[${index}].product_id`, item.id);
                                                                            setFieldValue(`items[${index}].costs`, item.costs);
                                                                            setFieldValue(`items[${index}].item_code`, item.product_full_code);
                                                                            setFieldValue(`items[${index}].name`, item.name);
                                                                            setFieldValue(`items[${index}].quantity`, 1);
                                                                            setFieldValue(`items[${index}].unit`, item.unit);
                                                                        }}
                                                                        onChange={(e, value) => setFieldValue(`items[${index}].item_code`, value)}
                                                                        isAjax={true}
                                                                        ajaxUrl="/api/products"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <InputAutocomplete 
                                                                        type="select"
                                                                        placeholder="สินค้า..."
                                                                        items={[]}
                                                                        defaultValue={item.name}
                                                                        itemLabelKey={['name']}
                                                                        itemValueKey={['name']}
                                                                        onSelect={(item) => {
                                                                            if(item.id) setFieldValue(`items[${index}].product_id`, item.id);
                                                                            setFieldValue(`items[${index}].item_code`, item.product_full_code);
                                                                            setFieldValue(`items[${index}].name`, item.name);
                                                                            setFieldValue(`items[${index}].quantity`, item.quantity ? item.quantity : 1);
                                                                            setFieldValue(`items[${index}].price`, item.sell_price);
                                                                        }}
                                                                        onChange={(e, value) => setFieldValue(`items[${index}].name`, value)}
                                                                        isAjax={true}
                                                                        ajaxUrl="/api/products"
                                                                    />
                                                                </td>
                                                                <td className="text-center">
                                                                    <input type="text" className="form-control text-right" name={`items[${index}].quantity`} value={item.quantity} onChange={handleChange}/>
                                                                </td>
                                                                <td className="text-center">
                                                                    <input type="text" className="form-control text-right" name={`items[${index}].price`} value={item.price} onChange={handleChange}/>
                                                                </td>
                                                                <td className="text-center">
                                                                    <div className="text">{item.unit}</div>
                                                                </td>
                                                                <td className="text-right">
                                                                    <button className="btn btn-danger" type="button" onClick={() => arrayHelpers.remove(index)}><i className="fa fa-remove"></i></button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                : null
                                            }
                                        </tbody>
                                    </table>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <button className="btn btn-default" onClick={() => arrayHelpers.push({})} type="button"><i className="fa fa-plus"></i> เพิ่มรายการ</button>
                                        </div>
                                        <div className="col-sm-6 text-right">
                                            <div className="row">
                                                <div className="col-sm-6 text-right">
                                                    ยอดรวม
                                                </div>
                                                <div className="col-sm-6 text-right" style={{paddingRight: 30, fontSize: '1.8rem'}}>
                                                    {calTotal(values)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        />
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <Link className="btn btn-default" to="/sales"><i className="fa fa-ban"></i> ยกเลิก</Link>
                                {' '}
                                <button type="submit" className="btn btn-primary"><i className="fa fa-save"></i> บันทึก</button>
                            </div>
                        </div>
                    </form>
                );
            }} 
        </Formik>
        
    )
}

export default FormProductReceive