import React from 'react'
import TextareaAutosize from 'react-autosize-textarea'

const FieldTextarea = ({label, name, value, error, handleChange}) => {

    return (
        <div className={`row form-group ${error ? 'has-error' : ''}`}>
            <div className="col-sm-4 control-label text-right">{label}</div>
            <div className="col-sm-8">
                <TextareaAutosize name={name} placeholder={label} value={value} className="form-control" onChange={handleChange} ></TextareaAutosize>
                {error && <span className="help-block">{error}</span>}
            </div>
        </div>
    )
}

export default FieldTextarea