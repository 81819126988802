import React from "react";
import PropTypes from "prop-types";

const UserAccountDetails = ({ title }) => (
	<div className="box box-primary">
		<div className="box-header with-border">
			<h3 className="box-title">{title}</h3>
		</div>
		<div className="box-body">
			<div className="row">
				<div className="col-sm-6">
					<div className="form-group">
						<label htmlFor="feEmail">Email</label>
						<input type="text" className="form-control" name="email" disabled value="sierra@example.com" autoComplete="email" placeholder="Email Address"/>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-6">
					<div className="form-group">
						<label htmlFor="feFirstName">First Name</label>
						<input type="text" className="form-control" name="name" disabled value="Sierra" placeholder="First Name"/>
					</div>
				</div>
				<div className="col-sm-6">
					<div className="form-group">
						<label htmlFor="feLastName">Last Name</label>
						<input type="text" className="form-control" name="name" disabled value="Sierra" placeholder="First Name"/>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-12">
					<div className="form-group">
						<label htmlFor="feAddress">Address</label>
						<input type="text" className="form-control" name="name" disabled value="Sierra" placeholder="First Name"/>
					</div>
				</div>
			</div>
			<button className="btn btn-primary" type="submit">Update Account</button>
		</div>
	</div>
);

UserAccountDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

UserAccountDetails.defaultProps = {
  title: "Account Details"
};

export default UserAccountDetails;
