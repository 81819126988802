import initialState from './initialState'
import types from './../actionTypes'

export default (state = initialState.settingStore, action = {}) => {

    switch(action.type) {

        case types.SETTING_PENDING: {
            return {
                ...state,
                is_pending: true,
                is_success: false,
                is_fail: false,
                accountingnumber: {
                    items: []
                }
            }
        }

        case types.FETCH_SETTINGS_SUCCESS: {
            return {
                ...state,
                is_pending: false,
                is_success: false,
                is_fail: false,
                setting: action.payload.setting
            }
        }

        case types.FETCH_SETTING_ACCOUNTING_NUMBERS_SUCCESS: {
            // state.accountingnumber.items = action.payload.accountingnumbers
            return {
                ...state,
                is_pending: false,
                is_success: false,
                is_fail: false,
                accountingnumbers: action.payload.accountingnumbers
                // accountingnumber: state.accountingnumber
            }
        }


        case types.UPDATE_SETTING_SUCCESS: {
            return {
                ...state,
                is_pending: false,
                is_success: true,
                setting: action.payload.setting
            }
        }

        case types.UPDATE_SETTING_ACCOUNTING_NUMBERS_SUCCESS: {
            return {
                ...state,
                is_pending: false,
                is_success: true
            }
        }

        default: 
            return state
    }
}