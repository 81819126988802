import * as title from './titleAction'
import * as auth from './authAction'
import * as product from './productsAction'
import * as productType from './producttypesAction'
import * as sale from './saleAction'
import * as purchase from './purchaseAction'
import * as user from './usersAction'
import * as accounting from './accountingAction'
import * as role from './roleAction'
import * as report from './reportAction'
import * as setting from './settingAction'
import * as fileupload from './fileuploadAction'

export default {
    ...title,
    ...auth,
    ...product,
    ...productType,
    ...sale,
    ...purchase,
    ...user,
    ...role,
    ...accounting,
    ...setting,
    ...report,
    ...fileupload
}