import {combineReducers} from 'redux';
import title from './titleReducer'
import auth from './authReducer'
import products from './productReducer'
import sales from './saleReducer'
import purchases from './purchaseReducer'
import producttypes from './producttypeReducer'
import accountings from './accountingReducer'
import roles from './roleReducer'
import users from './userReducer'
import report from './reportReducer'
import setting from './settingReducer'
import { reducer as reduxFormReducer } from 'redux-form';

const rootReducer = combineReducers({
    // short hand property names
    titleStore: title,
    authStore: auth,
    productsStore: products,
    salesStore: sales,
    purchasesStore: purchases,
    producttypesStore: producttypes,
    usersStore: users,
    rolesStore: roles,
    accountingStore: accountings,
    reportStore: report,
    settingStore: setting,
    form: reduxFormReducer
})
  
export default rootReducer;