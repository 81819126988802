import dayjs from 'dayjs'

export default {
    titleStore: {
        title: '',
        subtitle: '',
        breadcrumbs: [],
    },
    authStore: {
        is_pending: false,
        is_success: false,
        is_error: false,
        user: {},
        permissions: [],
        message: null
    },
    productStore: {
        pending: false,
        is_success: false,
        products_count: 0,
        products: [],
        product: {
            costs: [
                {}
            ]
        },
        form: {},
        error: {},
        product_receiving: {
            receive_date: dayjs().format("YYYY-MM-DD HH:mm:ss")
        },
    },
    saleStore: {
        is_pending: false,
        is_success: false,
        is_fail: false,
        sale: {
            receive_date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
            sale_date: dayjs().format("YYYY-MM-DD HH:mm:ss")
        },
        sales: [],
        sales_count: 0,
    },
    purchaseStore: {
        is_pending: false,
        is_success: false,
        is_fail: false,
        purchase: {
            purchase_date: dayjs().format("YYYY-MM-DD HH:mm:ss")
        },
        purchases: [],
        purchases_count: 0,
    },
    producttypeStore: {
        pending: false,
        is_success: false,
        producttypes_count: 0,
        producttypes: [],
        producttype: {},
        form: {},
        error: {}
    },
    accountingStore: {
        is_pending: false,
        is_success: false,
        is_fail: false,
        accountings_count: 0,
        accountings: [],
        accounting: { 
            items: [{journal_date: dayjs().format("YYYY-MM-DD HH:mm:ss")}] 
        },
    },
    userStore: {
        pending: false,
        is_success: false,
        users_count: 0,
        users: [],
        user: {},
        form: {}
    },
    roleStore: {
        is_pending: false,
        is_success: false,
        roles_count: 0,
        roles: [],
        role: {}
    },
    reportStore: {
        is_pending: false,
        is_success: false,
        sale: {}
    },
    settingStore: {
        is_pending: false,
        is_success: false,
        is_fail: false,
        setting: {},
        accountingnumber: {
            items: [{}]
        },
        accountingnumbers: []
    },
}