
import config from './../../config'
import {getCookie} from './../../utils/cookie'
import axios from 'axios'


export const UploadFile = (data) => {
    return new Promise((resolve, reject) => {
        let formData = new FormData()
        Object.keys(data).map((d) => {
            if(data[d] instanceof Array) {
                data[d].forEach(f => {
                    formData.append(d, f)
                })
            } else {
                formData.append(d, data[d])
            }
            return null
        })
        const conf = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': 'Bearer ' + getCookie('token')
            }
        }
        axios.post(config.BASE_API + '/api/upload', formData, conf)
        .then(function (response) {
            //handle success
            console.log(response);
            resolve(response)
        })
        .catch(function (response) {
            //handle error
            console.log(response);
            reject(response)
        });
    })

}