import React, {useState} from 'react'
import { Formik, FieldArray } from 'formik';
import * as Yup from "yup";
import {Link} from 'react-router-dom'
import Modal from 'react-modal';
import DatePicker from 'react-datetime'
import TextareaAutosize from 'react-autosize-textarea'
import 'react-datetime/css/react-datetime.css'
import InputAutocomplete from './../common/InputAutocomplete'
import config from './../../config'
import {getCookie} from './../../utils/cookie'

const customStyles = {
    content : {
      top                   : '40%',
      left                  : '50%',
      width: '100%',
      right                 : 'auto',
      bottom                : 'auto',
        //   marginRight           : '-30%',
      transform             : 'translate(-50%, -50%)',
      zIndex: '9999'
    }
  };
  
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#modal')

const calTotal = (sale) => {
    let total = sale.items ? sale.items.reduce((acc, cur) => acc + (cur.price * cur.quantity ? ((cur.price * cur.quantity) - (cur.discount || 0)) + (cur.vat_method === 'out' ? ((cur.price * cur.quantity) - (cur.discount || 0)) * 0.07 : 0) : 0) , 0) : 0
    return total.toFixed(2)
}

const FormPurchase = ({onSubmit, initialValues}) => {
    if(!initialValues.items) initialValues.items = [{}] 
    if(initialValues.items && initialValues.items.length === 0) initialValues.items = [{}] 
    
    const [modalIsOpen,setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function closeModal(){
        setIsOpen(false);
    }
    return (
        <Formik 
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values) => onSubmit(values)}
            validationSchema={
                Yup.object().shape({
                    supplier_name: Yup.mixed().required().label('ชื่อผู้จำหน่าย'),
                    pay_with: Yup.mixed().required().label('วิธีการชำระเงิน'),
                })
            }
        >
            {({ values, handleSubmit, setFieldValue, handleChange, errors, touched }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="form-group" style={{marginBottom: 5}}>
                                    <div>ชื่อผู้จำหน่าย</div>
                                </div>
                                <div className={`form-group ${errors.supplier_name ? 'has-error' : ''}`} style={{marginBottom: 10}}>
                                    <input placeholder="ชื่อผู้จำหน่าย" className="form-control" type="text" id="supplier_name" name="supplier_name" value={values.supplier_name} onChange={handleChange}/>
                                    {errors.supplier_name && <span className="help-block">{errors.supplier_name}</span>}
                                </div>
                                <div className="form-group" style={{marginBottom: 10, display: 'flex'}}>
                                    <div style={{flex: 1, paddingRight: 3}}>
                                        <input placeholder="เบอร์โทร" className="form-control" type="text" id="supplier_tel" name="supplier_tel" value={values.supplier_tel} onChange={handleChange}/>
                                    </div>
                                    <div style={{flex: 1, paddingLeft: 3}}>
                                        <input placeholder="อีเมล" className="form-control" type="text" id="supplier_email" name="supplier_email" value={values.supplier_email} onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="form-group" style={{marginBottom: 10}}>
                                    <TextareaAutosize rows={3} name="supplier_address" placeholder="ที่อยู่..." value={values.supplier_address} className="form-control" onChange={handleChange} ></TextareaAutosize>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="form-group" style={{marginBottom: 5}}>
                                    <div>วันที่</div>
                                </div>
                                <div className="form-group" style={{marginBottom: 10}}>
                                    <DatePicker value={values.purchase_date ? new Date(values.purchase_date) : new Date()} onChange={(v) => setFieldValue('purchase_date', v.format("YYYY-MM-DD HH:mm:ss"))}/>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="form-group" style={{marginBottom: 5}}>
                                    <div>วิธีการชำระเงิน</div>
                                </div>
                                <div className={`form-group ${errors.pay_with ? 'has-error' : ''}`} style={{marginBottom: 10}}>
                                    <select className="form-control" value={values.pay_with} name="pay_with" id="pay_with" onChange={handleChange}>
                                        <option value="">-- ชำระด้วย --</option>
                                        <option value="cash">เงินสด</option>
                                        <option value="credit">เงินเชื่อ</option>
                                    </select>
                                    {errors.pay_with && <span className="help-block">{errors.pay_with}</span>}
                                </div>

                            </div>
                        </div>
                        <FieldArray
                            name="items"
                            render={arrayHelpers => (
                                <div>
                                    <table className="table table-bordered" style={{marginBottom: 10}}>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th width="15%">รหัสสินค้า</th>
                                                <th width="15%">สินค้า</th>
                                                <th width="10%" className="text-center">จำนวน</th>
                                                <th width="15%" className="text-center">ราคาต่อหน่วย</th>
                                                <th width="12%" className="text-center">ส่วนลด</th>
                                                <th width="10%" className="text-center">รวม</th>
                                                <th width="10%" className="text-center">Vat</th>
                                                <th width="10%" className="text-center">สุทธิ</th>
                                                <th width="5%"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                values.items && values.items.length > 0 ?
                                                    values.items.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td><div className="text">{index + 1}</div></td>
                                                                <td>
                                                                    <InputAutocomplete 
                                                                        type="select"
                                                                        placeholder="รหัสสินค้า..."
                                                                        items={[]}
                                                                        defaultValue={item.item_code}
                                                                        itemLabelKey={['product_full_code']}
                                                                        itemValueKey={['product_full_code']}
                                                                        onSelect={(item) => {
                                                                            if(item.id) setFieldValue(`items[${index}].product_id`, item.id);
                                                                            setFieldValue(`items[${index}].item_code`, item.product_full_code);
                                                                            setFieldValue(`items[${index}].name`, item.name);
                                                                            setFieldValue(`items[${index}].quantity`, item.quantity ? item.quantity : 1);
                                                                            setFieldValue(`items[${index}].price`, item.sell_price);
                                                                        }}
                                                                        onChange={(e, value) => setFieldValue(`items[${index}].item_code`, value)}
                                                                        isAjax={true}
                                                                        ajaxUrl="/api/products"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <InputAutocomplete 
                                                                        type="select"
                                                                        placeholder="สินค้า..."
                                                                        items={[]}
                                                                        defaultValue={item.name}
                                                                        itemLabelKey={['name']}
                                                                        itemValueKey={['name']}
                                                                        onSelect={(item) => {
                                                                            if(item.id) setFieldValue(`items[${index}].product_id`, item.id);
                                                                            setFieldValue(`items[${index}].item_code`, item.product_full_code);
                                                                            setFieldValue(`items[${index}].name`, item.name);
                                                                            setFieldValue(`items[${index}].quantity`, item.quantity ? item.quantity : 1);
                                                                            setFieldValue(`items[${index}].price`, item.sell_price);
                                                                        }}
                                                                        onChange={(e, value) => setFieldValue(`items[${index}].name`, value)}
                                                                        isAjax={true}
                                                                        ajaxUrl="/api/products"
                                                                    />
                                                                </td>
                                                                <td className="text-center">
                                                                    <input type="text" className="form-control text-right" name={`items[${index}].quantity`} value={item.quantity} onChange={handleChange}/>
                                                                </td>
                                                                <td className="text-right">
                                                                    <input type="text" className="form-control text-right" name={`items[${index}].price`} value={item.price} onChange={handleChange}/>
                                                                </td>
                                                                <td className="text-right">
                                                                    <input type="text" className="form-control text-right" name={`items[${index}].discount`} value={item.discount} onChange={handleChange}/>
                                                                </td>
                                                                <td className="text-right">
                                                                    <div className="text">{item.price * item.quantity ? (item.price * item.quantity) - (item.discount || 0) : 0}</div>
                                                                </td>
                                                                <td className="text-right">
                                                                    <div className="input-group">
                                                                        <input disabled type="text" className="form-control text-right" name={`items[${index}].vat`} value={(item.price * item.quantity) && item.vat_method === 'out' ? (item.price * item.quantity) * 0.07 : ''} onChange={handleChange}/>
                                                                        <span className="input-group-btn">
                                                                            <button 
                                                                                className="btn btn-default" 
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    setFieldValue(`items[${index}].vat_method`, item.vat_method === 'out' ? 'no' : 'out');
                                                                                    setFieldValue(`items[${index}].vat`, item.vat_method === 'no' ? 0 : (item.price * item.quantity ? (item.price * item.quantity) * 0.07 : 0) );
                                                                                }}
                                                                            >
                                                                                <i className={`fa fa-${ item.vat_method && item.vat_method === 'out' ? 'check' : 'remove'}`}></i>
                                                                            </button>
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td className="text-right">
                                                                    <div className="text">{item.price * item.quantity ? (item.vat_method === 'out' ? ((item.price * item.quantity) - (item.discount || 0)) + (( (item.price * item.quantity) - (item.discount || 0) ) * 0.07) : (item.price * item.quantity) - (item.discount || 0)) : 0}</div>
                                                                </td>
                                                                <td className="text-right">
                                                                    <button className="btn btn-danger" type="button" onClick={() => arrayHelpers.remove(index)}><i className="fa fa-remove"></i></button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                : null
                                            }
                                        </tbody>
                                    </table>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <button className="btn btn-default" onClick={() => arrayHelpers.push({})} type="button"><i className="fa fa-plus"></i> เพิ่มรายการ</button>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="row">
                                                <div className="col-sm-6 text-right">
                                                    ยอดรวม
                                                </div>
                                                <div className="col-sm-6 text-right" style={{paddingRight: 30, fontSize: '1.8rem'}}>
                                                    {calTotal(values)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        />
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <Link className="btn btn-default" to="/sales"><i className="fa fa-ban"></i> ยกเลิก</Link>
                                {' '}
                                <button type="submit" className="btn btn-primary"><i className="fa fa-save"></i> บันทึก</button>
                                {' '}
                                {
                                    values.id ?
                                        <button type="button" onClick={openModal} className="btn btn-primary"><i className="fa fa-print"></i> พิมพ์</button>
                                    : null
                                }
                                <Modal
                                    isOpen={modalIsOpen}
                                    onAfterOpen={afterOpenModal}
                                    onRequestClose={closeModal}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                >
                                    <div>
                                        <iframe id="iPrint" style={{ overflow: 'hidden', height: 600, width: '100%', border: 0}} allowFullScreen height="100%" width="100%" src={`${config.BASE_API}/print/sales/${values.id}?act=${getCookie("token")}`} frameBorder="0">Loading...</iframe>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 text-right">
                                            <button className="btn btn-primary" type="button" onClick={() => document.getElementById('iPrint').contentWindow.print()}><i className="fa fa-print"></i> พิมพ์</button>
                                            {' '}
                                            <button className="btn btn-default" type="button" onClick={closeModal}><i className="fa fa-remove"></i> ปิด</button>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </form>
                );
            }} 
        </Formik>
        
    )
}

export default FormPurchase