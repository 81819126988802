import React from "react";
import PropTypes from "prop-types";
import {Link} from 'react-router-dom'
import dayjs from 'dayjs'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from './../redux/actions/productsAction'
import * as titleActions from './../redux/actions/titleAction'
import Pagination from './../components/common/Pagination'
import FormSearch from './../components/common/FormSearch'
import ImageLightbox from './../components/common/ImageLightbox'
import config from './../config'
import swal from 'sweetalert'

class Products extends React.Component {

	state = {
        page: 1,
        toggleItem: {
            code: { label: 'เลขทะเบียน', active: true },
            productType: { label: 'ประเภทสินค้า', active: true },
            prefix: { label: 'ตัวย่อสินค้า', active: true },
            name: { label: 'ชื่อสินค้า', active: true },
            description: { label: 'รายละเอียด', active: true },
            costPrice: { label: 'ราคาทุน', active: true },
            sellPrice: { label: 'ราคาขาย', active: true },
            image: { label: 'ภาพสินค้า', active: true },
            balance: { label: 'สินค้าคงเหลือ', active: true },
            quantity: { label: 'จำนวน', active: true },
            unit: { label: 'หน่วย', active: true },
            pickupDate: { label: 'วันที่รับเข้า', active: true },
            soldoutDate: { label: 'วันที่ขายออก', active: true },
            status: { label: 'สถานะ', active: true },
            note: { label: 'หมายเหตุ', active: false },
            lastModified: { label: 'แก้ไขล่าสุด', active: false },
        },
	}

	componentDidMount() {
        this.props.actions.SetTitle({
            title: 'สินค้า',
            subtitle: '',
            breadcrumbs: [
                {active: true, label: 'สินค้า'}
            ]
        })
		this.props.actions.FetchProducts()
	}

	
	handlePageClick = (obj) => {
		// let {caregivers_count} = this.state
		// let start = (caregivers_count - (caregivers_count - ((obj.selected + 1) * 10))) - 10
		this.props.actions.FetchProducts({'page': obj.selected + 1})
    }
    
    allToggle = (e) => {
        let {toggleItem} = this.state
        Object.keys(this.toggleItem).forEach((t) => {
            this.toggleItem[t].active = e.target.checked
        })
        this.setState({ toggleItem })
    }

    changeToggle = (k) => {
        let {toggleItem} = this.state
        toggleItem[k].active = !toggleItem[k].active
        this.setState({ toggleItem })
    }

    deleteData = (item) => {
        swal({
            title: "Are you sure?",
            text: "Confirm delete!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                this.props.actions.DeleteProduct(item)
                swal("Poof! Your imaginary file has been deleted!", {
                    icon: "success",
                });
                this.props.actions.FetchProducts()
            } else {
                swal("Your imaginary file is safe!");
            }
        });
    }

	render() {
        let {toggleItem} = this.state
        let {products, products_count} = this.props
		return (
			<div className="row">
                <div className="col-sm-12">
                    <div className="box box-default">
                        <div className="box-header with-border">
                            <h3 className="box-title">สินค้า</h3>
                        </div>

                        <div className="box-body">
                            <div className="row form-group">
                                <div className="col-sm-6">
                                    <FormSearch onSearch={(keyword) => this.props.actions.FetchProducts({search: keyword})}/>
                                </div>
                                <div className="col-sm-6 text-right">
                                    <Link to="/products/create" className="btn btn-primary"><i className="fa fa-plus"></i> เพิ่มสินค้า</Link>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12" style={{position: 'relative'}}>
                                    <div style={{position: 'absolute', top: '0px', right: '0px'}}>
                                        <div className="btn-group" role="group" style={{position: 'relative'}}>
                                            <button type="button" className="btn btn-default" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></button>
                                            <ul className="dropdown-menu pull-right">
                                                <li style={{padding: '5px 10px'}}>
                                                    <input type="checkbox" name="all" value="on" onChange={this.allToggle}/> All
                                                </li>
                                                {
                                                    Object.keys(toggleItem).map(item => (
                                                        <li style={{padding: '5px 10px'}} key={item}>
                                                            <input type="checkbox" name="item" checked={toggleItem[item].active} value="on" onChange={(e) => this.changeToggle(item)}/>
                                                            {toggleItem[item].label}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="table-responsive">
                                        <table id="tbProduct" className="table table-bordered table-hover" cellSpacing="0" width="100%">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    {toggleItem.code.active && <th>เลขทะเบียน</th> }
                                                    {toggleItem.productType.active && <th>ประเภทสินค้า</th>}
                                                    {toggleItem.prefix.active && <th>ตัวย่อสินค้า</th>}
                                                    {toggleItem.name.active && <th>ชื่อสินค้า</th>}
                                                    {toggleItem.description.active && <th>รายละเอียด</th>}
                                                    {toggleItem.costPrice.active && <th>ราคาทุน</th>}
                                                    {toggleItem.sellPrice.active && <th>ราคาขาย</th>}
                                                    {toggleItem.image.active && <th>ภาพสินค้า</th>}
                                                    {toggleItem.balance.active && <th>สินค้าคงเหลือ</th>}
                                                    {toggleItem.quantity.active && <th>จำนวนเริ่มต้น</th>}
                                                    {toggleItem.unit.active && <th>หน่วย</th>}
                                                    {toggleItem.pickupDate.active && <th>วันที่รับเข้า</th>}
                                                    {toggleItem.soldoutDate.active && <th>วันที่ขายออก</th>}
                                                    {toggleItem.status.active && <th>สถานะ</th>}
                                                    {toggleItem.note.active && <th>หมายเหตุ</th>}
                                                    {toggleItem.lastModified.active && <th>last modified</th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    products && products.length > 0 ? 
                                                        products.map((item, index) => {
                                                            return (
                                                                <tr key={item.id} >
                                                                    <td style={{whiteSpace: 'nowrap'}}>
                                                                        <Link className="btn btn-warning btn-xs" to={'/products/edit/'+item.id}><i className="fa fa-pencil"></i> Edit</Link>
                                                                        {' '}
                                                                        <a className="btn btn-danger btn-xs" onClick={() => this.deleteData(item)}><i className="fa fa-trash"></i> Del</a>
                                                                    </td>
                                                                    {toggleItem.code.active && <td style={{whiteSpace: 'nowrap'}} className="text-center">{item.product_full_code}</td>}
                                                                    {toggleItem.productType.active && <td  className="text-center">{item.product_type ? item.product_type.name : ''}</td>}
                                                                    {toggleItem.prefix.active && <td  className="text-center">{item.prefix}</td>}
                                                                    {toggleItem.name.active && <td  className="text-left">{item.name}</td>}
                                                                    {toggleItem.description.active && <td  className="text-left"><div style={{display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', lineHeight: '1em',width: '150px', maxHeight: '70px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{item.description}</div></td>}
                                                                    {toggleItem.costPrice.active && <td  className="text-center">{item.cost_price}</td>}
                                                                    {toggleItem.sellPrice.active && <td  className="text-center">{item.sell_price}</td>}
                                                                    {toggleItem.image.active && <td>
                                                                        <div style={{display: 'flex'}}>
                                                                            {
                                                                                item.images && item.images.length > 0 ?
                                                                                    <ImageLightbox images={item.images.map(image => {
                                                                                        return {
                                                                                            src: config.BASE_API + '/' + image.url
                                                                                        }
                                                                                    })} />
                                                                                : null
                                                                            }
                                                                        </div>
                                                                    </td>}
                                                                    {toggleItem.balance.active && <td  className="text-center">{item.balance}</td>}
                                                                    {toggleItem.quantity.active && <td  className="text-center">{item.quantity}</td>}
                                                                    {toggleItem.unit.active && <td  className="text-center">{item.unit}</td>}
                                                                    {toggleItem.pickupDate.active && <td  className="text-center">{item.pickup_date}</td>}
                                                                    {toggleItem.soldoutDate.active && <td  className="text-center">{item.soldout_date}</td>}
                                                                    {toggleItem.status.active && <td  className="text-center">{item.status}</td>}
                                                                    {toggleItem.note.active && <td  className="text-center">{item.notes}</td>}
                                                                    {toggleItem.lastModified.active && <td  className="text-center">{dayjs(item.updated_at).format("DD/MM/YYYY HH:mm")}</td>}
                                                                </tr>
                                                            )
                                                        })
                                                    : 
                                                        <tr>
                                                            <td className="danger" colSpan="18"> Data not found</td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="row" v-if="meta">
                                {/* <div className="col-sm-6" style="padding: 6px 20px;">Showing {{meta.from}} to {{meta.to}} of {{meta.total}} entries</div> */}
                                <div className="col-sm-6"></div>
                                <div className="col-sm-6 text-right">    
                                    <Pagination
                                        itemCount={products_count}
                                        rowCount={10}
                                        onPageChange={this.handlePageClick}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>

                       
			    </div>
			</div>
		)
	}
}
    


Products.propTypes = {
  /**
   * The small stats dataset.
   */
  smallStats: PropTypes.array
};

Products.defaultProps = {
  
};


const mapStateToProps = (state, ownProps) => {
	return {
        ...state.producttypesStore,
		...state.productsStore
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({...actions, ...titleActions}, dispatch)
	}
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Products)
