import React from "react";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import swal from 'sweetalert'
import * as Yup from "yup";
import { Formik, FieldArray } from 'formik'
import actions from '../../redux/actions'

class AccountingNumber extends React.Component {

    componentDidMount = () => {
        this.props.actions.SetTitle({
            title: 'บัญชี',
            subtitle: '',
            breadcrumbs: [
                {active: true, label: 'บัญชี'}
            ]
        })
        this.props.actions.FetchSettingAccountingNumbers()
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.is_success) {
            swal("", "บันทึกเสร็จสิ้น", "success");
        }
    }

    onSubmit = (values) => {
        this.props.actions.UpdateSettingAccountingNumber(values)
    }

    render() {
        let {accountingnumbers, is_pending} = this.props
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="box box-default">
                        <div className="box-header with-border">
                            <h3 className="box-title">เลขที่บัญชี</h3>
                        </div>

                        <div className="box-body">
                        <Formik
                            initialValues={{items: accountingnumbers && accountingnumbers.length > 0 ? accountingnumbers : []}}
                            enableReinitialize
                            onSubmit={this.onSubmit}
                            validationSchema={
                                Yup.object().shape({
                                    items: Yup.array()
                                        .of(
                                            Yup.object().shape({
                                                accounting_no: Yup.string().required('Required'), // these constraints take precedence
                                                accounting_name: Yup.string().required('Required'), // these constraints take precedence
                                            })
                                        )
                                        .required('Must have friends') // these constraints are shown if and only if inner constraints are satisfied
                                        .min(1, 'Minimum of 1 rows'),
                                  })
                            }
                        >
                            {({ values, handleChange, handleSubmit, errors }) => (
                                <form onSubmit={handleSubmit}> 
                                    <FieldArray
                                        name="items"
                                        render={arrayHelpers => (
                                            <div className="table-responsive">
                                                {
                                                    values.items && values.items.length > 0 ?
                                                        values.items.map((item, index) => {
                                                            return (
                                                                <div className="row form-group" key={index}>
                                                                    <div className="col-sm-2 text-right" style={{padding: '8px'}}>
                                                                        เลขที่
                                                                    </div>
                                                                    <div className={`col-sm-2 ${errors.items && errors.items[index] && errors.items[index].accounting_no ? 'has-error' : ''}`}>
                                                                        <input name={`items.${index}.accounting_no`} value={item.accounting_no} className="form-control" onChange={handleChange} />
                                                                        {errors.items && errors.items[index] && errors.items[index].accounting_no ? <div className="help-block">{errors.items[index].accounting_no}</div> : null}
                                                                    </div>
                                                                    <div className="col-sm-1 text-right" style={{padding: '8px'}}>
                                                                        ชื่อ
                                                                    </div>
                                                                    <div className={`col-sm-3 ${errors.items && errors.items[index] && errors.items[index].accounting_name ? 'has-error' : ''}`}>
                                                                        <input name={`items.${index}.accounting_name`} value={item.accounting_name} className="form-control" onChange={handleChange} />
                                                                        {errors.items && errors.items[index] && errors.items[index].accounting_name ? <div className="help-block">{errors.items[index].accounting_name}</div> : null}
                                                                    </div>
                                                                    <div className="col-sm-1">
                                                                        <button
                                                                            className="btn btn-danger"
                                                                            type="button"
                                                                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                                        >
                                                                            <i className="fa fa-trash"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        )
                                                    : null
                                                }
                                                <div className="row">
                                                    <div className="col-sm-2"></div>
                                                    <div className="col-sm-4">
                                                        <button className="btn btn-primary" type="button" onClick={() => arrayHelpers.push({})}>
                                                            <i className="fa fa-plus"></i> Add 
                                                        </button>
                                                        {' '}
                                                        <button className="btn btn-primary" type="submit"><i className="fa fa-save"></i> บันทึก</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    />
                                </form>
                            )}
                            </Formik>
                        </div>
                        {
                            is_pending &&
                            <div className="overlay">
                                <i className="fa fa-refresh fa-spin"></i>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
} 



const mapStateToProps = (state, ownProps) => {
    return {
        accountingnumber: state.settingStore.accountingnumber,
        accountingnumbers: state.settingStore.accountingnumbers,
        is_success: state.settingStore.is_success,
        is_pending: state.settingStore.is_pending,
    }
}
  
  const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(AccountingNumber)
