import React from "react";
import { Redirect} from 'react-router-dom'
import FormProductType from './../components/producttype/FormProductType'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from './../redux/actions/producttypesAction'
import * as titleAction from './../redux/actions/titleAction'


class ProductTypeForm extends React.Component {

    constructor(props, state) {
        super(props, state);
        this.state = {

        };
    }

    componentDidMount() {
        const { match } = this.props;
        this.props.actions.SetTitle({
            title: 'ประเภทสินค้า',
            subtitle: '',
            breadcrumbs: [
                {path: '/producttypes', label: 'ประเภทสินค้า'},
                {active: true, label: (match.params.id ? 'แก้ไข' : 'เพิ่ม') +'ประเภทสินค้า'}
            ]
        })
        if(match.params.id) {
            this.props.actions.FetchProductTypeByID(match.params.id)
        } else {
            this.props.actions.NewProductType()
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if(nextProps.is_success) {
            this.props.history.push('/producttypes')
        }
    }
    
    submit = (values) => {
        const { match } = this.props;
        if(match.params.id) {
            this.props.actions.UpdateProductType(values)
        } else {
            this.props.actions.StoreProductType(values)
        }
    }

    render() {
        let { is_success, producttype } = this.props
        return (
            <div className="row">
                <div className="col-sm-12">
                
                    { is_success ? <Redirect to="/producttypes" push={true} /> : ''}

                    <div className="box box-primary" id="frmProduct">
                        <div className="box-header with-border">
                            <h3 className="box-title">{producttype.id ? 'แก้ไข' : 'เพิ่ม'} ประเภทสินค้า</h3>
                        </div>
                        <div className="box-body">
                            <FormProductType onSubmit={this.submit} initialValues={producttype}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
} 


ProductTypeForm.propTypes = {

};

ProductTypeForm.defaultProps = {

};


const mapStateToProps = (state, ownProps) => {
    return {
        ...state.producttypesStore
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({...actions, ...titleAction}, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductTypeForm);

