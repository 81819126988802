import React from 'react'
import WithForm from './../hoc/WithForm'
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

const FormRole = ({fields, validator, handleChange, handleSubmit, onCheck, onExpand, checked, expanded, nodes}) => {
    let error = {
        name: validator.message('name', fields.name, 'required')
    }
    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="form-group">
                    <label htmlFor="feInputAddress">ชื่อกลุ่ม *</label>
                    <input type="text" className="form-control mb-3" placeholder="Name..." name="name" value={fields.name || ''} onChange={handleChange} invalid={error.name ? true : false}/>
                    {error.name ? <div className="invalid-feedback">{error.name}</div> : null}
                </div>
                <div className="form-group">
                    <CheckboxTree
                        nodes={nodes}
                        checked={fields.permissions}
                        expanded={expanded}
                        onCheck={(checked) => handleChange({ target: {name: 'permissions', value: checked} })}
                        onExpand={onExpand}
                        showExpandAll
                        icons={{
                            check: <i className="fa fa-check-square-o"></i>,
                            uncheck: <i className="fa fa-square-o"></i>,
                            halfCheck: <i className="fa fa-minus-square"></i>,
                            expandClose: <i className="fa fa-chevron-right"></i>,
                            expandOpen: <i className="fa fa-chevron-down"></i>,
                            expandAll: <i className="fa fa-chevron-down"></i>,
                            collapseAll: <i className="fa fa-chevron-right"></i>,
                            parentClose: <i className="fa fa-folder-o"></i>,
                            parentOpen: <i className="fa fa-folder-open-o"></i>,
                            leaf: <i className="fa fa-folder-open-o"></i>,
                        }}
                    />
                </div>
                <button className="btn btn-primary" onClick={handleSubmit}>
                    <i className="fa fa-copy"></i> Save
                </button>
            </div>
        </div>
    )
}

export default WithForm(FormRole)
