import React from "react";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import swal from 'sweetalert'
import actions from './../../redux/actions'
import FormRole from './../../components/user/FormRole'

const nodes = [{
    value: 'all',
    label: 'All',
    children: [
        { 
            value: 'sales',
            label: 'ขาย',
            children: [

                { label: 'ขายสินค้า', value: 'Create Sales'},
                { 
                    label: 'รายการขาย',
                    value: 'sale_lists',
                    children: [
                        { label: 'ดูข้อมูลรายการขาย', value: 'View Sales'},
                        { label: 'แก้ไขรายการขาย', value: 'Update Sales'},
                        { label: 'ลบรายการขาย', value: 'Delete Sales'}
                    ]
                },
            ]
        },
        { 
            value: 'products',
            label: 'สินค้า',
            children: [
                { label: 'เพิ่มสินค้าใหม่', value: 'Create Products' },
                { label: 'รับเข้าสินค้า', value: 'Receive Products' },
                { 
                    label: 'รายการสินค้า',
                    value: 'product_lists',
                    children: [
                        { label: 'ดูข้อมูลรายการสินค้า', value: 'View Products'},
                        { label: 'เพิ่มรายการสินค้า', value: 'Create Products'},
                        { label: 'แก้ไขรายการสินค้า', value: 'Update Products'},
                        { label: 'ลบรายการสินค้า', value: 'Delete Products'},
                    ]
                },
                { label: 'สินค้าคงเหลือ', value: 'Balance Products' },
            ]
        },
        { 
            label: 'ประเภทสินค้า',
            value: 'producttypes',
            children: [
                { label: 'ดูข้อมูลรายการประเภทสินค้า', value: 'View ProductTypes'},
                { label: 'เพิ่มรายการประเภทสินค้า', value: 'Create ProductTypes'},
                { label: 'แก้ไขรายการประเภทสินค้า', value: 'Update ProductTypes'},
                { label: 'ลบรายการประเภทสินค้า', value: 'Delete ProductTypes'},
            ]
        },
        { 
            label: 'บัญชี',
            value: 'accountings',
            children: [
                { 
                    label: 'สมุดรายวันทั่วไป', 
                    value: 'accounting_journals',
                    children: [
                        { label: 'ดูข้อมูลรายการสมุดรายวันทั่วไป', value: 'View AccountingJournals'},
                        { label: 'เพิ่มรายการสมุดรายวันทั่วไป', value: 'Create AccountingJournals'},
                        { label: 'แก้ไขรายการสมุดรายวันทั่วไป', value: 'Update AccountingJournals'},
                    ]
                },
                { 
                    label: 'เลขที่บัญชี', 
                    value: 'accounting_numbers',
                    children: [
                        { label: 'ดูข้อมูลเลขที่บัญชี', value: 'View AccountingNumbers'},
                        { label: 'เพิ่มเลขที่บัญชี', value: 'Create AccountingNumbers'},
                        { label: 'แก้ไขเลขที่บัญชี', value: 'Update AccountingNumbers'},
                        { label: 'ลบเลขที่บัญชี', value: 'Delete AccountingNumbers'},
                    ]
                },
            ]
        },
        { 
            label: 'ผู้ใช้',
            value: 'users',
            children: [
                { 
                    label: 'ผู้ใช้', 
                    value: 'View Users',
                    children: [
                        { label: 'ดูข้อมูลผู้ใช้', value: 'View Users'},
                        { label: 'เพิ่มผู้ใช้', value: 'Create Users'},
                        { label: 'แก้ไขผู้ใช้', value: 'Update Users'},
                        { label: 'ลบผู้ใช้', value: 'Delete Users'},
                    ]
                },
                { 
                    label: 'กลุ่มผู้ใช้และสิทธ์', 
                    value: 'user_roles',
                    children: [
                        { label: 'ดูข้อมูลกลุ่มผู้ใช้และสิทธ์', value: 'View Roles'},
                        { label: 'เพิ่มกลุ่มผู้ใช้และสิทธ์', value: 'Create Roles'},
                        { label: 'แก้ไขกลุ่มผู้ใช้และสิทธ์', value: 'Update Roles'},
                        { label: 'ลบกลุ่มผู้ใช้และสิทธ์', value: 'Delete Roles'},
                    ]
                },
            ]
        },
        { 
            value: 'settings',
            label: 'ตั้งค่าระบบ',
            children: [
                { 
                    label: 'องค์กร',
                    value: 'setting_org',
                    children: [
                        { label: 'ดูข้อมูลตั้งค่าองค์กร', value: 'View SettingOrg' },
                        { label: 'แก้ไขตั้งค่าองค์กร', value: 'Update SettingOrg' },
                    ]
                }
            ]
        },
    ]
}];

class RoleForm extends React.Component {

    state = {
        checked: [],
        expanded: ['all', 'sales', 'products', 'producttypes', 'accountings', 'users', 'settings'],
    }

    componentDidMount = () => {
        const { match } = this.props;
        let title = 'เพิ่ม'
        if(match.params.id) {
            this.props.actions.FetchRoleByID(match.params.id)
            title = 'แก้ไข'
        } 
        this.props.actions.SetTitle({
            title: 'ผู้ใช้',
            subtitle: '',
            breadcrumbs: [
                {path: '/users/roles', label: 'กลุ่มผู้ใช้'},
                {active: true, label: title + 'กลุ่มผู้ใช้'}
            ]
        })
    }

    componentWillReceiveProps = (nextProps) => {
        if(nextProps.is_success) {
            swal("บันทึกข้อมูลกลุ่มผู้ใช้และสิทธ์!", "บันทึกข้อมูลกลุ่มผู้ใช้และสิทธ์เสร็จสิ้น!", "success")
            this.props.history.push('/users/roles')
        }
    }

    onSubmit = (values) => {
        const { match } = this.props;
        if(match.params.id) {
            this.props.actions.UpdateRole(values)
        } else {
            this.props.actions.StoreRole(values)
        }
    }

    onCheck = (checked) => {
        this.setState({ checked })
    }

    onExpand = (expanded) => {
        this.setState({ expanded })
    }

    render() {
        let {role} = this.props
        return (
            <div className="box box-default">
                <div className="box-header">
                    <h6 className="box-title"> {role.id ? 'แก้ไข' : 'เพิ่ม'}กลุ่มผู้ใช้และสิทธ์</h6>
                </div>
                <div className="box-body">
                    <FormRole 
                        initialValues={role}
                        onSubmit={this.onSubmit} 
                        onCheck={this.onCheck} 
                        checked={this.state.checked}
                        expanded={this.state.expanded}
                        onExpand={this.onExpand}
                        nodes={nodes}
                    />
                </div>
            </div>
        )
    }
} 

const mapStateToProps = (state, ownProps) => {
    return {
        role: state.rolesStore.role,
        is_success: state.rolesStore.is_success,
    }
}
  
  const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(RoleForm)
