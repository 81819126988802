import types from './../actionTypes'
import config from './../../config'
import {getCookie} from './../../utils/cookie'
import axios from 'axios'
import swal from 'sweetalert'

export const AddPurchaseItem = (item, goToPurchase) => {
    return async (dispatch) => {
        dispatch({
            type: types.PURCHASE_ADD_ITEM,
            payload: {
                product_id: item.id,
                item_code: item.product_full_code,
                name: item.name,
                price: item.sell_price,
                quantity: 1,
                discount: 0
            }
        });
        swal("เพิ่มรายการซื้อเสร็จสิน", {
            icon: "success",
            buttons: ["ปิด", "ไปหน้าขายสินค้า"],
        })
        .then((willDelete) => {
            if (willDelete) {
                goToPurchase()
            } 
        });
        
    }
}

export function NewPurchase() {
    return (dispatch) => {
        dispatch({
            type: types.PURCHASE_NEW
        })
    }
}

export function FetchPurchases(query) {
    return async (dispatch) => {
        // try {
            dispatch({
                type: types.PURCHASE_PENDING,
                payload: null
            })

            axios.get(config.BASE_API + '/api/purchases', {
                params: {
                    // '_limit': 10,
                    ...query
                },
                headers: {
                    Authorization: `Bearer ${getCookie("token")}`
                }
            })
            .then(response => {
                dispatch({
                    type: types.FETCH_PURCHASES_SUCCESS,
                    payload: response.data
                });
            })
            .catch(error => {
                // Handle error.
                console.log('An error occurred:', error);
            });
       
    }
}


export const FetchPurchaseByID = (id) => {
    return async (dispatch) => {
        dispatch({
            type: types.PURCHASE_PENDING,
            payload: null
        })
        axios.get(config.BASE_API + '/api/purchases/' + id, {
            headers: {
                Authorization: `Bearer ${getCookie("token")}`
            }
        })
        .then(response => {
            dispatch({
                type: types.FETCH_PURCHASE_SUCCESS,
                payload: {
                    purchase: response.data
                }
            });
        })
        .catch(error => {
          // Handle error.
          console.log('An error occurred:', error);
        });
    }
}

export const StorePurchase = (data) => {
    return async (dispatch) => {

        dispatch({
            type: types.PURCHASE_PENDING,
            payload: null
        });
    
        axios.post(config.BASE_API + '/api/purchases', data, {
            headers: {
                Authorization: `Bearer ${getCookie("token")}`
            }
        })
        .then(response => {

            dispatch({
                type: types.STORE_PURCHASE_SUCCESS,
                payload: {
                    purchase: response.data && response.data.data ? response.data.data : {}
                }
            });

        })
        .catch(error => {
            // Handle error.
            console.log('An error occurred:', error);
        });
    }
}


export const UpdatePurchase = (data) => {
    return async (dispatch) => {

        dispatch({
            type: types.PURCHASE_PENDING,
            payload: null
        });
    
        axios.put(config.BASE_API + '/api/purchases/' + data.id, data, {
            headers: {
                Authorization: `Bearer ${getCookie("token")}`
            }
        })
        .then(response => {
            dispatch({
                type: types.STORE_PURCHASE_SUCCESS,
                payload: {
                    purchase: response.data && response.data.data ? response.data.data : {}
                }
            });

        })
        .catch(error => {
            // Handle error.
            console.log('An error occurred:', error);
        });
    }
}


export const DeletePurchase = (data) => {
    return async (dispatch) => {
        dispatch({
            type: types.PURCHASE_PENDING,
            payload: null
        });

        axios.delete(config.BASE_API + '/api/purchases/' + data.id, {
            headers: {
                Authorization: `Bearer ${getCookie("token")}`
            }
        })
        .then(response => {
            dispatch({
                type: types.STORE_PURCHASE_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
          // Handle error.
          console.log('An error occurred:', error);
        });
    }
}