import initialState from './initialState'
import types from './../actionTypes'

export default (state = initialState.productStore, action = {}) => {

    switch(action.type) {

        case types.PRODUCT_PENDING: {
            return {
                ...state,
                pending: true,
                is_success: false
            }
        }

        case types.PRODUCT_FORM_CHANGE: {

            return {
                ...state,
                product: action.payload
            }
        }

        case types.NEW_PRODUCT: {
            return {
                ...state,
                pending: false,
                is_success: false,
                product: {}
            }
        }

        case types.FETCH_PRODUCTS_SUCCESS: {
            return {
                ...state,
                pending: false,
                is_success: false,
                product: {},
                products: action.payload.data,
                products_count: action.payload.total,
            }
        }

        case types.FETCH_PRODUCT_SUCCESS: {
            return {
                ...state,
                pending: false,
                is_success: false,
                product: action.payload.product,
                form: action.payload.product,
            }
        }

        case types.STORE_PRODUCT_SUCCESS: {
            return {
                ...state,
                pending: false,
                is_success: true,
                product: action.payload
            }
        }

        default: 
            return state
    }
}