import initialState from './initialState'
import types from './../actionTypes'
import {setCookie} from './../../utils/cookie'

export default (state = initialState.authStore, action = {}) => {

    switch(action.type) {

		case types.AUTH_PENDING: {
			return {
				...state,
				is_pending: true,
				is_success: false,
				is_error: false,
				message: null
			}
		}

		case types.GET_CURRENT_USER_SUCCESS: {
			let permissions = []
			for(let role of action.payload.roles) {
				for(let p of role.permissions) {
					permissions.push(p.name)
				}
			}
			return {
				...state,
				is_pending: false,
				is_success: false,
				user: action.payload,
				permissions: permissions
			}
		}
        
		case types.AUTH_LOGIN_SUCCESS: {
			setCookie("token", action.payload.token, 1)
			setCookie("api_token", action.payload.token, 1)
			setCookie("laravel_token", action.payload.token, 1)
			return {
				...state,
				is_pending: false,
				is_success: true,
				is_error: false,
				message: action.payload.message,
				token: action.payload.token
			}
		}

		case types.AUTH_LOGIN_FAIL: {
			return {
				...state,
				is_pending: false,
				is_success: false,
				is_error: true,
				message: action.payload.message
			}
		}

        default: 
            return state
    }
}
