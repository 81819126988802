import React from 'react'
// import ReactQuill from "react-quill";
import {Link} from 'react-router-dom'
import { FormInput } from "shards-react";

import WithForm from './../hoc/WithForm'
// import InputAutocomplete from './../common/InputAutocomplete'
// import TextareaAutosize from 'react-autosize-textarea';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import DatePicker from 'react-datetime'
// import 'react-datetime/css/react-datetime.css'
// import dayjs from 'dayjs'
// import config from '../../config';

const FormProductType = ({handleSubmit, handleChange, fields, validator}) => {
    let error = {
        prefix: validator.message('prefix', fields.prefix, 'required'),
        name: validator.message('name', fields.name, 'required'),
    }
    return (
        <form onSubmit={handleSubmit}>                
            <div className="row">
                <div className="col-sm-12">
                
                    <div className="row">
                        <div className="col-sm-6 col-sm-offset-2">

                            <div className={`row form-group ${error.prefix ? 'has-error' : ''}`}>
                                <div className="col-sm-4 control-label text-right">Prefix *</div>
                                <div className="col-sm-8">
                                    <FormInput name="prefix" type="text" placeholder="Prefix *" value={fields.prefix} className="form-control mb-3" onChange={handleChange} />
                                    {error.prefix && <span className="help-block">{error.prefix}</span>}
                                </div>
                            </div>

                            <div className={`row form-group ${error.name ? 'has-error' : ''}`}>
                                <div className="col-sm-4 control-label text-right">Name *</div>
                                <div className="col-sm-8">
                                    <FormInput name="name" type="text" placeholder="Name *" value={fields.name} className="form-control mb-3" onChange={handleChange} />
                                    {error.name && <span className="help-block">{error.name}</span>}
                                </div>
                            </div>                            

                        </div>
                        
                    </div>

                </div>
            </div>

            
            <div className="row form-group">
                <div className="col-sm-12 text-center">
                    <button type="submit" className="btn btn-primary">Save</button>
                    <Link className="btn btn-secondary" to="/producttypes">Cancel</Link>
                </div>
            </div>

        </form>
    )
}

export default WithForm(FormProductType)