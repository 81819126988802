export default {
    CHANGE: "CHANGE",
    TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",

    // Authenticate
    AUTHENTICATE_PENDING: "AUTHENTICATE_PENDING",
    AUTHENTICATE_SUCCESS: "AUTHENTICATE_SUCCESS",
    AUTHENTICATE_SET_USER: "AUTHENTICATE_SET_USER",
    AUTHENTICATE_FORM_CHANGE: "AUTHENTICATE_FORM_CHANGE",

    // Caregiver Group
    CAREGIVER_GROUP_PENDING: "CAREGIVER_GROUP_PENDING",
    CAREGIVER_GROUP_FORM_CHANGE: "CAREGIVER_GROUP_FORM_CHANGE",
    FETCH_CAREGIVER_GROUPS_SUCCESS: "FETCH_CAREGIVER_GROUPS_SUCCESS",
    FETCH_CAREGIVER_GROUPS_COUNT_SUCCESS: "FETCH_CAREGIVER_GROUPS_COUNT_SUCCESS",
    FETCH_CAREGIVER_GROUP_SUCCESS: "FETCH_CAREGIVER_GROUP_SUCCESS",
    STORE_CAREGIVER_GROUP_SUCCESS: "STORE_CAREGIVER_GROUP_SUCCESS",

    // Caregiver
    CAREGIVER_PENDING: "CAREGIVER_PENDING",
    CAREGIVER_FORM_CHANGE: "CAREGIVER_FORM_CHANGE",
    FETCH_CAREGIVERS_COUNT_SUCCESS: "FETCH_CAREGIVERS_COUNT_SUCCESS",
    FETCH_CAREGIVERS_SUCCESS: "FETCH_CAREGIVERS_SUCCESS",
    FETCH_CAREGIVER_SUCCESS: "FETCH_CAREGIVER_SUCCESS",
    STORE_CAREGIVER_SUCCESS: "STORE_CAREGIVER_SUCCESS",

    // House Type
    HOUSE_TYPE_PENDING: "HOUSE_TYPE_PENDING",
    FETCH_HOUSE_TYPES_SUCCESS: "FETCH_HOUSE_TYPES_SUCCESS",
    FETCH_HOUSE_TYPES_COUNT_SUCCESS: "FETCH_HOUSE_TYPES_COUNT_SUCCESS",
    FETCH_HOUSE_TYPE_SUCCESS: "FETCH_HOUSE_TYPE_SUCCESS",
    STORE_HOUSE_TYPE_SUCCESS: "STORE_HOUSE_TYPE_SUCCESS",
    HOUSE_TYPE_FORM_CHANGE: "HOUSE_TYPE_FORM_CHANGE",

    // House
    HOUSE_PENDING: "HOUSE_PENDING",
    FETCH_HOUSES_SUCCESS: "FETCH_HOUSES_SUCCESS",
    FETCH_HOUSES_COUNT_SUCCESS: "FETCH_HOUSES_COUNT_SUCCESS",
    FETCH_HOUSE_SUCCESS: "FETCH_HOUSE_SUCCESS",
    STORE_HOUSE_SUCCESS: "STORE_HOUSE_SUCCESS",
    HOUSE_FORM_CHANGE: "HOUSE_FORM_CHANGE",


    // Upload
    UPLOAD_PENDING: "UPLOAD_PENDING"
};
