import types from './../actionTypes'

export const SetTitle = (data) => {
    return async (dispatch) => {

        dispatch({
            type: types.SET_TITLE,
            payload: data
        });
    }
}
