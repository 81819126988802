
export default {
    CHANGE: "CHANGE",
    TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",

    SET_TITLE: "SET_TITLE",

    // Auth
    AUTH_PENDING: "AUTH_PENDING",
    GET_CURRENT_USER_SUCCESS: "GET_CURRENT_USER_SUCCESS",
    AUTH_LOGIN_SUCCESS: "AUTH_LOGIN_SUCCESS",
    AUTH_LOGIN_FAIL: "AUTH_LOGIN_FAIL",

    // Authenticate
    AUTHENTICATE_PENDING: "AUTHENTICATE_PENDING",
    AUTHENTICATE_SUCCESS: "AUTHENTICATE_SUCCESS",
    AUTHENTICATE_SET_USER: "AUTHENTICATE_SET_USER",
    AUTHENTICATE_FORM_CHANGE: "AUTHENTICATE_FORM_CHANGE",

    // Product Type
    PRODUCT_TYPE_PENDING: "PRODUCT_TYPE_PENDING",
    PRODUCT_TYPE_FORM_CHANGE: "PRODUCT_TYPE_FORM_CHANGE",
    NEW_PRODUCT_TYPE: "NEW_PRODUCT_TYPE",
    FETCH_PRODUCT_TYPES_COUNT_SUCCESS: "FETCH_PRODUCT_TYPES_COUNT_SUCCESS",
    FETCH_PRODUCT_TYPES_SUCCESS: "FETCH_PRODUCT_TYPES_SUCCESS",
    FETCH_PRODUCT_TYPE_SUCCESS: "FETCH_PRODUCT_TYPE_SUCCESS",
    STORE_PRODUCT_TYPE_SUCCESS: "STORE_PRODUCT_TYPE_SUCCESS",

    // Product
    PRODUCT_PENDING: "PRODUCT_PENDING",
    PRODUCT_FORM_CHANGE: "PRODUCT_FORM_CHANGE",
    NEW_PRODUCT: "NEW_PRODUCT",
    FETCH_PRODUCTS_COUNT_SUCCESS: "FETCH_PRODUCTS_COUNT_SUCCESS",
    FETCH_PRODUCTS_SUCCESS: "FETCH_PRODUCTS_SUCCESS",
    FETCH_PRODUCT_SUCCESS: "FETCH_PRODUCT_SUCCESS",
    STORE_PRODUCT_SUCCESS: "STORE_PRODUCT_SUCCESS",

    // Sale
    SALE_PENDING: "SALE_PENDING",
    SALE_ADD_ITEM: "SALE_ADD_ITEM",
    SALE_NEW: "SALE_NEW",
    FETCH_SALES_SUCCESS: "FETCH_SALES_SUCCESS",
    FETCH_SALE_SUCCESS: "FETCH_SALE_SUCCESS",
    STORE_SALE_SUCCESS: "STORE_SALE_SUCCESS",

    // Purchase
    PURCHASE_PENDING: "PURCHASE_PENDING",
    PURCHASE_ADD_ITEM: "PURCHASE_ADD_ITEM",
    PURCHASE_NEW: "PURCHASE_NEW",
    FETCH_PURCHASES_SUCCESS: "FETCH_PURCHASES_SUCCESS",
    FETCH_PURCHASE_SUCCESS: "FETCH_PURCHASE_SUCCESS",
    STORE_PURCHASE_SUCCESS: "STORE_PURCHASE_SUCCESS",

     // Accounting
    ACCOUNTING_PENDING: "ACCOUNTING_PENDING",
    ACCOUNTING_ADD_NEW: "ACCOUNTING_ADD_NEW",
    FETCH_ACCOUNTINGS_SUCCESS: "FETCH_ACCOUNTINGS_SUCCESS",
    FETCH_ACCOUNTING_SUCCESS: "FETCH_ACCOUNTING_SUCCESS",
    STORE_ACCOUNTING_SUCCESS: "STORE_ACCOUNTING_SUCCESS",
    UPDATE_ACCOUNTING_SUCCESS: "UPDATE_ACCOUNTING_SUCCESS",

    // User
    USER_PENDING: "USER_PENDING",
    NEW_USER: "NEW_USER",
    FETCH_USERS_SUCCESS: "FETCH_USERS_SUCCESS",
    FETCH_USER_SUCCESS: "FETCH_USER_SUCCESS",
    STORE_USER_SUCCESS: "STORE_USER_SUCCESS",
    USER_FORM_CHANGE: "USER_FORM_CHANGE",

    // Role
    ROLE_PENDING: "ROLE_PENDING",
    NEW_ROLE: "NEW_ROLE",
    FETCH_ROLES_SUCCESS: "FETCH_ROLES_SUCCESS",
    FETCH_ROLE_SUCCESS: "FETCH_ROLE_SUCCESS",
    STORE_ROLE_SUCCESS: "STORE_ROLE_SUCCESS",

    // Setting
    SETTING_PENDING: "SETTING_PENDING",
    FETCH_SETTINGS_SUCCESS: "FETCH_SETTINGS_SUCCESS",
    UPDATE_SETTING_SUCCESS: "UPDATE_SETTING_SUCCESS",
    FETCH_SETTING_ACCOUNTING_NUMBERS_SUCCESS: "FETCH_SETTING_ACCOUNTING_NUMBERS_SUCCESS",
    UPDATE_SETTING_ACCOUNTING_NUMBERS_SUCCESS: "UPDATE_SETTING_ACCOUNTING_NUMBERS_SUCCESS",

    // Report
    REPORT_PENDING: "REPORT_PENDING",
    FETCH_REPORT_SALE: "FETCH_REPORT_SALE",

    // Upload
    UPLOAD_PENDING: "UPLOAD_PENDING",
};
