import dayjs from 'dayjs'
import initialState from './initialState'
import types from './../actionTypes'

export default (state = initialState.saleStore, action = {}) => {

    switch(action.type) {

        case types.SALE_PENDING: {
            return {
                ...state,
                is_pending: true,
                is_success: false,
                is_fail: false
            }
        }

        case types.SALE_ADD_ITEM: {
            if(!state.sale.items) state.sale.items = []
            state.sale.items.push(action.payload)
            return {
                ...state,
                is_pending: false,
                is_success: false,
                is_fail: false,
                sale: state.sale
            }
        }

        case types.SALE_NEW: {
            return {
                ...state,
                is_pending: false,
                is_success: false,
                is_fail: false,
                sale: {
                    receive_date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                    sale_date: dayjs().format("YYYY-MM-DD HH:mm:ss")
                }
            }
        }

        case types.FETCH_SALES_SUCCESS: {
            return {
                ...state,
                is_pending: false,
                is_success: false,
                is_fail: false,
                sale: {
                    receive_date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                    sale_date: dayjs().format("YYYY-MM-DD HH:mm:ss")
                },
                sales: action.payload.data,
                sales_count: action.payload.total,
            }
        }

        case types.FETCH_SALE_SUCCESS: {
            return {
                ...state,
                is_pending: false,
                is_success: false,
                is_fail: false,
                sale: action.payload.sale
            }
        }

        case types.STORE_SALE_SUCCESS: {
            return {
                ...state,
                is_pending: false,
                is_success: true,
                sale: action.payload.sale
            }
        }

        default: 
            return state
    }
}