import React from 'react'
import {Link} from 'react-router-dom'
import {Logout} from './../../flux/actions/authenticateAction'

const Header = ({user}) => {
    return (
        <header className="main-header">

            <a href="/" className="logo">

                <span className="logo-mini"><b>J</b>ST</span>

                <span className="logo-lg"><b>J</b>Stock</span>
            </a>


            <nav className="navbar navbar-static-top" role="navigation">

                <a href="#" className="sidebar-toggle" data-toggle="push-menu" role="button">
                    <span className="sr-only">Toggle navigation</span>
                </a>

                <div className="navbar-custom-menu">
                    <ul className="nav navbar-nav">
                        <li style={{padding: '5px 10px'}}>
                            <img src="/images/buu.png" style={{height: 40}}/>
                        </li>
                        <li style={{padding: '5px 10px'}}>
                            <img src="/images/rmutto.png" style={{height: 40}}/>
                        </li>
                        <li style={{padding: '5px 10px'}}>
                            <img src="/images/tsri.png" style={{height: 40}}/>
                        </li>
                        <li className="dropdown user user-menu">

                            <a href="#" className="dropdown-toggle" data-toggle="dropdown">

                                {/* <img src="/themes/dist/img/user2-160x160.jpg" className="user-image" alt="User Image"/> */}

                                <span className="hidden-xs">{user ? user.name : ''}</span>
                            </a>
                            <ul className="dropdown-menu">

                                <li className="user-header">
                                    <img src="/themes/dist/img/user2-160x160.jpg" className="img-circle" alt="User Image"/>

                                    <p>
                                    {user ? user.name : ''}
                                    <small>{user ? user.email : ''}</small>
                                    </p>
                                </li>

                                <li className="user-footer">
                                    <div className="pull-left">
                                        {/* <Link to="/profile" className="btn btn-default btn-flat">Profile</Link> */}
                                    </div>
                                    <div className="pull-right">
                                        <button className="btn btn-default btn-flat" type='button' onClick={Logout}>Sign out</button>

                                    </div>
                                </li>
                            </ul>
                        </li>

                    
                    </ul>
                </div>
            </nav>
        </header>
    )
}

export default Header