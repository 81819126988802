import React from 'react'
import { Formik, FieldArray } from 'formik';
import * as Yup from "yup";
import {Link} from 'react-router-dom'
import { FormInput } from "shards-react";
import InputAutocomplete from './../common/InputAutocomplete'
import Thumb from './../common/Thumb'
import FieldInput from './common/FieldInput'
import FieldTextarea from './common/FieldTextarea'
import FieldDatePicker from './common/FieldDatePicker'
import config from '../../config';

const calTotalCost = (costs) => {
    let total = costs && costs.length > 0 ? costs.reduce((acc, cur) => acc + ( (cur.cost_price * cur.cost_quantity) ? (cur.cost_price * cur.cost_quantity) : 0) ,0) : 0
    let vat = costs && costs.length > 0 ? costs.reduce((acc, cur) => acc + ( parseFloat(cur.cost_vat) ? parseFloat(cur.cost_vat) : 0) ,0) : 0
    return (total + vat).toFixed(2)
}

const FormProduct = ({onSubmit, initialValues, producttypes, addToSaleItem, goToSale}) => {
    
    return (
        <Formik 
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values) => onSubmit(values)} 
            validateOnBlur
            validationSchema={
                Yup.object().shape({
                    product_type_id: Yup.mixed().required().label('ประเภทสินค้า'),
                    prefix: Yup.mixed().required().label('หมวดตัวย่อสินค้า'),
                    name: Yup.string().required().label('ชื่อสินค้า'),
                    unit: Yup.string().label('หน่วย'),
                })
            }
        >
        {({ values, handleSubmit, setFieldValue, handleChange, errors }) => {

                return (
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-12 col-md-6">

                                <div className="row form-group">
                                    <div className="col-sm-4 control-label text-right">เลขทะเบียน</div>
                                    <div className="col-sm-8">
                                        <FormInput name="product_full_code" type="text" placeholder="เลขทะเบียน" value={values.product_full_code} className="form-control mb-3" onChange={handleChange} disabled/>
                                    </div>
                                </div>

                                <div className={`row form-group ${errors.product_type_id ? 'has-error' : ''}`}>
                                    <div className="col-sm-4 control-label text-right">ประเภทสินค้า *</div>
                                    <div className="col-sm-8">
                                        <select className="form-control" name="product_type_id" id="product_type_id" onChange={handleChange} value={values.product_type_id}>
                                            <option value="">-- ประเภทสินค้า --</option>
                                            {
                                                producttypes && producttypes.length > 0 ?
                                                    producttypes.map(type => {
                                                        return (
                                                            <option value={type.id} key={type.id}>{type.name+' ('+type.prefix+')'}</option>
                                                        )
                                                    })
                                                : null
                                            }
                                        </select>
                                        {errors.product_type_id && <span className="help-block">{errors.product_type_id}</span>}
                                    </div>
                                </div>

                                <div className={`row form-group ${errors.prefix ? 'has-error' : ''}`}>
                                    <div className="col-sm-4 control-label text-right">หมวดตัวย่อสินค้า *</div>
                                    <div className="col-sm-8">
                                        <InputAutocomplete 
                                            type="select"
                                            placeholder="หมวดตัวย่อสินค้า..."
                                            items={[]}
                                            defaultValue={values.prefix}
                                            itemLabelKey={['prefix']}
                                            itemValueKey={['prefix']}
                                            onSelect={(item) => setFieldValue('prefix', item.prefix) }
                                            onChange={(e, value) => setFieldValue('prefix', value)}
                                            isAjax={true}
                                            ajaxUrl="/api/products/model/prefix"
                                        />
                                        {errors.prefix && <span className="help-block">{errors.prefix}</span>}
                                    </div>
                                </div>

                                <FieldInput 
                                    name="name" 
                                    label="ชื่อสินค้า *"
                                    type="text"
                                    value={values.name}
                                    error={errors.name}
                                    handleChange={handleChange}
                                />

                                <FieldTextarea 
                                    name="description"
                                    label="รายละเอียดสินค้า *"
                                    value={values.description}
                                    error={errors.description}
                                    handleChange={handleChange}
                                />

                                <FieldInput 
                                    name="quantity" 
                                    label="จำนวนเริ่มต้น"
                                    type="number"
                                    value={values.quantity}
                                    error={errors.quantity}
                                    handleChange={(e) => {
                                        setFieldValue('quantity', e.target.value)
                                        setFieldValue('cost_price', e.target.value ? calTotalCost(values.costs) / e.target.value : calTotalCost(values.costs) )
                                    }}
                                />

                                <div className={`row form-group ${errors.unit ? 'has-error' : ''}`}>
                                    <div className="col-sm-4 control-label text-right">หน่วย</div>
                                    <div className="col-sm-8">
                                        <InputAutocomplete 
                                            type="select"
                                            placeholder="หน่วย..."
                                            defaultValue={values.unit}
                                            itemLabelKey={['unit']}
                                            itemValueKey={['unit']}
                                            onSelect={(item) => setFieldValue('unit', item.unit) }
                                            onChange={(e, value) => setFieldValue('unit', value)}
                                            isAjax={true}
                                            ajaxUrl="/api/products/model/unit"
                                        />
                                        {errors.unit && <span className="help-block">{errors.unit}</span>}
                                    </div>
                                </div>

                                <FieldInput 
                                    name="cost_price" 
                                    label="ราคาทุน/หน่วย"
                                    type="number"
                                    value={values.cost_price}
                                    error={errors.cost_price}
                                    handleChange={handleChange}
                                />

                                <FieldDatePicker 
                                    name="pickup_date" 
                                    label="วันที่รับเข้า"
                                    value={values.pickup_date}
                                    error={errors.pickup_date}
                                    handleChange={handleChange}
                                />

                                <FieldInput 
                                    name="sell_price" 
                                    label="ราคาขาย/หน่วย"
                                    type="number"
                                    value={values.sell_price}
                                    error={errors.sell_price}
                                    handleChange={handleChange}
                                />

                                <FieldDatePicker 
                                    name="soldout_date" 
                                    label="วันที่ขายออก"
                                    value={values.soldout_date}
                                    error={errors.soldout_date}
                                    handleChange={handleChange}
                                />

                                <div className={`row form-group ${errors.status ? 'has-error' : ''}`}>
                                    <div className="col-sm-4 control-label text-right">สถานะ</div>
                                    <div className="col-sm-8">
                                        <select name="status" id="status" className="form-control" value={values.status} onChange={handleChange}>
                                            <option value="">-- --</option>
                                            <option value="in-stock">in stock</option>
                                            <option value="out-of-stock">out of stock</option>
                                            <option value="sold-out">sold out</option>
                                        </select>
                                        {errors.status && <span className="help-block">{errors.status}</span>}
                                    </div>
                                </div>

                                <FieldTextarea 
                                    name="notes"
                                    label="หมายเหตุ"
                                    value={values.notes}
                                    error={errors.notes}
                                    handleChange={handleChange}
                                />

                                <div className="row form-group">
                                    <div className="col-sm-4 text-right">ใบรับรอง</div>
                                    <div className="col-sm-8">
                                        <FieldArray
                                            name="attachments"
                                            render={arrayHelpers => (
                                                <div>
                                                    {
                                                        values.attachments && values.attachments.length > 0 ?
                                                            values.attachments.map( (attach, index) => {
                                                                return (
                                                                    <div key={index} style={{display: 'flex'}}>
                                                                        <div style={{flex: 1}}>
                                                                            <a href={config.BASE_API + '/' + attach.url}>{attach.name}</a>
                                                                        </div>
                                                                        <div style={{flex: 0}}>
                                                                            <a href="#" onClick={(e) => {e.preventDefault();arrayHelpers.remove(index)} } className="text-danger"><i className="fa fa-remove"></i></a>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        : null
                                                    }
                                                    <input type="file" name="attachment" accept=".jpg,.jpeg,.png,.gif,.doc,.docx,.pdf"  multiple onChange={(event) => setFieldValue("file_attachments", event.target.files)}/>
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>

                                {values.status === 'sold-out' && <div className="row form-group">
                                    <div className="col-sm-4 control-label text-right">ใบเสร็จ</div>
                                    <div className="col-sm-8" >
                                        <FieldArray
                                            name="receipts"
                                            render={arrayHelpers => (
                                                <div>
                                                    {
                                                        values.receipts && values.receipts.length > 0 ?
                                                            values.receipts.map( (attach, index) => {
                                                                return (
                                                                    <div key={index} style={{display: 'flex'}}>
                                                                        <div style={{flex: 1}}>
                                                                            <a href={config.BASE_API + '/' + attach.url}>{attach.name}</a>
                                                                        </div>
                                                                        <div style={{flex: 0}}>
                                                                            <a href="#" onClick={(e) => {e.preventDefault();arrayHelpers.remove(index)} } className="text-danger"><i className="fa fa-remove"></i></a>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        : null
                                                    }
                                                    <input type="file" name="attachment" accept=".jpg,.jpeg,.png,.gif,.doc,.docx,.pdf"  multiple onChange={(event) => setFieldValue("file_receipts", event.target.files)}/>
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>}

                                <div className="row form-group">
                                    <div className="col-sm-4 control-label text-right">ภาพสินค้า</div>
                                    <div className="col-sm-8">
                                        <input style={{display: 'none'}} accept="image/*" id="imageProduct" type="file" name="product_image" multiple onChange={(event) => setFieldValue("file_images", event.target.files)} />
                                        <div className="row">
                                            <div className="col-sm-12" style={{display: 'flex', 'flexWrap': 'wrap'}} id="imgPreview">
                                                <FieldArray
                                                    name="images"
                                                    render={arrayHelpers => (
                                                        <div>
                                                            {
                                                                values.images && values.images.length > 0 ?
                                                                    values.images.map((image, index) => {
                                                                        return (
                                                                            <div key={index} style={{flex: '1 0 49%', margin: '2px', border: '1px solid #efefef', position: 'relative'}}>
                                                                                <a href="#" style={{top: 0, right: 0, position: 'absolute', color: 'red'}} onClick={(e) => {e.preventDefault();arrayHelpers.remove(index)}}><i className="fa fa-trash"></i></a>
                                                                                <img style={{width: '100%', objectFit: 'contain', minHeight: '200px'}} src={config.BASE_API + '/' + image.url}/>
                                                                            </div>
                                                                        )
                                                                    })
                                                                : null
                                                            }
                                                        </div>
                                                    )}
                                                />
                                                <FieldArray
                                                    name="file_images"
                                                    render={arrayHelpers => (
                                                        <div>
                                                            {
                                                                values.file_images && values.file_images[0] ?
                                                                    Object.keys(values.file_images).map((image, index) => {
                                                                        return (
                                                                            <div key={index} style={{flex: '1 0 49%', margin: '2px', border: '1px solid #efefef', position: 'relative'}}>
                                                                                <a href="#" style={{top: 0, right: 0, position: 'absolute', color: 'red'}} onClick={(e) => {e.preventDefault();arrayHelpers.remove(index)}}><i className="fa fa-trash"></i></a>
                                                                                <Thumb style={{width: '100%', objectFit: 'contain', minHeight: '200px'}} file={values.file_images[image]} />
                                                                            </div>
                                                                        )
                                                                    })
                                                                : null
                                                            }
                                                        </div>
                                                    )}
                                                />
                                                
                                            </div>
                                        </div>
                                        <button type="button" className="btn btn-app btn-block" onClick={() => document.getElementById('imageProduct').click()} style={{margin: '0px', width: '100px'}}>
                                            <i className="fa fa-image"></i> Add image
                                        </button>
                                        
                                    </div>
                                </div>

                                {values.id && <div className="row form-group">
                                    <div className="col-sm-4 text-right">Modified date</div>
                                    <div className="col-sm-8">
                                        {values.updated_at}
                                    </div>
                                </div>}

                                {values.id && <div className="row form-group">
                                    <div className="col-sm-4 text-right">Modified by</div>
                                    <div className="col-sm-8">
                                        {values.user ? values.user.name : ''}
                                    </div>
                                </div>}

                            </div>
                            <div className="col-sm-12 col-md-6">
                                <FieldArray
                                    name="costs"
                                    render={arrayHelpers => (
                                        <div>
                                            {
                                                values.costs && values.costs.length > 0 ?
                                                    values.costs.map((cost, index) => {
                                                        return (
                                                            <div className="row form-group" key={index}>
                                                                <div className="col-sm-12" style={{display: 'flex'}}>
                                                                    <div style={{flex: 1}}>
                                                                        <div>ชื่อทุน</div>
                                                                        <InputAutocomplete 
                                                                            type="select"
                                                                            placeholder="ชื่อทุน..."
                                                                            items={[]}
                                                                            defaultValue={cost.cost_name}
                                                                            itemLabelKey={['product_full_code', 'name']}
                                                                            itemValueKey={['product_full_code', 'name']}
                                                                            onSelect={(item) => {
                                                                                if(item.id) setFieldValue(`costs[${index}].product_from_id`, item.id);
                                                                                setFieldValue(`costs[${index}].cost_name`, item.product_full_code);
                                                                                setFieldValue(`costs[${index}].cost_price`, item.sell_price);
                                                                                
                                                                            }}
                                                                            onChange={(e, value) => setFieldValue(`costs[${index}].cost_name`, value)}
                                                                            isAjax={true}
                                                                            ajaxUrl="/api/products"
                                                                        />
                                                                    </div>
                                                                    <div style={{display: 'flex', flex: 1, paddingLeft: 5}}>
                                                                        <div style={{flex: 1, textAlign: 'right'}}>
                                                                            <div>จำนวน</div>
                                                                            <FormInput 
                                                                                name={`costs[${index}].cost_quantity`} 
                                                                                type="text" 
                                                                                placeholder="จำนวน" 
                                                                                value={cost.cost_quantity} 
                                                                                className="form-control text-right mb-3" 
                                                                                onChange={(e) => {
                                                                                    values.costs[index].cost_quantity = e.target.value
                                                                                    setFieldValue('cost_price', values.quantity ? calTotalCost(values.costs) / values.quantity : calTotalCost(values.costs) )
                                                                                    setFieldValue(`costs[${index}].cost_quantity`, e.target.value)
                                                                                    // setTimeout(() => {
                                                                                    //     setFieldValue('cost_price', calTotalCost(values.costs))
                                                                                    // }, 500)
                                                                                }} 
                                                                            />
                                                                        </div>
                                                                        <div style={{flex: 1, textAlign: 'right', paddingLeft: 5, whiteSpace: 'nowrap'}}>
                                                                            <div>ราคาทุน/หน่วย</div>
                                                                            <FormInput 
                                                                                name={`costs[${index}].cost_price`} 
                                                                                type="text" 
                                                                                placeholder="ราคาทุนต่อหน่วย" 
                                                                                value={cost.cost_price} 
                                                                                className="form-control text-right mb-3" 
                                                                                onChange={(e) => {
                                                                                    values.costs[index].cost_price = e.target.value
                                                                                    setFieldValue('cost_price', values.quantity ? calTotalCost(values.costs) / values.quantity : calTotalCost(values.costs) )
                                                                                    setFieldValue(`costs[${index}].cost_price`, e.target.value)
                                                                                    // setTimeout(() => {
                                                                                    // }, 500)
                                                                                }} 
                                                                            />
                                                                        </div>
                                                                        <div style={{flex: 1, textAlign: 'right', paddingLeft: 5}}>
                                                                            <div>vat</div>
                                                                            <div className="input-group">
                                                                                <FormInput name={`costs[${index}].cost_vat`} disabled type="text" placeholder="vat" value={cost.cost_vat} className="form-control text-right mb-3" onChange={handleChange} />
                                                                                <span className="input-group-btn">
                                                                                    <button 
                                                                                        className="btn btn-default" 
                                                                                        type="button"
                                                                                        onClick={() => {
                                                                                            setFieldValue(`costs[${index}].cost_vat_method`, cost.cost_vat_method === 'out' ? 'no' : 'out');
                                                                                            setFieldValue(`costs[${index}].cost_vat`, cost.cost_vat_method === 'no' ? 0 : (cost.cost_price * cost.cost_quantity ? (cost.cost_price * cost.cost_quantity) * 0.07 : 0) );
                                                                                        }}
                                                                                    >
                                                                                        <i className={`fa fa-${ cost.cost_vat_method && cost.cost_vat_method === 'out' ? 'check' : 'remove'}`}></i>
                                                                                    </button>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div style={{flex: 0, paddingLeft: 5, paddingTop: 20}}>
                                                                            <button className="btn btn-danger" onClick={() => arrayHelpers.remove(index)} type="button"><i className="fa fa-remove text-danger"></i></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                : null
                                            }
                                            <div className="row form-group">
                                                <div className="col-sm-12" style={{display: 'flex'}}>
                                                    <div style={{flex: 1}}>
                                                        <button className="btn btn-default" onClick={() => arrayHelpers.push({})} type="button"><i className="fa fa-plus"></i> เพิ่มรายการทุน</button>
                                                    </div>
                                                    <div style={{display: 'flex', flex: 1, paddingLeft: 5}}>
                                                        <div style={{flex: 1, textAlign: 'right'}}></div>
                                                        <div style={{flex: 1, fontSize: 16, textAlign: 'right', whiteSpace: 'nowrap'}}>
                                                            <div>ราคาทุนรวม</div>
                                                        </div>
                                                        <div style={{flex: 1, fontSize: 16, textAlign: 'right', paddingLeft: 5}}>
                                                            {calTotalCost(values.costs)}
                                                        </div>
                                                        <div style={{ width: 40, paddingLeft: 5, paddingTop: 20}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                />

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <button type="submit" className="btn btn-primary"><i className="fa fa-save"></i> บันทึก</button>
                                {' '}
                                <Link className="btn btn-default" to="/products"><i className="fa fa-block"></i> ยกเลิก</Link>
                                {' '}
                                <button type="button" className="btn btn-primary" onClick={() => addToSaleItem(values, goToSale)}><i className="fa fa-plus"></i> เพิ่มรายการขาย</button>
                            </div>
                        </div>
                    </form>
                );
            }} 
        </Formik>
        
    )
}

export default FormProduct