import types from './../actionTypes'
import config from './../../config'
import {getCookie} from './../../utils/cookie'
import axios from 'axios'

export function FetchReportSale(query) {
    return async (dispatch) => {
        // try {
            dispatch({
                type: types.REPORT_PENDING,
                payload: null
            })

            axios.get(config.BASE_API + '/api/reports/sales', {
                params: {
                    // '_limit': 10,
                    ...query
                },
                headers: {
                    Authorization: `Bearer ${getCookie("token")}`
                }
            })
            .then(response => {
                dispatch({
                    type: types.FETCH_REPORT_SALE,
                    payload: {
                        sale: response.data
                    }
                });
            })
            .catch(error => {
                // Handle error.
                console.log('An error occurred:', error);
            });
       
    }
}