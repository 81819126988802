import types from './../actionTypes'
import config from './../../config'
import {getCookie} from './../../utils/cookie'
import axios from 'axios'

export function FetchProductTypes(query) {
    return async (dispatch) => {
        // try {
            dispatch({
                type: types.PRODUCT_TYPE_PENDING,
                payload: null
            })

            axios.get(config.BASE_API + '/api/producttypes', {
                params: {
                    // '_limit': 10,
                    ...query
                },
                headers: {
                    Authorization: `Bearer ${getCookie("token")}`
                }
            })
            .then(response => {
                dispatch({
                    type: types.FETCH_PRODUCT_TYPES_SUCCESS,
                    payload: response.data
                });
            })
            .catch(error => {
                // Handle error.
                console.log('An error occurred:', error);
            });
       
    }
}


export const FetchProductTypeByID = (id) => {
    return async (dispatch) => {
        dispatch({
            type: types.PRODUCT_TYPE_PENDING,
            payload: null
        })
        axios.get(config.BASE_API + '/api/producttypes/' + id, {
            headers: {
                Authorization: `Bearer ${getCookie("token")}`
            }
        })
        .then(response => {
            dispatch({
                type: types.FETCH_PRODUCT_TYPE_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
          // Handle error.
          console.log('An error occurred:', error);
        });
    }
}


export const NewProductType = () => {
    return async (dispatch) => {
        dispatch({
            type: types.NEW_PRODUCT_TYPE,
            payload: {}
        });
    }
}


export const StoreProductType = (data) => {
    return async (dispatch) => {
        dispatch({
            type: types.PRODUCT_PENDING,
            payload: null
        });
    
        axios.post(config.BASE_API + '/api/producttypes', data, {
            headers: {
                Authorization: `Bearer ${getCookie("token")}`
            }
        })
        .then(response => {
            dispatch({
                type: types.STORE_PRODUCT_TYPE_SUCCESS,
                payload: response.data && response.data.data ? response.data.data : {}
            });
        })
        .catch(error => {
          // Handle error.
          console.log('An error occurred:', error);
        });
    }
}

export const UpdateProductType = (data) => {
    return async (dispatch) => {

        dispatch({
            type: types.PRODUCT_TYPE_PENDING,
            payload: null
        });
    
        axios.put(config.BASE_API + '/api/producttypes/' + data.id, data, {
            headers: {
                Authorization: `Bearer ${getCookie("token")}`
            }
        })
        .then(response => {
            dispatch({
                type: types.STORE_PRODUCT_TYPE_SUCCESS,
                payload: response.data && response.data.data ? response.data.data : {}
            });
        })
        .catch(error => {
          // Handle error.
          console.log('An error occurred:', error);
        });
    }
}

export const DeleteProductType = (data) => {
    return async (dispatch) => {
        dispatch({
            type: types.PRODUCT_TYPE_PENDING,
            payload: null
        });

        axios.delete(config.BASE_API + '/api/producttypes/' + data.id, {
            headers: {
                Authorization: `Bearer ${getCookie("token")}`
            }
        })
        .then(response => {
            dispatch({
                type: types.STORE_PRODUCT_TYPE_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
          // Handle error.
          console.log('An error occurred:', error);
        });
    }
}

export const OnChangeForm = (e, data) => {
    return async (dispatch) => {
        data[e.target.name] = e.target.value
        dispatch({
            type: types.PRODUCT_TYPE_FORM_CHANGE,
            payload: data
        });
    }
}