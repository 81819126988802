import initialState from './initialState'
import types from './../actionTypes'

export default (state = initialState.userStore, action = {}) => {

    switch(action.type) {

        case types.USER_PENDING: {
            return {
                ...state,
                pending: true,
                is_success: false
            }
        }

        case types.USER_FORM_CHANGE: {

            return {
                ...state,
                user: action.payload
            }
        }

        case types.NEW_USER: {
            return {
                ...state,
                pending: false,
                is_success: false,
                user: {}
            }
        }

       

        case types.FETCH_USERS_SUCCESS: {
            return {
                ...state,
                pending: false,
                users: action.payload.data,
                users_count: action.payload.total,
            }
        }

        case types.FETCH_USER_SUCCESS: {
            return {
                ...state,
                pending: false,
                is_success: false,
                user: action.payload,
                form: action.payload,
            }
        }

        case types.STORE_USER_SUCCESS: {
            return {
                ...state,
                pending: false,
                is_success: true,
                user: action.payload
            }
        }

        default: 
            return state
    }
}