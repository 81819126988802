import React, {useState} from 'react'
import {Link} from 'react-router-dom'

const CheckPermission = (permissions, per) => {
    if(permissions && permissions.length > 0) {
        return permissions.filter(p => p === per).length > 0 ? true : false
    }
}

const Sidebar = ({match, user, permissions}) => {
    const {path} = match
    const [openSale, setOpenSale] = useState(false);
    const [openPurchase, setOpenPurchase] = useState(false);
    const [openProduct, setOpenProduct] = useState(false);
    const [openAcc, setOpenAcc] = useState(false);
    const [openUser, setOpenUser] = useState(false);
    const [openSetting, setOpenSetting] = useState(false);
    // console.log(permissions.filter(p => p === 'View Sales'))
    return (
        <aside className="main-sidebar">


            <section className="sidebar">


                <div className="user-panel">
                    <div className="pull-left image">
                        <img src="/themes/dist/img/user2-160x160.jpg" className="img-circle" alt="User Image"/>
                    </div>
                    <div className="pull-left info">
                        <p>{user ? user.name : ''}</p>

                        <a href="#"> {user ? user.email : ''}</a>
                    </div>
                </div>

                <ul className="sidebar-menu tree" data-widget="tree">
                    <li className="header">MAIN MENU</li>
                    {
                        CheckPermission(permissions, 'View Sales') ?
                            <li className={`treeview ${openSale ? 'menu-open' : ''} ${ path === '/sales' || path === '/sales/create' || path === '/sales/edit/:id' || path === '/sales/installments'  ? 'menu-open active' : ''}`}>
                                <a href="#" onClick={(e) => {e.preventDefault();setOpenSale(!openSale)} }><i className="fa fa-dollar"></i> <span> ขาย</span>
                                    <span className="pull-right-container">
                                        <i className="fa fa-angle-left pull-right"></i>
                                    </span>
                                </a>
                                <ul className="treeview-menu" style={{display: openSale || path === '/sales' ? 'block' : 'none'}}>
                                    { CheckPermission(permissions, 'Create Sales') ? <li className={`${ path === '/sales/create' ? 'active' : ''}`}><Link to="/sales/create"><span>ขายสินค้า</span></Link></li> : null }
                                    { CheckPermission(permissions, 'View Sales') ? <li className={`${ path === '/sales' ? 'active' : ''}`}><Link to="/sales"><span>รายการขาย</span></Link></li> : null }
                                    { CheckPermission(permissions, 'View Sales') ? <li className={`${ path === '/sales/installments' ? 'active' : ''}`}><Link to="/sales/installments"><span>รายการผ่อนจ่าย</span></Link></li> : null }
                                </ul>
                            </li> 
                        : null
                    }
                    {
                        CheckPermission(permissions, 'View Sales') ?
                            <li className={`treeview ${openPurchase ? 'menu-open' : ''} ${ path === '/purchases' || path === '/purchases/create' || path === '/purchases/edit/:id' ? 'menu-open active' : ''}`}>
                                <a href="#" onClick={(e) => {e.preventDefault();setOpenPurchase(!openPurchase)} }><i className="fa fa-dollar"></i> <span> ซื้อ</span>
                                    <span className="pull-right-container">
                                        <i className="fa fa-angle-left pull-right"></i>
                                    </span>
                                </a>
                                <ul className="treeview-menu" style={{display: openPurchase || path === '/purchases' ? 'block' : 'none'}}>
                                    { CheckPermission(permissions, 'Create Sales') ? <li className={`${ path === '/purchases/create' ? 'active' : ''}`}><Link to="/purchases/create"><span>ซื้อสินค้า</span></Link></li> : null }
                                    { CheckPermission(permissions, 'View Sales') ? <li className={`${ path === '/purchases' ? 'active' : ''}`}><Link to="/purchases"><span>รายการซื้อ</span></Link></li> : null }
                                </ul>
                            </li> 
                        : null
                    }
                    {
                        CheckPermission(permissions, 'View Products') ?
                            <li className={`treeview ${openProduct ? 'menu-open' : ''} ${ path === '/products' || path === '/products/create' || path === '/products/edit/:id' || path === '/products/receive' ? 'menu-open active' : ''}`}>
                                <a href="#" onClick={(e) => {e.preventDefault();setOpenProduct(!openProduct)} }><i className="fa fa-cubes"></i> <span> สินค้า</span>
                                    <span className="pull-right-container">
                                        <i className="fa fa-angle-left pull-right"></i>
                                    </span>
                                </a>
                                <ul className="treeview-menu" style={{display: openProduct || path === '/products' ? 'block' : 'none'}}>
                                    { CheckPermission(permissions, 'Create Products') ? <li className={`${ path === '/products/create' ? 'active' : ''}`}><Link to="/products/create"><span>เพิ่มสินค้าใหม่</span></Link></li> : null}
                                    { CheckPermission(permissions, 'Receive Products') ? <li className={`${ path === '/products/receive' ? 'active' : ''}`}><Link to="/products/receive"><span>รับเข้าสินค้า</span></Link></li> : null}
                                    { CheckPermission(permissions, 'View Products') ? <li className={`${ path === '/products' ? 'active' : ''}`}><Link to="/products"><span>รายการสินค้า</span></Link></li> : null}
                                    { CheckPermission(permissions, 'Balance Products') ? <li className={`${ path === '/products/balance' ? 'active' : ''}`}><Link to="/products/balance"><span>สินค้าคงเหลือ</span></Link></li> : null}
                                </ul>
                            </li>
                        : null
                    }
                    {
                        CheckPermission(permissions, 'View ProductTypes') ?
                            <li className={path === '/producttypes' ? 'active' : ''}>
                                <Link to="/producttypes"><i className="fa fa-cube"></i> <span>ประเภทสินค้า</span></Link>
                            </li>
                        : null
                    }
                    {
                        CheckPermission(permissions, 'View AccountingJournals') ?
                            <li className={`treeview ${openAcc ? 'menu-open' : ''} ${ path === '/accountings' || path === '/accountings/create' || path === '/accountings/edit/:id' || path === '/accountings/numbers' || path === '/accountings/ledgers'  ? 'menu-open active' : ''}`}>
                                <a href="#" onClick={(e) => {e.preventDefault();setOpenAcc(!openAcc)} }><i className="fa fa-clipboard"></i> <span> บัญชี</span>
                                    <span className="pull-right-container">
                                        <i className="fa fa-angle-left pull-right"></i>
                                    </span>
                                </a>
                                <ul className="treeview-menu" style={{display: openAcc || path === '/accountings' || path === '/accountings/numbers' || path === '/accountings/ledgers' ? 'block' : 'none'}}>
                                    { CheckPermission(permissions, 'View AccountingJournals') ? <li className={`${ path === '/accountings' ? 'active' : ''}`}><Link to="/accountings"><span>สมุดรายวันทั่วไป</span></Link></li> : null}
                                    { CheckPermission(permissions, 'View AccountingJournals') ? <li className={`${ path === '/accountings/ledgers' ? 'active' : ''}`}><Link to="/accountings/ledgers"><span>บัญชีแยกประเภท</span></Link></li> : null}
                                    { CheckPermission(permissions, 'View AccountingNumbers') ? <li className={`${ path === '/accountings/numbers' ? 'active' : ''}`}><Link to="/accountings/numbers"><span>เลขที่บัญชี</span></Link></li> : null}
                                </ul>
                            </li> 
                    : null}
                    {/* <li className={path === '/accountings' ? 'active' : ''}>
                        <Link to="/accountings"><i className="fa fa-clipboard"></i> <span>บัญชี</span></Link>
                    </li> */}
                    {
                        CheckPermission(permissions, 'View Users') ?
                            <li className={`treeview ${openUser ? 'menu-open' : ''} ${ path === '/users' || path === '/users/create' || path === '/users/edit/:id' || path === '/users/roles'  ? 'menu-open active' : ''}`}>
                                <a href="#" onClick={(e) => {e.preventDefault();setOpenUser(!openUser)} }><i className="fa fa-users"></i> <span> ผู้ใช้</span>
                                    <span className="pull-right-container">
                                        <i className="fa fa-angle-left pull-right"></i>
                                    </span>
                                </a>
                                <ul className="treeview-menu" style={{display: openUser || path === '/users' || path === '/users/roles' ? 'block' : 'none'}}>
                                    { CheckPermission(permissions, 'View Users') ? <li className={`${ path === '/users' ? 'active' : ''}`}><Link to="/users"><span>ผู้ใช้</span></Link></li> : null }
                                    { CheckPermission(permissions, 'View Roles') ? <li className={`${ path === '/users/roles' ? 'active' : ''}`}><Link to="/users/roles"><span>กลุ่มผู้ใช้และสิทธ์</span></Link></li> : null }
                                </ul>
                            </li> 
                    : null}
                    {/* <li className={path === '/settings' ? 'active' : ''}>
                        <Link to="/settings"><i className="fa fa-cog"></i> <span>ตั้งค่า</span></Link>
                    </li> */}
                    {
                        CheckPermission(permissions, 'View SettingOrg') ?
                            <li className={`treeview ${openSetting ? 'menu-open' : ''} ${ path === '/settings' || path === '/settings/accountings' ? 'menu-open active' : ''}`}>
                                <a href="#" onClick={(e) => {e.preventDefault();setOpenSetting(!openSetting)} }><i className="fa fa-cog"></i> <span> ตั้งค่า</span>
                                    <span className="pull-right-container">
                                        <i className="fa fa-angle-left pull-right"></i>
                                    </span>
                                </a>
                                <ul className="treeview-menu" style={{display: openSetting || path === '/settings' ? 'block' : 'none'}}>
                                    { CheckPermission(permissions, 'View SettingOrg') ? <li className={`${ path === '/settings' ? 'active' : ''}`}><Link to="/settings"><span>องค์กร</span></Link></li> : null }
                                </ul>
                            </li>
                    : null }
                </ul>

                <div style={{position: 'absolute', bottom: 0, left: 0, color: 'darkgrey', fontSize: 11, padding: 5}}>
                    <strong>Copyright &copy; 2019 <a href="http://ddd-th.com">dodreamdifference co.,ltd</a>.</strong>
                </div>

            </section>
        </aside>
    )
}

export default Sidebar