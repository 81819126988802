import React from 'react'
import {Link} from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'

const FormUser = ({initialValues, onSubmit, roles}) => {
    return (
        <Formik 
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values) => onSubmit(values)}
            validationSchema={
                Yup.object().shape({
                    name: Yup.mixed().required().label('ชื่อ'),
                    email: Yup.mixed().required().label('อีเมล'),
                    password: Yup.mixed().required().label('รหัสผ่าน'),
                    password_confirmation: Yup.mixed().required().label('ยืนยันรหัสผ่าน'),
                })
            }
        >
            {({ values, handleSubmit, handleChange, errors, touched, setFieldValue }) => {
                return (
                    <form onSubmit={handleSubmit}>                
                        <div className="row">
                            <div className="col-sm-12">
                            
                                <div className="row">
                                    <div className="col-sm-6 col-sm-offset-2">

                                        <div className={`row form-group ${errors.name ? 'has-error' : ''}`}>
                                            <div className="col-sm-4 control-label text-right">ชื่อ *</div>
                                            <div className="col-sm-8">
                                                <input id="name" name="name" type="text" placeholder="ชื่อ *" value={values.name} className="form-control" onChange={handleChange} />
                                                {errors.name && touched.name && <span className="help-block">{errors.name}</span>}
                                            </div>
                                        </div>

                                        <div className={`row form-group ${errors.email ? 'has-error' : ''}`}>
                                            <div className="col-sm-4 control-label text-right">อีเมล *</div>
                                            <div className="col-sm-8">
                                                <input id="email" name="email" type="email" placeholder="Email *" value={values.email} className="form-control mb-3" onChange={handleChange} />
                                                {errors.email && touched.email && <span className="help-block">{errors.email}</span>}
                                            </div>
                                        </div>

                                        <div className={`row form-group ${errors.password ? 'has-error' : ''}`}>
                                            <div className="col-sm-4 control-label text-right">รหัสผ่าน *</div>
                                            <div className="col-sm-8">
                                                <input id="password" name="password" type="password" placeholder="รหัสผ่าน *" value={values.password} className="form-control mb-3" onChange={handleChange} />
                                                {errors.password && touched.password && <span className="help-block">{errors.password}</span>}
                                            </div>
                                        </div>

                                        <div className={`row form-group ${errors.password_confirmation ? 'has-error' : ''}`}>
                                            <div className="col-sm-4 control-label text-right">ยืนยันรหัสผ่าน *</div>
                                            <div className="col-sm-8">
                                                <input id="password_confirmation" name="password_confirmation" type="password" placeholder="ยืนยันรหัสผ่าน *" value={values.password_confirmation} className="form-control mb-3" onChange={handleChange} />
                                                {errors.password_confirmation && touched.password_confirmation && <span className="help-block">{errors.password_confirmation}</span>}
                                            </div>
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-sm-4 control-label text-right">กลุ่มผู้ใช้และสิทธ์</div>
                                            <div className="col-sm-8">
                                                {
                                                    roles && roles.length > 0 ?
                                                        roles.map( role => {
                                                            return (
                                                                <div key={role.id}><input type="radio" name="role_id" checked={values.role_id === role.id ? true : false} onChange={() => setFieldValue('role_id', role.id)}/> {role.name}</div>
                                                            )
                                                        })
                                                    : null
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    
                                </div>

                            </div>
                        </div>

                        
                        <div className="row form-group">
                            <div className="col-sm-8 col-sm-offset-4">
                                <button type="submit" className="btn btn-primary"><i className="fa fa-save"></i> Save</button>
                                {' '}
                                <Link className="btn btn-default" to="/users"><i className="fa fa-ban"></i> Cancel</Link>
                            </div>
                        </div>

                    </form>
                )
            }}
        </Formik>
    )
}

export default FormUser