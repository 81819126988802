import React from "react"
import {Link} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import swal from 'sweetalert'
import * as actions from './../../redux/actions/productsAction'
import * as titleActions from './../../redux/actions/titleAction'
import Pagination from './../../components/common/Pagination'
import FormSearch from './../../components/common/FormSearch'

class ProductBalance extends React.Component {

	state = {
        page: 1,
        toggleItem: {
            code: { label: 'เลขทะเบียน', active: true },
            productType: { label: 'ประเภทสินค้า', active: true },
            prefix: { label: 'ตัวย่อสินค้า', active: true },
            name: { label: 'ชื่อสินค้า', active: true },
            balance: { label: 'จำนวนคงเหลือ', active: true },
        },
	}

	componentDidMount() {
        this.props.actions.SetTitle({
            title: 'สินค้า',
            subtitle: '',
            breadcrumbs: [
                {active: true, label: 'สินค้า'}
            ]
        })
		this.props.actions.FetchProducts()
	}

	
	handlePageClick = (obj) => {
		// let {caregivers_count} = this.state
		// let start = (caregivers_count - (caregivers_count - ((obj.selected + 1) * 10))) - 10
		this.props.actions.FetchProducts({'page': obj.selected + 1})
    }
    
    allToggle = (e) => {
        let {toggleItem} = this.state
        Object.keys(this.toggleItem).forEach((t) => {
            this.toggleItem[t].active = e.target.checked
        })
        this.setState({ toggleItem })
    }

    changeToggle = (k) => {
        let {toggleItem} = this.state
        toggleItem[k].active = !toggleItem[k].active
        this.setState({ toggleItem })
    }

    deleteData = (item) => {
        swal({
            title: "Are you sure?",
            text: "Confirm delete!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                this.props.actions.DeleteProduct(item)
                swal("Poof! Your imaginary file has been deleted!", {
                    icon: "success",
                });
                this.props.actions.FetchProducts()
            } else {
                swal("Your imaginary file is safe!");
            }
        });
    }

	render() {
        let {toggleItem} = this.state
        let {products, products_count} = this.props
		return (
			<div className="row">
                <div className="col-sm-12">
                    <div className="box box-default">
                        <div className="box-header with-border">
                            <h3 className="box-title">รายการสินค้าคงเหลือ</h3>
                        </div>

                        <div className="box-body">
                            <div className="row form-group">
                                <div className="col-sm-6">
                                    <FormSearch onSearch={(keyword) => this.props.actions.FetchProducts({search: keyword})}/>
                                </div>
                                <div className="col-sm-6 text-right">
                                    
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12" style={{position: 'relative'}}>
                                    <div style={{position: 'absolute', top: '0px', right: '0px'}}>
                                        <div className="btn-group" role="group" style={{position: 'relative'}}>
                                            <button type="button" className="btn btn-default" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></button>
                                            <ul className="dropdown-menu pull-right">
                                                <li style={{padding: '5px 10px'}}>
                                                    <input type="checkbox" name="all" value="on" onChange={this.allToggle}/> All
                                                </li>
                                                {
                                                    Object.keys(toggleItem).map(item => (
                                                        <li style={{padding: '5px 10px'}} key={item}>
                                                            <input type="checkbox" name="item" checked={toggleItem[item].active} value="on" onChange={(e) => this.changeToggle(item)}/>
                                                            {toggleItem[item].label}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="table-responsive">
                                        <table id="tbProduct" className="table table-bordered table-hover" cellSpacing="0" width="100%">
                                            <thead>
                                                <tr>
                                                    {toggleItem.code.active && <th>เลขทะเบียน</th> }
                                                    {toggleItem.productType.active && <th>ประเภทสินค้า</th>}
                                                    {toggleItem.prefix.active && <th>ตัวย่อสินค้า</th>}
                                                    {toggleItem.name.active && <th>ชื่อสินค้า</th>}
                                                    {toggleItem.balance.active && <th>จำนวนคงเหลือ</th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    products && products.length > 0 ? 
                                                        products.map((item, index) => {
                                                            return (
                                                                <tr key={item.id} >
                                                                    {toggleItem.code.active && <td style={{whiteSpace: 'nowrap'}} className="text-left">{item.product_full_code}</td>}
                                                                    {toggleItem.productType.active && <td  className="text-left">{item.product_type ? item.product_type.name : ''}</td>}
                                                                    {toggleItem.prefix.active && <td  className="text-center">{item.prefix}</td>}
                                                                    {toggleItem.name.active && <td  className="text-left">{item.name}</td>}
                                                                    {toggleItem.balance.active && <td  className="text-right">{item.balance}</td>}
                                                                </tr>
                                                            )
                                                        })
                                                    : 
                                                        <tr>
                                                            <td className="danger" colSpan="18"> Data not found</td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="row" v-if="meta">
                                {/* <div className="col-sm-6" style="padding: 6px 20px;">Showing {{meta.from}} to {{meta.to}} of {{meta.total}} entries</div> */}
                                <div className="col-sm-6"></div>
                                <div className="col-sm-6 text-right">    
                                    <Pagination
                                        itemCount={products_count}
                                        rowCount={10}
                                        onPageChange={this.handlePageClick}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>

                       
			    </div>
			</div>
		)
	}
}
    


const mapStateToProps = (state, ownProps) => {
	return {
		products: state.productsStore.products,
		products_count: state.productsStore.products_count,
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({...actions, ...titleActions}, dispatch)
	}
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ProductBalance)
