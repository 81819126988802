import types from './../actionTypes'
import config from './../../config'
import {getCookie} from './../../utils/cookie'
import axios from 'axios'

export function FetchRoles(query) {
    return async (dispatch) => {
        // try {
            dispatch({
                type: types.ROLE_PENDING,
                payload: null
            })

            axios.get(config.BASE_API + '/api/roles', {
                params: {
                    ...query
                },
                headers: {
                    Authorization: `Bearer ${getCookie("token")}`
                }
            })
            .then(response => {
                dispatch({
                    type: types.FETCH_ROLES_SUCCESS,
                    payload: response.data
                });
            })
            .catch(error => {
                // Handle error.
                console.log('An error occurred:', error);
            });
       
    }
}


export const FetchRoleByID = (id) => {
    return async (dispatch) => {
        dispatch({
            type: types.ROLE_PENDING,
            payload: null
        })
        axios.get(config.BASE_API + '/api/roles/' + id, {
            headers: {
                Authorization: `Bearer ${getCookie("token")}`
            }
        })
        .then(response => {
            dispatch({
                type: types.FETCH_ROLE_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
          // Handle error.
          console.log('An error occurred:', error);
        });
    }
}


export const NewRole = () => {
    return async (dispatch) => {
        dispatch({
            type: types.NEW_USER,
            payload: {}
        });
    }
}


export const StoreRole = (data) => {
    return async (dispatch) => {

        dispatch({
            type: types.ROLE_PENDING,
            payload: null
        });
    
        axios.post(config.BASE_API + '/api/roles', data, {
            headers: {
                Authorization: `Bearer ${getCookie("token")}`
            }
        })
        .then(response => {
            dispatch({
                type: types.STORE_ROLE_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
          // Handle error.
          console.log('An error occurred:', error);
        });
    }
}

export const UpdateRole = (data) => {
    return async (dispatch) => {

        dispatch({
            type: types.ROLE_PENDING,
            payload: null
        });
    
        axios.put(config.BASE_API + '/api/roles/' + data.id, data, {
            headers: {
                Authorization: `Bearer ${getCookie("token")}`
            }
        })
        .then(response => {
            dispatch({
                type: types.STORE_ROLE_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
          // Handle error.
          console.log('An error occurred:', error);
        });
    }
}

export const DeleteRole = () => {

}