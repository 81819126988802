import React from 'react'
import SimpleReactValidator from 'simple-react-validator';

const WithForm = (FormComponent) => {

    return class extends React.Component {

        constructor(props) {
            super(props)
            this.state = {
                fields: {...props.inputNames},
                errors: {...props.errors},
                selectedFile: null,
                files: {},
                filesPreview: {}
            }
            this.handleChange = this.handleChange.bind(this);
            this.handleFile = this.handleFile.bind(this);
            this.handleSubmit = this.handleSubmit.bind(this);

            this.validator = new SimpleReactValidator();
        }
        // https://codeburst.io/save-the-zombies-how-to-add-state-and-lifecycle-methods-to-stateless-react-components-1a996513866d 
        static get name() {
            return FormComponent.name;
        }

        handleChange(e) {
            const target = e.target;
            let value = target.type === 'checkbox' ? target.checked : target.value;
            let name = target.name;
            const fields = {...this.state.fields},  errors = {...this.state.errors};
            const error = //create a validation function that returns an error based on field name and value
            fields[name] = value;
            errors[name] = error;
            this.setState({ fields, errors })
        }

        handleFile(e) {
            const {name, files} = e.target;
            const filesSelect = {...this.state.files},  filesPreview = {...this.state.filesPreview};
            filesSelect[name] = files[0]
            this.setState({
                files: filesSelect
            });
            let reader = new FileReader()

            reader.onloadend = () => {
                filesPreview[name] = reader.result
                this.setState({
                    filesPreview
                });
            }
            reader.readAsDataURL(files[0])
        }

        handleFileMultiple = (e) => {
            const files = {...this.state.files}
            files[e.target.name] = e.target.files
            this.setState({
                files
            });
        }

        handleFileImage = (e) => {
            const fields = {...this.state.fields}
            const files = e.target.files
            const fileSelected = {...this.state.files}
            fileSelected[e.target.name] = files
            this.setState({
                files: fileSelected
            });
            if(!fields.view_images) fields.view_images = []
            Object.keys(files).forEach((f) => {
                let reader = new FileReader()

                reader.onloadend = () => {
                    fields.view_images.push(reader.result)
                    this.setState({
                        fields
                    });
                }
                reader.readAsDataURL(files[f])
            })
        }

        setValue = (arr) => {
            const fields = {...this.state.fields},  errors = {...this.state.errors};
            arr.forEach( a => {
                fields[a.key] = a.value
            })
            this.setState({ fields, errors })
        }

        addMultiple = (k, item, k2) => {
            const fields = {...this.state.fields};
            if(!fields[k]) fields[k] = [];
            if(k2) {
                fields[k].push({[k2]: item })
            } else {
                fields[k].push(item)
            }
            this.setState({ fields })
        }

        delMultiple = (k, index) => {
            const fields = {...this.state.fields};
            fields[k].splice(index, 1)
            this.setState({ fields })
        }

        handleSubmit(e) {
            e.preventDefault()
            if (this.validator.allValid()) {
                if(this.props.onSubmit) {
                    this.props.onSubmit(this.state.fields, this.state.files)
                }
            } else {
                this.validator.showMessages();
                // rerender to show messages for the first time
                // you can use the autoForceUpdate option to do this automatically`
                this.forceUpdate();
            }
        }

        componentWillReceiveProps(nextProps) {
            if(nextProps.initialValues) {
                this.setState({
                    fields: nextProps.initialValues
                })
            }
        }

        render() {
            return <FormComponent 
                    {...this.props} //to access form specific props not handled by state
                    fields={this.state.fields}
                    errors={this.state.errors}
                    validator={this.validator}
                    files={this.state.files}
                    filesPreview={this.state.filesPreview}
                    handleChange={this.handleChange}
                    handleFile={this.handleFile}
                    handleFileMultiple={this.handleFileMultiple}
                    handleFileImage={this.handleFileImage}
                    handleSubmit={this.handleSubmit}
                    setValue={this.setValue}
                    addMultiple={this.addMultiple}
                    delMultiple={this.delMultiple}
                />
        }
    }

}

export default WithForm