export default function() {
	return [
		{
			title: "ภาพรวม",
			to: "/dashboard",
			htmlBefore: '<i class="material-icons">dashboard</i>',
			htmlAfter: ""
		},
		{
			title: "ผู้เลี้ยง",
			to: "/caregiver",
			htmlBefore: '<i class="material-icons">supervisor_account</i>',
			htmlAfter: ""
		},
		{
			title: "โรงเรือน",
			to: "/house",
			htmlBefore: '<i class="material-icons">home</i>',
			htmlAfter: ""
		},
		{
			title: "ไข่",
			to: "/eggs",
			htmlBefore: '<i class="material-icons">lens</i>',
			htmlAfter: ""
		},
		{
			title: "ปฎิทินเลี้ยง",
			to: "/calendar-feet",
			htmlBefore: '<i class="material-icons">calendar_today</i>',
			htmlAfter: ""
		},
		{
			title: "เตรียมส่ง",
			to: "/transport",
			htmlBefore: '<i class="material-icons">local_shipping</i>',
			htmlAfter: ""
		},
		{
			title: "รับรัง",
			to: "/nest-receive",
			htmlBefore: '<i class="material-icons">local_shipping</i>',
			htmlAfter: ""
		},
		{
			title: "ตั้งค่าระบบ",
			to: "/setting",
			htmlBefore: '<i class="material-icons">settings</i>',
			htmlAfter: "",
		},
		
		// {
		// 	title: "Blog Dashboard",
		// 	to: "/blog-overview",
		// 	htmlBefore: '<i class="material-icons">edit</i>',
		// 	htmlAfter: ""
		// },
		// {
		// 	title: "Blog Posts",
		// 	htmlBefore: '<i class="material-icons">vertical_split</i>',
		// 	to: "/blog-posts",
		// },
		// {
		// 	title: "Add New Post",
		// 	htmlBefore: '<i class="material-icons">note_add</i>',
		// 	to: "/add-new-post",
		// },
		// {
		// 	title: "Forms & Components",
		// 	htmlBefore: '<i class="material-icons">view_module</i>',
		// 	to: "/components-overview",
		// },
		// {
		// 	title: "Tables",
		// 	htmlBefore: '<i class="material-icons">table_chart</i>',
		// 	to: "/tables",
		// },
		// {
		// 	title: "User Profile",
		// 	htmlBefore: '<i class="material-icons">person</i>',
		// 	to: "/user-profile-lite",
		// },
		// {
		// 	title: "Errors",
		// 	htmlBefore: '<i class="material-icons">error</i>',
		// 	to: "/errors",
		// }
	];
}
