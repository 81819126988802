import React from "react";
import { Router, Route } from "react-router-dom";

import routes from "./routes";
// import {CheckAuth} from './flux/actions/authenticateAction'
// import {getCookie} from './utils/cookie'
// import withTracker from "./withTracker";
import { createBrowserHistory } from 'history';
// import "bootstrap/dist/css/bootstrap.min.css";
// import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";

const history = createBrowserHistory()
// const PrivateRoute = ({ component: Component, ...rest }) => (
// 	<Route {...rest} render={(props) => (
// 	  fakeAuth.isAuthenticated === true
// 		? <Component {...props} />
// 		: <Redirect to='/login' />
// 	)} />
// )

// const PrivateRoute  = ({component: Component, authed, ...rest}) => {
// 	return (
// 		<Route {...rest} render={(props) => authed === true
// 			? <Component {...props} />
// 			: <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
// 		/>
// 	)
// }


// const PrivateRoute = ({children, ...rest}) => {
// 	// return class extends React.Component {
// 	// 	componentWillMount() {
// 		console.log("check auth")
// 	if(!checkToken()) {
// 		// browserHistory.push('/login')
// 		rest.history.push('/login')
// 	}
//     //     render() {
//     //         return <Component {...rest} />
//     //     }
// 	// }
// 	return children
// }
  

export default () => (
	<Router basename={process.env.REACT_APP_BASENAME || ""} history={history}>
		<div>
		{routes.map((route, index) => {
			if(route.children) {
				return (
					<Route
						key={index}
						path={route.path}
						exact={route.exact}
						children={(props) => {
							return (
								<route.component {...props}>
										{
											route.children.map((child, ind) => {
												return (
													<Route
														key={`${index}${ind}`}
														path={child.path}
														exact={child.exact}
														component={props => {
															// console.log(props)
															return (
																<child.layout {...props}>
																	<child.component {...props} />
																</child.layout>
															)
														}}
													/>
												)
											})
										}

								</route.component>
							)
						} }
						
					/>
				);
			} else {

				return (
					<Route
						key={index}
						path={route.path}
						exact={route.exact}
						component={props => {
							// console.log(props)
							return (
								<route.layout {...props}>
									<route.component {...props} />
								</route.layout>
							)
						}}
					/>
				);
			}
		})}
		</div>
	</Router>
);
