import React from "react";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import DatePicker from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import dayjs from 'dayjs'
import swal from 'sweetalert'
import actions from './../redux/actions'
import { Formik, Form, Field, FieldArray } from 'formik'

class AccountingForm extends React.Component {

	componentDidMount() {
        let {match} = this.props
        let title = 'เพิ่มรายการสมุดรายวัน'
        this.props.actions.FetchSettingAccountingNumbers()
        if(match.params.id) {
            title = 'แก้ไขรายการสมุดรายวัน'
            this.props.actions.FetchAccountingByID(match.params.id)
        }
        this.props.actions.SetTitle({
            title: 'บัญชี',
            subtitle: '',
            breadcrumbs: [
                {path: '/accountings', label: 'สมุดรายวันทั่วไป'},
                {active: true, label: title}
            ]
        })
    }
    
    componentWillReceiveProps = (nextProps) => {
        if(nextProps.is_success) {
            swal("บัยทึกข้อมูลเสร็จสิ้น!", {
                icon: "success",
            });
            this.props.history.push('/accountings')
        }
    }

    onSubmit = (values) => {
        this.props.actions.StoreAccountingMultiple(values)
    }

	render() {
        let {accounting, setting_accountingnumber, is_pending} = this.props
		return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="box box-primary">
                        <div className="box-header with-border">
                            <h3 className="box-title">เพิ่มรายการสมุดรายวันทั่วไป</h3>
                        </div>

                        <div className="box-body">
                            <div className="row">
                                <div className="col-sm-12">
                                <Formik
                                    enableReinitialize
                                    initialValues={accounting}
                                    onSubmit={this.onSubmit}
                                >
                                    {({ values, handleChange, setFieldValue }) => (
                                        <Form> 
                                            <FieldArray
                                                name="items"
                                                render={arrayHelpers => (
                                                    <div className="table-responsive">
                                                        <table id="tbProduct" className="table table-bordered" cellSpacing="0" width="100%">
                                                            <thead>
                                                                <tr>
                                                                    <th width="10%" colSpan="2" className="text-center"></th>
                                                                    <th width="30%" rowSpan="2" colSpan="2" className="text-center">รายการ</th>
                                                                    <th width="5%" rowSpan="2" className="text-center">เลขที่บัญชี</th>
                                                                    <th width="10%" colSpan="2" className="text-center">เดบิต</th>
                                                                    <th width="10%" colSpan="2" className="text-center">เครดิต</th>
                                                                </tr>
                                                                <tr>
                                                                    <th className="text-center" colSpan="2">วันที่</th>
                                                                    <th width="7%" className="text-center">บาท.</th>
                                                                    <th width="3%" className="text-center">สต.</th>
                                                                    <th width="7%" className="text-center">บาท.</th>
                                                                    <th width="3%" className="text-center">สต.</th>
                                                                    <th width="3%" className="text-center"></th>
                                                                </tr>
                                                            </thead>
                                                            {
                                                                values.items && values.items.length > 0 ?
                                                                    values.items.map((item, index) => {
                                                                        return (
                                                                            <tbody key={index}>
                                                                                <tr>
                                                                                    <td colSpan="2">
                                                                                        <DatePicker value={item.journal_date ? new Date(item.journal_date) : new Date()} onChange={(v) => handleChange({target: {name: `items.${index}.journal_date`, value: v.format("YYYY-MM-DD")}})} timeFormat={false}/>
                                                                                    </td>
                                                                                    <td width="15%" style={{borderRight: 0}}>
                                                                                        <Field name={`items.${index}.debit_name`} className="form-control" />
                                                                                    </td>
                                                                                    <td width="15%" style={{borderLeft: 0}}></td>
                                                                                    <td className="text-center">
                                                                                        <select name={`items.${index}.debit_no`} value={item.debit_no} onChange={handleChange} className="form-control">
                                                                                            <option value="">-</option>
                                                                                            {
                                                                                                setting_accountingnumber && setting_accountingnumber.length > 0 ?
                                                                                                    setting_accountingnumber.map(it => <option key={it.id} value={it.accounting_no}>{it.accounting_no} {it.accounting_name}</option> )
                                                                                                : null
                                                                                            }
                                                                                        </select>
                                                                                    </td>
                                                                                    <td className="text-right" colSpan="2">
                                                                                        <input type="number" name={`items.${index}.debit_price`} value={item.debit_price} className="form-control" onChange={(e) => {setFieldValue(`items.${index}.debit_price`, e.target.value);setFieldValue(`items.${index}.credit_price`, e.target.value)}}/>
                                                                                    </td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td rowSpan="4">
                                                                                        <button
                                                                                            className="btn btn-danger"
                                                                                            type="button"
                                                                                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                                                        >
                                                                                            <i className="fa fa-trash"></i>
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td width="15%" style={{borderRight: 0}}></td>
                                                                                    <td width="15%" style={{borderLeft: 0}}>
                                                                                        <Field name={`items.${index}.credit_name`} className="form-control" />
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                        <select name={`items.${index}.credit_no`} value={item.credit_no} onChange={handleChange} className="form-control">
                                                                                            <option value="">-</option>
                                                                                            {
                                                                                                setting_accountingnumber && setting_accountingnumber.length > 0 ?
                                                                                                    setting_accountingnumber.map(it => <option key={it.id} value={it.accounting_no}>{it.accounting_no} {it.accounting_name}</option> )
                                                                                                : null
                                                                                            }
                                                                                        </select>
                                                                                    </td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td className="text-right" colSpan="2">
                                                                                        <input value={item.debit_price} className="form-control" type="text" disabled/>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td colSpan="2"><Field name={`items.${index}.description`} className="form-control" /></td>
                                                                                    <td className="text-center"></td>
                                                                                    <td className="text-right"></td>
                                                                                    <td>-</td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                </tr>
                                                                                {
                                                                                    values.items.length - 1 === index ? 
                                                                                        <tr>
                                                                                            <td colSpan="10">
                                                                                                <button className="btn btn-primary" type="button" onClick={() => arrayHelpers.push({journal_date: dayjs().format("YYYY-MM-DD HH:mm:ss") })}>
                                                                                                    <i className="fa fa-plus"></i> Add 
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    : null
                                                                                }
                                                                            </tbody>
                                                                        )
                                                                    }
                                                                    )
                                                                : (
                                                                    <tr>
                                                                        <td colSpan="10">
                                                                            <button className="btn btn-primary" type="button" onClick={() => arrayHelpers.push({journal_date: dayjs().format("YYYY-MM-DD HH:mm:ss") })}>
                                                                                <i className="fa fa-plus"></i> Add 
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </table>
                                                    </div>
                                                )}
                                            />
                                            <div className="row">
                                                <div className="col-sm-12 text-center">
                                                    <button className="btn btn-primary" type="submit"><i className="fa fa-save"></i> บันทึก</button>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                    </Formik>
                                    
                                    
                                </div>
                            </div>

                            <div className="row" v-if="meta">
                                {/* <div className="col-sm-6" style="padding: 6px 20px;">Showing {{meta.from}} to {{meta.to}} of {{meta.total}} entries</div> */}
                                <div className="col-sm-6"></div>
                                <div className="col-sm-6 text-right">    
                                    {/* <Pagination
                                        itemCount={products_count}
                                        rowCount={10}
                                        onPageChange={this.handlePageClick}
                                    /> */}
                                </div>
                            </div>
                        </div>

                        {
                            is_pending &&
                            <div className="overlay">
                                <i className="fa fa-refresh fa-spin"></i>
                            </div>
                        }

                    </div>

                </div>
            </div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		setting_accountingnumber: state.settingStore.accountingnumbers,
		accounting: state.accountingStore.accounting,
        is_success: state.accountingStore.is_success,
        is_pending: state.accountingStore.is_pending,
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	}
}
  
export default connect(mapStateToProps, mapDispatchToProps)(AccountingForm)
