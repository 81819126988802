import types from './../actionTypes'
import config from './../../config'
import {getCookie} from './../../utils/cookie'
import axios from 'axios'

export function FetchUsers(query) {
    return async (dispatch) => {
        // try {
            dispatch({
                type: types.USER_PENDING,
                payload: null
            })

            axios.get(config.BASE_API + '/api/users', {
                params: {
                    ...query
                },
                headers: {
                    Authorization: `Bearer ${getCookie("token")}`
                }
            })
            .then(response => {
                dispatch({
                    type: types.FETCH_USERS_SUCCESS,
                    payload: response.data
                });
            })
            .catch(error => {
                // Handle error.
                console.log('An error occurred:', error);
            });
       
    }
}


export const FetchUserByID = (id) => {
    return async (dispatch) => {
        dispatch({
            type: types.USER_PENDING,
            payload: null
        })
        axios.get(config.BASE_API + '/api/users/' + id, {
            headers: {
                Authorization: `Bearer ${getCookie("token")}`
            }
        })
        .then(response => {
            dispatch({
                type: types.FETCH_USER_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
          // Handle error.
          console.log('An error occurred:', error);
        });
    }
}


export const NewUser = () => {
    return async (dispatch) => {
        dispatch({
            type: types.NEW_USER,
            payload: {}
        });
    }
}


export const StoreUser = (data) => {
    return async (dispatch) => {

        dispatch({
            type: types.USER_PENDING,
            payload: null
        });
    
        axios.post(config.BASE_API + '/api/users', data, {
            headers: {
                Authorization: `Bearer ${getCookie("token")}`
            }
        })
        .then(response => {
            dispatch({
                type: types.STORE_USER_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
          // Handle error.
          console.log('An error occurred:', error);
        });
    }
}

export const UpdateUser = (data) => {
    return async (dispatch) => {

        dispatch({
            type: types.USER_PENDING,
            payload: null
        });
    
        axios.put(config.BASE_API + '/api/users/' + data.id, data, {
            headers: {
                Authorization: `Bearer ${getCookie("token")}`
            }
        })
        .then(response => {
            dispatch({
                type: types.STORE_USER_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
          // Handle error.
          console.log('An error occurred:', error);
        });
    }
}

export const DeleteUser = () => {

}