import React from 'react'
import {Link} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import actions from './../../redux/actions'
import TableList from './../../components/common/TableList'
import FormSearch from './../../components/common/FormSearch'

class RoleList extends React.Component {

    componentDidMount(){
        this.props.actions.FetchRoles()
        this.props.actions.SetTitle({
            title: 'ผู้ใช้',
            subtitle: '',
            breadcrumbs: [
                {active: true, label: 'กลุ่มผู้ใช้'}
            ]
        })
    }

    render() {
        let {roles} = this.props
        return (
            <div className="box box-default">
                <div className="box-header with-border">
                    <h3 className="box-title">กลุ่มผู้ใช้</h3>
                </div>

                <div className="box-body">

                    <div className="row">
                        <div className="col-sm-6">
                            <FormSearch onSearch={(keyword) => this.props.actions.FetchRoles({search: keyword})}/>
                        </div>
                        <div className="col-sm-6 text-right">
                            <Link
                                to="/users/roles/create"
                                className="btn btn-primary"
                            >
                                <i className="fa fa-plus"></i> เพิ่มกลุ่มผู้ใช้และสิทธ์
                            </Link>
                        </div>
                    </div>
                    <TableList 
                        headers={['Action', 'ชื่อ']} 
                        items={roles}
                        listComponent={(item, index) => (
                            <tr key={`${item.id+index}`}>
                                <td>
                                    <Link className="btn btn-warning btn-sm" to={`/users/roles/${item.id}/edit`}><i className="fa fa-pencil"></i> Edit</Link>
                                </td>
                                <td>{item.name}</td>
                            </tr>
                        )}
                    />
                </div>
            </div>
        )
    }
} 


const mapStateToProps = (state, ownProps) => {
    return {
        roles: state.rolesStore.roles
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(RoleList)
