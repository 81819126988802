import types from './../actionTypes'
import config from './../../config'
import {getCookie} from './../../utils/cookie'
import axios from 'axios'

export const GetCurrentUser = () => {
	return async (dispatch) => {
		dispatch({
			type: types.AUTH_PENDING,
			payload: null
		})

		axios.get(config.BASE_API + '/api/me',{
			headers: {
				'Authorization': `Bearer ${getCookie("token")}`
			}
		})
		.then(res => {
			dispatch({
				type: types.GET_CURRENT_USER_SUCCESS,
				payload: res.data.user
			})
		})
		.catch(err => {
		
		})
	}
}


export const AuthLogin = (data) => {
	return async (dispatch) => {
		dispatch({
			type: types.AUTH_PENDING,
			payload: null
		})

		axios.post(config.BASE_API + '/api/login', data, {
			headers: {
				'Authorization': `Bearer ${getCookie("token")}`
			}
		})
		.then(res => {
			let {success, message} = res.data
			dispatch({
				type: types.AUTH_LOGIN_SUCCESS,
				payload: {
					is_success: true,
					token: success.token,
					message: message,
				} 
			})
		})
		.catch(err => {
			if(err.response && err.response.status === 401) {
				let {error, message} = err.response.data
				dispatch({
					type: types.AUTH_LOGIN_FAIL,
					payload: {
						is_error: error,
						message: message,
					}
				})
			}
		})
	}
}

