import React from 'react'
import ReactPaginate from 'react-paginate'
import './Pagination.css'

const Pagination = ({itemCount, rowCount, onPageChange}) => {
    return (
        <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={itemCount / rowCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={onPageChange}
            containerClassName={'pagination'}
            subContainerClassName={'page-item'}
            activeClassName={'active'}
        />
    )
}

export default Pagination