import dayjs from 'dayjs'
import initialState from './initialState'
import types from './../actionTypes'

export default (state = initialState.accountingStore, action = {}) => {

    switch(action.type) {

        case types.ACCOUNTING_PENDING: {
            return {
                ...state,
                is_pending: true,
                is_success: false,
                is_fail: false
            }
        }

        case types.FETCH_ACCOUNTINGS_SUCCESS: {
            return {
                ...state,
                is_pending: false,
                is_success: false,
                is_fail: false,
                accountings: action.payload.accountings,
                accountings_count: action.payload.accountings_count,
            }
        }

        case types.FETCH_ACCOUNTING_SUCCESS: {
            state.accounting.items[0] = action.payload.accounting
            return {
                ...state,
                is_pending: false,
                is_success: false,
                is_fail: false,
                accounting: state.accounting
            }
        }

        case types.STORE_ACCOUNTING_SUCCESS: {
            return {
                ...state,
                is_pending: false,
                is_success: true,
                is_fail: false,
                accounting: {
                    items: [{journal_date: dayjs().format("YYYY-MM-DD HH:mm:ss")}]
                }
            }
        }

        case types.UPDATE_ACCOUNTING_SUCCESS: {
            state.accounting.items.push(action.payload.accounting)
            return {
                ...state,
                is_pending: false,
                is_success: true,
                is_fail: false,
                accounting: {
                    items: [{journal_date: dayjs().format("YYYY-MM-DD HH:mm:ss")}]
                }
            }
        }

        default: 
            return state
    }
}