import React from "react";
import PropTypes from "prop-types";
import { Row } from "shards-react";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from './../redux/actions/titleAction'
// import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
// import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import Header from "../components/layout/Header";
import Sidebar from "../components/layout/Sidebar";
// import Footer from "../components/layout/Footer";
import AuthStore from './../flux/stores/AuthenticateStore'
import PageTitle from "./../components/common/PageTitle";

class DefaultLayout extends React.Component {

	state = {
		user: AuthStore.getUserState()
	}

	componentDidMount = () => {
		document.body.classList.remove('login-page');
  		document.body.classList.add('skin-blue');
  		document.body.classList.add('sidebar-mini');
  		document.body.classList.add('fixed');
	}

	componentWillMount = () => {
		AuthStore.addChangeListener('AUTHENTICATE_SET_USER', this.onChangeUser)
	}
	
	componentWillUnmount = () => {
		AuthStore.removeChangeListener('AUTHENTICATE_SET_USER', this.onChangeUser)
	}

	onChangeUser = () => {
		this.setState({
			user: AuthStore.getUserState()
		})
	}

	render() {
		let {children, title, subtitle, breadcrumbs, match, user, permissions} = this.props
		return (
		  <div className="wrapper">
			<Header user={user}/>
			<Sidebar user={user} match={match} permissions={permissions}/>
		
			<div className="content-wrapper">
		
					{/* <section className="content-header">
						<h1>
							@yield('title')
						</h1>
						<ol className="breadcrumb">
							<li><a href="#"><i className="fa fa-dashboard"></i> Level</a></li>
							<li className="active">Here</li>
						</ol> 
					</section> */}
					<Row className="page-header" style={{margin: '10px 0px 0px 0px'}}>
						<PageTitle title={title} subtitle={subtitle} className="text-sm-left" breadcrumbs={breadcrumbs}/>
					</Row>
		
					<section className="content container-fluid">
		
						{children}
		
					</section>
		
			  </div>
			{/* <Footer /> */}
			{/* <div className="control-sidebar-bg"></div> */}
		  </div>
		)
	}
}

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false
};



const mapStateToProps = (state, ownProps) => {
    return {
        title: state.titleStore.title,
        subtitle: state.titleStore.subtitle,
		breadcrumbs: state.titleStore.breadcrumbs,
		user: state.authStore.user,
		permissions: state.authStore.permissions
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({...actions}, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
