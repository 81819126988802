import initialState from './initialState'
import types from './../actionTypes'

export default (state = initialState.titleStore, action = {}) => {

    switch(action.type) {

        case types.SET_TITLE: {
            return {
                ...state,
                title: action.payload.title,
                subtitle: action.payload.subtitle,
                breadcrumbs: action.payload.breadcrumbs,
            }
        }

        default: 
            return state
    }
}