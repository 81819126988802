import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import numeral from 'numeral'
import swal from 'sweetalert'
import Modal from 'react-modal'
import actions from './../../redux/actions'
import Pagination from './../../components/common/Pagination'
import FormSearch from './../../components/common/FormSearch'

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        width: '40%',
        marginRight           : '-50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '9999'
    }
}

const useFetching = (someFetchActionCreator, params) => {
    useEffect( () => {
      someFetchActionCreator(params);
    }, [])
}

const calTotal = (item) => {
    let total = item.items ? item.items.reduce((acc, cur) => acc + (cur.price * cur.quantity ? ((cur.price * cur.quantity) - (cur.discount || 0)) + (cur.vat_method === 'out' ? ((cur.price * cur.quantity) - (cur.discount || 0)) * 0.07 : 0) : 0) , 0) : 0
    return total.toFixed(2)
}

const Installments = ({actions, is_pending, sales, sales_count}) => {

    const [modal, setModal] = useState(false)
    const [dataView, setDataView] = useState({})
    const [page, setPage] = useState(1)

    useFetching(actions.SetTitle, {
        title: 'รายการผ่อนจ่าย',
        subtitle: '',
        breadcrumbs: [
            {active: true, label: 'รายการผ่อนจ่าย'}
        ]
    })

    useFetching(actions.FetchSaleInstallments, {})

    const handlePageClick = (obj) => {
        setPage(obj.selected + 1)
        actions.FetchSaleInstallments({'page': obj.selected + 1 })
    }

    const submitInstallment = async (data) => {
        let res = await actions.StoreSaleInstallment({
            sale_id: data.id,
            period: data.installments ? data.installments.length + 1 : 1,
            amount: data.credit_period ? (calTotal(data) / data.credit_period).toFixed(2) : 0
        })
        setModal(false)
        if(res.data) {
            swal("บันทึกข้อมูลเสร็จสิ้น!", {
                icon: "success",
            });
            actions.FetchSaleInstallments({})
        }
    }

    function openPaymentModal(data) {
        setDataView(data)
        setModal(true)
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    const closeModal = () => {
        setModal(false);
    }

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="box box-default">
                    <div className="box-header with-border">
                        <h3 className="box-title">รายการผ่อนจ่าย</h3>
                    </div>

                    <div className="box-body">
                        <div className="row form-group">
                            <div className="col-sm-6">
                                <FormSearch onSearch={(keyword) => actions.FetchSaleInstallments({search: keyword, page})}/>
                            </div>
                            <div className="col-sm-6 text-right">
                                
                            </div>
                        </div>
                        <div className="table-responsive" style={{minHeight: 500}}>
                            <table id="tbProduct" className="table table-bordered table-hover" cellSpacing="0" width="100%">
                                <thead>
                                    <tr>
                                        <th>รหัสรายการ</th>
                                        <th>ชื่อลูกค้า</th>
                                        <th className="text-center">แบ่งชำระ(งวด)</th>
                                        <th className="text-center">งวดคงเหลือ</th>
                                        <th className="text-right">ยอดเงินคงเหลือ</th>
                                        <th className="text-right">ยอดต่องวด</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sales && sales.length > 0 ? 
                                            sales.map((item) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item.sale_full_code}</td>
                                                        <td>{item.customer_name}</td>
                                                        <td className="text-center">{item.credit_period}</td>
                                                        <td className="text-center">{item.credit_period ? item.credit_period - item.installments.length : ''}</td>
                                                        <td className="text-right">{item.credit_period && item.installments.length > 0 ? numeral(calTotal(item) - ((calTotal(item) / item.credit_period) * item.installments.length)).format('0,0.00') : numeral(calTotal(item)).format("0,0.00")}</td>
                                                        <td className="text-right">{item.credit_period ? numeral((calTotal(item) / item.credit_period)).format('0,0.00') : ''}</td>
                                                        <td className="text-right">
                                                            <button type="button" className="btn btn-default" onClick={() => openPaymentModal(item)} data-toggle="modal" data-target="#modal-installments">แจ้งชำระเงิน</button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        : 
                                            <tr>
                                                <td className="danger" colSpan="6"> Data not found</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="row">
                            {/* <div className="col-sm-6" style="padding: 6px 20px;">Showing {{meta.from}} to {{meta.to}} of {{meta.total}} entries</div> */}
                            <div className="col-sm-6"></div>
                            <div className="col-sm-6 text-right">    
                                <Pagination
                                    itemCount={sales_count}
                                    rowCount={10}
                                    onPageChange={handlePageClick}
                                />
                            </div>
                        </div>

                    </div>
                    {
                        is_pending &&
                        <div className="overlay">
                            <i className="fa fa-refresh fa-spin"></i>
                        </div>
                    }
                </div>
                <Modal
                    isOpen={modal}
                    closeTimeoutMS={200}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="แจ้งชำระเงิน"
                >
                    <div>
                        <p>รหัสรายการ : {dataView.sale_full_code}</p>
                        <p>ชื่อลูกค้า : {dataView.customer_name}</p>
                        <p>ชำระงวดที่ : {dataView.installments ? dataView.installments.length + 1 : ''}</p>
                        <p>จำนวนเงิน : {dataView.credit_period ? (calTotal(dataView) / dataView.credit_period).toFixed(2) : ''} </p>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 text-right">
                            <button type="button" className="btn btn-default" onClick={() => setModal(false)}><i className="fa fa-ban"></i> ยกเลิก</button>
                            {' '}
                            <button type="button" className="btn btn-primary" onClick={() => submitInstallment(dataView)}><i className="fa fa-save"></i> ยืนยัน</button>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}



const mapStateToProps = (state, ownProps) => {
	return {
		is_pending: state.salesStore.is_pending,
		sales: state.salesStore.sales,
		sales_count: state.salesStore.sales_count,
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	}
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Installments)
