import React from 'react'
import { Formik } from 'formik';
import * as Yup from "yup";
import FieldInput from './../product/common/FieldInput'
import FieldTextarea from './../product/common/FieldTextarea'

const FormSetting = ({onSubmit, initialValues}) => {    
    return (
        <Formik 
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values) => onSubmit(values)}
            validationSchema={
                Yup.object().shape({
                    company_name: Yup.mixed().required().label('ชื่อองค์กร'),
                })
            }
        >
            {({ values, handleSubmit, handleChange, errors, touched }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-8">

                                <FieldInput 
                                    name="company_name" 
                                    label="ชื่อองค์กร *"
                                    type="text"
                                    value={values.company_name}
                                    error={touched.company_name && errors.company_name}
                                    handleChange={handleChange}
                                />
                                <FieldInput 
                                    name="company_tax_number" 
                                    label="เลขประจำตัวผู้เสียภาษี"
                                    type="text"
                                    value={values.company_tax_number}
                                    error={touched.company_tax_number && errors.company_tax_number}
                                    handleChange={handleChange}
                                />
                                <FieldInput 
                                    name="company_tel" 
                                    label="เบอร์โทรศัพท์"
                                    type="text"
                                    value={values.company_tel}
                                    error={touched.company_tel && errors.company_tel}
                                    handleChange={handleChange}
                                />
                                <FieldTextarea 
                                    name="company_address"
                                    label="ที่อยู่"
                                    value={values.company_address}
                                    error={touched.company_address && errors.company_address}
                                    handleChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 text-center"></div>
                            <div className="col-sm-8">
                                <button type="submit" className="btn btn-primary"><i className="fa fa-save"></i> บันทึก</button>
                            </div>
                        </div>
                    </form>
                );
            }} 
        </Formik>
        
    )
}

export default FormSetting