import dayjs from 'dayjs'
import initialState from './initialState'
import types from './../actionTypes'

export default (state = initialState.purchaseStore, action = {}) => {

    switch(action.type) {

        case types.PURCHASE_PENDING: {
            return {
                ...state,
                is_pending: true,
                is_success: false,
                is_fail: false
            }
        }

        case types.PURCHASE_ADD_ITEM: {
            if(!state.purchase.items) state.purchase.items = []
            state.purchase.items.push(action.payload)
            return {
                ...state,
                is_pending: false,
                is_success: false,
                is_fail: false,
                purchase: state.purchase
            }
        }

        case types.PURCHASE_NEW: {
            return {
                ...state,
                is_pending: false,
                is_success: false,
                is_fail: false,
                purchase: {
                    purchase_date: dayjs().format("YYYY-MM-DD HH:mm:ss")
                }
            }
        }

        case types.FETCH_PURCHASES_SUCCESS: {
            return {
                ...state,
                is_pending: false,
                is_success: false,
                is_fail: false,
                purchase: {
                    purchase_date: dayjs().format("YYYY-MM-DD HH:mm:ss")
                },
                purchases: action.payload.data,
                purchases_count: action.payload.total,
            }
        }

        case types.FETCH_PURCHASE_SUCCESS: {
            return {
                ...state,
                is_pending: false,
                is_success: false,
                is_fail: false,
                purchase: action.payload.purchase
            }
        }

        case types.STORE_PURCHASE_SUCCESS: {
            return {
                ...state,
                is_pending: false,
                is_success: true,
                purchase: action.payload.purchase
            }
        }

        default: 
            return state
    }
}