import { Dispatcher, Constants } from "./../index";
import config from './../../config'
import {getCookie, deleteCookie} from './../../utils/cookie'
import axios from 'axios'

export const Authenticate = (data) => {

    Dispatcher.dispatch({
        actionType: Constants.AUTHENTICATE_PENDING
    });

    axios.post(config.BASE_API + '/api/login', data)
    .then(response => {
        Dispatcher.dispatch({
            actionType: Constants.AUTHENTICATE_SUCCESS,
            payload: response.data.success
        });
    })
    .catch(error => {
      // Handle error.
      console.log('An error occurred:', error);
    });

}

export const CheckAuth = () => {
    Dispatcher.dispatch({
        actionType: Constants.AUTHENTICATE_PENDING
    });

    axios.get(config.BASE_API + '/api/me', {
        headers: {
            Authorization: `Bearer ${getCookie("token")}`
        }
    })
    .then(response => {
        Dispatcher.dispatch({
            actionType: Constants.AUTHENTICATE_SET_USER,
            payload: response.data.user
        });
    })
}

export const Logout = () => {
    deleteCookie("token")
    window.location = '/login'
    // Dispatcher.dispatch({
    //     actionType: Constants.AUTHENTICATE_LOGOUT,
    //     payload: response.data
    // });
}
export const OnChangeForm = (e, data) => {
    data[e.target.name] = e.target.value
    Dispatcher.dispatch({
        actionType: Constants.AUTHENTICATE_FORM_CHANGE,
        payload: data
    });
}