import React, {Fragment} from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images';

const Image = (props) => (
    <div
        style={{
            flex: 0,
            ':hover': {
                opacity: 0.9,
            },
        }}
        {...props}
    />
);

class ImageLightbox extends React.Component {
    state = {
        selectedIndex: 0,
        lightboxIsOpen: false,
    }

    toggleLightbox = (selectedIndex) => {
        this.setState(state => ({
            lightboxIsOpen: !state.lightboxIsOpen,
            selectedIndex,
        }));
    }
    render() {
        const { images, isLoading } = this.props;
        const { selectedIndex, lightboxIsOpen } = this.state;
        return (
            <Fragment>
              {!isLoading ? 

                  images.map(({ src }, j) => (
                    <Image onClick={() => this.toggleLightbox(j)} key={src}>
                      <img
                        alt={''}
                        src={src}
                        style={{
                            cursor: 'pointer',
                            width: '70px',
                        }}
                      />
                    </Image>
                  ))

               : null}
      
              <ModalGateway>
                {lightboxIsOpen && !isLoading ? (
                  <Modal onClose={this.toggleLightbox}>
                    <Carousel

                      currentIndex={selectedIndex}

                      frameProps={{ autoSize: 'height' }}
                      views={images}
                      isModal
                      isFullscreen
                    />
                  </Modal>
                ) : null}
              </ModalGateway>
            </Fragment>
        );
    }
}

export default ImageLightbox