import types from './../actionTypes'
import config from './../../config'
import {getCookie} from './../../utils/cookie'
import {getToken} from './../../utils/Token'
import axios from 'axios'
import {UploadFile} from './fileuploadAction'

export function FetchProducts(query) {
    return async (dispatch) => {
        // try {
            dispatch({
                type: types.PRODUCT_PENDING,
                payload: null
            })

            axios.get(config.BASE_API + '/api/products', {
                params: {
                    // '_limit': 10,
                    ...query
                },
                headers: {
                    Authorization: `Bearer ${getToken()}`
                }
            })
            .then(response => {
                dispatch({
                    type: types.FETCH_PRODUCTS_SUCCESS,
                    payload: response.data
                });
            })
            .catch(error => {
                // Handle error.
                console.log('An error occurred:', error);
            });
       
    }
}


export const FetchProductByID = (id) => {
    return async (dispatch) => {
        dispatch({
            type: types.PRODUCT_PENDING,
            payload: null
        })
        axios.get(config.BASE_API + '/api/products/' + id, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        })
        .then(response => {
            dispatch({
                type: types.FETCH_PRODUCT_SUCCESS,
                payload: {
                    product: response.data
                }
            });
        })
        .catch(error => {
          // Handle error.
          console.log('An error occurred:', error);
        });
    }
}


export const NewProduct = () => {
    return async (dispatch) => {
        dispatch({
            type: types.NEW_PRODUCT,
            payload: {}
        });
    }
}


export const StoreProduct = (data, files) => {
    return async (dispatch) => {

        dispatch({
            type: types.PRODUCT_PENDING,
            payload: null
        });
    
        axios.post(config.BASE_API + '/api/products', data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        })
        .then(response => {
            
            if(response.data && response.data.data && response.data.data.id) {
                if(files) {
                    let p_arr = [];
                    Object.keys(files).forEach( f => {
                        if(files[f]) {
                            let file_arr = [];
                            Object.keys(files[f]).forEach(file => {
                                file_arr.push(files[f][file])
                            })
                            p_arr.push(
                                UploadFile({
                                    'files[]': file_arr,
                                    path: 'product/' + f,
                                    refId: response.data.data.id,
                                    ref: 'products',
                                    field: f
                                })
                            )
                        }
                    })
                    Promise.all(p_arr).then(() => {
                        // console.log(results)
                        dispatch({
                            type: types.STORE_PRODUCT_SUCCESS,
                            payload: response.data && response.data.data ? response.data.data : {}
                        });
                    })
                } else {
                    dispatch({
                        type: types.STORE_PRODUCT_SUCCESS,
                        payload: response.data && response.data.data ? response.data.data : {}
                    });
                }
                
            } else {
                dispatch({
                    type: types.STORE_PRODUCT_SUCCESS,
                    payload: response.data && response.data.data ? response.data.data : {}
                });
            }
        })
        .catch(error => {
          // Handle error.
          console.log('An error occurred:', error);
        });
    }
}

export const UpdateProduct = (data, files) => {
    return async (dispatch) => {

        dispatch({
            type: types.PRODUCT_PENDING,
            payload: null
        });
    
        axios.put(config.BASE_API + '/api/products/' + data.id, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        })
        .then(response => {
            if(response.data && response.data.data && response.data.data.id) {
                if(files) {
                    let p_arr = [];
                    Object.keys(files).forEach( f => {
                        if(files[f]) {
                            let file_arr = [];
                            Object.keys(files[f]).forEach(file => {
                                file_arr.push(files[f][file])
                            })
                            p_arr.push(
                                UploadFile({
                                    'files[]': file_arr,
                                    path: 'product/' + f,
                                    refId: response.data.data.id,
                                    ref: 'products',
                                    field: f
                                })
                            )
                        }
                    })
                    Promise.all(p_arr).then(() => {
                        // console.log(results)
                        dispatch({
                            type: types.STORE_PRODUCT_SUCCESS,
                            payload: response.data && response.data.data ? response.data.data : {}
                        });
                    })
                } else {
                    dispatch({
                        type: types.STORE_PRODUCT_SUCCESS,
                        payload: response.data && response.data.data ? response.data.data : {}
                    });
                }
                
            } else {
                dispatch({
                    type: types.STORE_PRODUCT_SUCCESS,
                    payload: response.data && response.data.data ? response.data.data : {}
                });
            }
            
        })
        .catch(error => {
          // Handle error.
          console.log('An error occurred:', error);
        });
    }
}

export const DeleteProduct = (data) => {
    return async (dispatch) => {
        dispatch({
            type: types.PRODUCT_PENDING,
            payload: null
        });

        axios.delete(config.BASE_API + '/api/products/' + data.id, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        })
        .then(response => {
            dispatch({
                type: types.STORE_PRODUCT_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
          // Handle error.
          console.log('An error occurred:', error);
        });
    }
}

export const OnChangeForm = (e, data) => {
    return async (dispatch) => {
        data[e.target.name] = e.target.value
        dispatch({
            type: types.PRODUCT_FORM_CHANGE,
            payload: data
        });
    }
}


export const StoreProductReceive = (data) => {
    return async (dispatch) => {

        dispatch({
            type: types.PRODUCT_PENDING,
            payload: null
        });
    
        axios.post(config.BASE_API + '/api/products/receivings', data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        })
        .then(response => {
            if(response.data && response.data.data) {
                dispatch({
                    type: types.STORE_PRODUCT_SUCCESS,
                    payload: response.data && response.data.data ? response.data.data : {}
                });
            }

        })
        .catch(error => {
          // Handle error.
          console.log('An error occurred:', error);
        });
    }
}
