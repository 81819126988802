import React, { useState, useEffect } from "react";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import numeral from 'numeral'
import DatePicker from 'react-datetime'
import actions from '../../redux/actions'

const months = {
    m01: 'ม.ค',
    m02: 'ก.พ',
    m03: 'มี.ค',
    m04: 'เม.ย',
    m05: 'พ.ค',
    m06: 'มิ.ย',
    m07: 'ก.ค',
    m08: 'ส.ค',
    m09: 'ก.ย',
    m10: 'ต.ค',
    m11: 'พ.ย',
    m12: 'ธ.ค',
}

const useFetching = (someFetchActionCreator, params) => {
    useEffect( () => {
      someFetchActionCreator(params);
    }, [])
}

function Ledger({accountings, is_pending, accountingnumbers, actions}) {
    const [accno, setAccno] = useState('101')
    const [keyword, setKeyword] = useState(dayjs().format("YYYY-MM"))
    useFetching(actions.SetTitle, {
        title: 'บัญชี',
        subtitle: '',
        breadcrumbs: [
            {active: true, label: 'บัญชี'}
        ]
    })
    useFetching(actions.FetchAccountings, {keyword: dayjs().format("YYYY-MM")})
    useFetching(actions.FetchSettingAccountingNumbers, {})

    const acc_debits = accountings//.filter(acc => acc.debit_no === accno)
    const acc_credits = accountings//.filter(acc => acc.credit_no === accno)
    
    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="box box-default">
                    <div className="box-header with-border">
                        <h3 className="box-title">บัญชีแยกประเภท</h3>
                    </div>

                    <div className="box-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row form-group">
                                    <div className="col-sm-6" style={{display: 'flex'}}>
                                        <div style={{flex: 1, marginRight: 5}}>
                                            <select 
                                                className="form-control" 
                                                value={accno}
                                                onChange={(e) => { 
                                                    setAccno(parseInt(e.target.value))
                                                    actions.FetchAccountings({accounting_no: e.target.value, keyword })
                                                }}
                                            >
                                                {accountingnumbers && accountingnumbers.map(n => <option value={n.accounting_no} key={n.accounting_no}>{n.accounting_no} {n.accounting_name}</option>)}
                                            </select>
                                        </div>
                                        <div style={{flex: 1}}>
                                            <DatePicker 
                                                onChange={(d) => {
                                                    setKeyword(d.format("YYYY-MM"))
                                                    actions.FetchAccountings({accounting_no: accno, keyword: d.format("YYYY-MM")})
                                                }} 
                                                value={keyword} 
                                                viewMode="months" 
                                                dateFormat="YYYY-MM" 
                                                closeOnSelect 
                                                inputProps={{placeholder: 'เดือน...'}} 
                                                timeFormat={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 text-right">
                                        
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12" style={{display: 'flex'}}>
                                        <div className="table-responsive" style={{flex: 1}}>
                                            <table id="tbProduct" className="table table-bordered" cellSpacing="0" width="100%">
                                                <thead>
                                                    <tr>
                                                        <th width="10%" colSpan="2" className="text-center">พ.ศ. {keyword ? parseInt(dayjs(keyword).format("YYYY")) + 543 : ''}</th>
                                                        <th width="30%" rowSpan="2" className="text-center">รายการ</th>
                                                        <th width="5%" rowSpan="2" className="text-center">หน้าบัญชี</th>
                                                        <th width="10%" colSpan="2" className="text-center">เดบิต</th>
                                                    </tr>
                                                    <tr>
                                                        <th className="text-center">เดือน</th>
                                                        <th className="text-center">วันที่</th>
                                                        <th className="text-center">บาท.</th>
                                                        <th className="text-center">สต.</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        acc_debits && acc_debits.length > 0 ?
                                                            acc_debits.map(acc => {
                                                                return (
                                                                    <tr key={acc.id}>
                                                                        <td style={{borderBottom: '2px solid #efefef'}} className="text-center">{acc.journal_date ? months['m'+dayjs(acc.journal_date).format("MM")] : ''}</td>
                                                                        <td style={{borderBottom: '2px solid #efefef'}} className="text-center">{acc.journal_date ? dayjs(acc.journal_date).format("d") : ''}</td>
                                                                        <td>{acc.credit_name}</td>
                                                                        <td className="text-center">รว.1</td>
                                                                        <td className="text-right">{acc.debit_price ? numeral(acc.debit_price).format('0,0') : ''}</td>
                                                                        <td>{acc.debit_price ? numeral(acc.debit_price).format('.00').substring(1) : ''}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        : <tr><td colSpan="10">ไม่พบข้อมูล</td></tr>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="table-responsive" style={{flex: 1}}>
                                            <table id="tbProduct" className="table table-bordered" cellSpacing="0" width="100%">
                                                <thead>
                                                    <tr>
                                                        <th width="10%" colSpan="2" className="text-center">พ.ศ. {keyword ? parseInt(dayjs(keyword).format("YYYY")) + 543 : ''}</th>
                                                        <th width="30%" rowSpan="2" className="text-center">รายการ</th>
                                                        <th width="5%" rowSpan="2" className="text-center">หน้าบัญชี</th>
                                                        <th width="10%" colSpan="2" className="text-center">เครดิต</th>
                                                    </tr>
                                                    <tr>
                                                        <th className="text-center">เดือน</th>
                                                        <th className="text-center">วันที่</th>
                                                        <th className="text-center">บาท.</th>
                                                        <th className="text-center">สต.</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        acc_credits && acc_credits.length > 0 ?
                                                            acc_credits.map(acc => {
                                                                return (
                                                                    <tr key={acc.id}>
                                                                        <td style={{borderBottom: '2px solid #efefef'}} className="text-center">{acc.journal_date ? months['m'+dayjs(acc.journal_date).format("MM")] : ''}</td>
                                                                        <td style={{borderBottom: '2px solid #efefef'}} className="text-center">{acc.journal_date ? dayjs(acc.journal_date).format("d") : ''}</td>
                                                                        <td>{acc.debit_name}</td>
                                                                        <td className="text-center">รว.1</td>
                                                                        <td className="text-right">{acc.debit_price ? numeral(acc.debit_price).format('0,0') : ''}</td>
                                                                        <td>{acc.debit_price ? numeral(acc.debit_price).format('.00').substring(1) : ''}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        : <tr><td colSpan="10">ไม่พบข้อมูล</td></tr>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        is_pending &&
                        <div className="overlay">
                            <i className="fa fa-refresh fa-spin"></i>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        accountings: state.accountingStore.accountings,
        is_pending: state.accountingStore.is_pending,
        accountingnumbers: state.settingStore.accountingnumbers,
    }
}
  
  const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Ledger)
