import React from "react";
import PropTypes from "prop-types";

// import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
// import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
// import MainFooter from "../components/layout/MainFooter";

const LoginLayout = ({ children }) => {
  document.body.classList.add('login-page');
  document.body.classList.remove('skin-blue');
  document.body.classList.remove('sidebar-mini');
  document.body.classList.remove('fixed');
  return (
    <div className="login-box">
        <div className="login-logo">
            <a href="/"><b>J</b>STOCK</a>
        </div>

        {children}
    </div>
  )
}


LoginLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

LoginLayout.defaultProps = {
  noNavbar: false,
  noFooter: false
};

export default LoginLayout;
