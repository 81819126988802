import React from "react";
import PropTypes from "prop-types";
import {Link} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from './../redux/actions/producttypesAction'
import * as titleActions from './../redux/actions/titleAction'
import Pagination from './../components/common/Pagination'
import FormSearch from './../components/common/FormSearch'
import swal from 'sweetalert'
// import Popper from './../components/common/Popper'

class ProductTypes extends React.Component {

	componentDidMount() {
        this.props.actions.SetTitle({
            title: 'ประเภทสินค้า',
            subtitle: '',
            breadcrumbs: [
                {active: true, label: 'ประเภทสินค้า'}
            ]
        })
		this.props.actions.FetchProductTypes()
	}

	
	handlePageClick = (obj) => {
		// let {caregivers_count} = this.state
		// let start = (caregivers_count - (caregivers_count - ((obj.selected + 1) * 10))) - 10
		this.props.actions.FetchProductTypes({'page': obj.selected})
    }
    
    deleteData = (item) => {
        swal({
            title: "Are you sure?",
            text: "Confirm delete!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                this.props.actions.DeleteProductType(item)
                swal("Poof! Your imaginary file has been deleted!", {
                    icon: "success",
                });
                this.props.actions.FetchProductTypes()
            } else {
                swal("Your imaginary file is safe!");
            }
        });
    }

	render() {
		let {producttypes, producttypes_count} = this.props
		return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="box box-primary">
                        <div className="box-header with-border">
                            <h3 className="box-title">ประเภทสินค้า</h3>
                        </div>

                        <div className="box-body">
                            <div className="row form-group">
                                <div className="col-sm-6">
                                    <FormSearch onSearch={(keyword) => this.props.actions.FetchProductTypes({search: keyword})}/>
                                </div>
                                <div className="col-sm-6 text-right">
                                    <Link to="/producttypes/create" className="btn btn-primary"><i className="fa fa-plus"></i> Create</Link>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12" style={{position: 'relative'}}>

                                    <div className="table-responsive">
                                        <table id="tbProduct" className="table table-bordered table-hover" cellSpacing="0" width="100%">
                                            <thead>
                                                <tr>
                                                    <th width="20%"></th>
                                                    <th>ตัวย่อประเภทสินค้า</th>
                                                    <th>ชื่อประเภทสินค้า</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    producttypes && producttypes.length > 0 ? 
                                                        producttypes.map((item, index) => {
                                                            return (
                                                                <tr key={item.id} >
                                                                    <td>
                                                                        <Link className="btn btn-warning btn-xs" to={'/producttypes/edit/'+item.id}><i className="fa fa-pencil"></i> แก้ไข</Link>
                                                                        {' '}
                                                                        <a className="btn btn-danger btn-xs" onClick={() => this.deleteData(item)}><i className="fa fa-trash"></i> ลบ</a>
                                                                    </td>
                                                                    <td className="text-center">{item.prefix}</td>
                                                                    <td className="text-center">{item.name}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    : 
                                                        <tr>
                                                            <td className="danger" colSpan="18"> ไม่พบข้อมูล</td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="row" v-if="meta">
                                {/* <div className="col-sm-6" style="padding: 6px 20px;">Showing {{meta.from}} to {{meta.to}} of {{meta.total}} entries</div> */}
                                <div className="col-sm-6"></div>
                                <div className="col-sm-6 text-right">    
                                    <Pagination
                                        itemCount={producttypes_count}
                                        rowCount={10}
                                        onPageChange={this.handlePageClick}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

		)
	}
}
    


ProductTypes.propTypes = {
  /**
   * The small stats dataset.
   */
  smallStats: PropTypes.array
};

ProductTypes.defaultProps = {
  
};


const mapStateToProps = (state, ownProps) => {
	return {
		...state.producttypesStore
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({...actions, ...titleActions}, dispatch)
	}
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ProductTypes)
