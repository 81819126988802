import types from './../actionTypes'
import config from './../../config'
import {getCookie} from './../../utils/cookie'
import axios from 'axios'

export function FetchSettings(query) {
    return async (dispatch) => {
        // try {
            dispatch({
                type: types.SETTING_PENDING,
                payload: null
            })

            axios.get(config.BASE_API + '/api/settings', {
                params: {
                    // '_limit': 10,
                    ...query
                },
                headers: {
                    Authorization: `Bearer ${getCookie("token")}`
                }
            })
            .then(response => {
                dispatch({
                    type: types.FETCH_SETTINGS_SUCCESS,
                    payload: {
                        setting: response.data
                    }
                });
            })
            .catch(error => {
                // Handle error.
                console.log('An error occurred:', error);
            });
       
    }
}

export function FetchSettingAccountingNumbers(query) {
    return async (dispatch) => {
        // try {
            dispatch({
                type: types.SETTING_PENDING,
                payload: null
            })

            axios.get(config.BASE_API + '/api/settings/accountingnumbers', {
                params: {
                    // '_limit': 10,
                    ...query
                },
                headers: {
                    Authorization: `Bearer ${getCookie("token")}`
                }
            })
            .then(response => {
                dispatch({
                    type: types.FETCH_SETTING_ACCOUNTING_NUMBERS_SUCCESS,
                    payload: {
                        accountingnumbers: response.data
                    }
                });
            })
            .catch(error => {
                // Handle error.
                console.log('An error occurred:', error);
            });
       
    }
}

export const UpdateSetting = (data) => {
    return async (dispatch) => {

        dispatch({
            type: types.SETTING_PENDING,
            payload: null
        });
    
        axios.put(config.BASE_API + '/api/settings', data, {
            headers: {
                Authorization: `Bearer ${getCookie("token")}`
            }
        })
        .then(response => {
            dispatch({
                type: types.UPDATE_SETTING_SUCCESS,
                payload: {
                    setting: response.data
                }
            });
        })
        .catch(error => {
          // Handle error.
          console.log('An error occurred:', error);
        });
    }
}


export const UpdateSettingAccountingNumber = (data) => {
    return async (dispatch) => {

        dispatch({
            type: types.SETTING_PENDING,
            payload: null
        });
    
        axios.put(config.BASE_API + '/api/settings/accountingnumbers', data, {
            headers: {
                Authorization: `Bearer ${getCookie("token")}`
            }
        })
        .then(response => {
            dispatch({
                type: types.UPDATE_SETTING_ACCOUNTING_NUMBERS_SUCCESS,
                payload: {
                    setting: response.data
                }
            });
        })
        .catch(error => {
          // Handle error.
          console.log('An error occurred:', error);
        });
    }
}
