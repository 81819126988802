import React from "react";
import { Container, Row, Col } from "shards-react";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import actions from './../redux/actions'
import UserDetails from "../components/user-profile-lite/UserDetails";
import UserAccountDetails from "../components/user-profile-lite/UserAccountDetails";

class Profile extends React.Component {

    state = {
        form: {},
    }

    componentDidMount() {
        this.props.actions.SetTitle({
            title: 'โปรไฟล์',
            subtitle: '',
            breadcrumbs: [
                {active: true, label: 'โปรไฟล์'}
            ]
        })
    }

    handleSave = () => {
        
    }

    render() {
        
        return (
            <Container fluid className="main-content-container px-4">
                <Row>
                    <Col lg="3">
                        <UserDetails />
                    </Col>
                    <Col lg="9">
                        <UserAccountDetails />
                    </Col>
                </Row>
            </Container>    
        )
    }
}
    

Profile.propTypes = {

};

Profile.defaultProps = {

};


const mapStateToProps = (state, ownProps) => {
    return {
        ...state.housesStore
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Profile)
