import React from "react";
import PropTypes from "prop-types";
import Chart from "../../utils/chart";
import dayjs from 'dayjs'

function pad(num, size) {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
}

class ChartSaleSummary extends React.Component {
	constructor(props) {
		super(props);

		let year = dayjs().format("YYYY")
		let labels = [];
		for(let i = 1;i <= 12;i++) {
			labels.push(`${year}-${pad(i, 2)}`)
		}

		this.state = {
			data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			labels: labels
		}
		this.canvasRef = React.createRef();
	}

	componentWillReceiveProps(nextProps) {
		if(this.props.data !== nextProps.data) {
			let {data} = this.state
			Object.keys(nextProps.data).forEach(d => {
				let y = d.split('-')
				data[parseInt(y[1])-1] = nextProps.data[d]
			})
			this.setState({ data  })
			this.BarChart.config.data.datasets.forEach((dataset) => {
				dataset.data = data
			})
			this.BarChart.update()
		}
	}

	componentDidMount() {
		let {labels, data} = this.state
		const BarChart = new Chart(this.canvasRef.current, {
			type: 'bar',
			data: {
			labels: labels,
			datasets: [{
				label: '# of Tomatoes',
				data: data,
				backgroundColor: 'rgba(255, 99, 132, 0.2)',
				borderColor: 'rgba(255,99,132,1)',
				borderWidth: 1
			}]
			},
			options: {
			responsive: false,
			legend: {
				display: false
			},
			scales: {
				xAxes: [{
				ticks: {
					maxRotation: 90,
					minRotation: 90
				}
				}],
				yAxes: [{
				ticks: {
					beginAtZero: true
				}
				}]
			}
			}
		});

		// Render the chart.
		BarChart.render();
		this.BarChart = BarChart
	}

	render() {
		return (
			<canvas height="300" ref={this.canvasRef} style={{ width: "100%" }}/>
		)
	}
}

ChartSaleSummary.propTypes = {
  	data: PropTypes.object
};

ChartSaleSummary.defaultProps = {
  
}

export default ChartSaleSummary
