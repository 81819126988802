import React from "react";
import { Redirect } from "react-router-dom";
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'

// 
import actions from './redux/actions'
// Layout Types
import { DefaultLayout, LoginLayout } from "./layouts";

// Route Views
import LoginView from "./views/Login";
import DashboardView from "./views/Dashboard";
import ProfileView from "./views/Profile";
import SaleFormView from "./views/Sale/SaleForm";
import SaleListView from "./views/Sale/SaleList";
import InstallmentListView from "./views/Sale/Installments";
import PurchaseFormView from "./views/Purchase/PurchaseForm";
import PurchaseListView from "./views/Purchase/PurchaseList";
import ProductView from "./views/Products";
import ProductBalanceView from "./views/Product/ProductBalance";
import ProductFormView from "./views/ProductForm";
import ProductReceiveFormView from "./views/Product/ProductReceiveForm";
import ProductTypeView from "./views/ProductTypes";
import ProductTypeFormView from "./views/ProductTypeForm";
import AccountingListView from "./views/AccountingList";
import AccountingFormView from "./views/AccountingForm";
import UserView from "./views/Users";
import UserFormView from "./views/UserForm";
import RoleView from "./views/Role";
import RoleFormView from "./views/Role/RoleForm";
import SettingView from "./views/Setting";
import AccountingNumberView from "./views/AccountingNumber";
import AccountingLedgerView from "./views/Accounting/Ledger";

import {getCookie} from './utils/cookie'
// import {CheckAuth} from './flux/actions/authenticateAction'
import AuthStore from './flux/stores/AuthenticateStore'

const checkToken = () => {
    let token = getCookie('token')
    if(token) {
        return true;
    }
    return false
}

 class CheckAuthenticate extends React.Component {
	state = {
		is_token: true,
		user: AuthStore.getUserState()
	}

	componentWillMount = () => {
		if(checkToken()) {
			// browserHistory.push('/login')
			// CheckAuth()
			this.props.actions.GetCurrentUser()
		} else {
			this.setState({ is_token: false })
		}
		// AuthStore.addChangeListener('AUTHENTICATE_SET_USER', this.onUserChange);
	}

	// componentWillUnmount = () => {
		// AuthStore.removeChangeListener('AUTHENTICATE_SET_USER', this.onUserChange);
	// }
	
	// onUserChange = () => {
	// 	this.setState({
	// 		user: AuthStore.getUserState()
	// 	})
	// }

	render() {
		let {is_token} = this.state
		return is_token ? <div>{this.props.children}</div> : <Redirect to="/login" />
	}
}

const AuthWrapper = connect(
	(state) => {
		return {
			user: state.authStore.user,
		}
	}, (dispatch) => {
		return {
			actions: bindActionCreators(actions, dispatch)
		}
	})(CheckAuthenticate)

export default [
	{
		path: "/login",
		layout: LoginLayout,
		component: LoginView
	},
	{
		path: "/",
		exact: true,
		layout: DefaultLayout,
		auth: true,
		// component: () => <Redirect to="/login" />
		component: AuthWrapper,
		children: [
			{
				path: "/",
				layout: DefaultLayout,
				exact: true,
				auth: true,
				component: DashboardView
			},
			{
				path: "/profile",
				layout: DefaultLayout,
				exact: true,
				auth: true,
				component: ProfileView
			},
			{
				path: "/dashboard",
				layout: DefaultLayout,
				exact: true,
				auth: true,
				component: DashboardView
			},
			{
				// Sale
				name: "Sale",
				path: "/sales",
				layout: DefaultLayout,
				exact: true,
				auth: true,
				component: SaleListView
			},
			{
				// Sale
				name: "SaleCreate",
				path: "/sales/create",
				layout: DefaultLayout,
				exact: true,
				auth: true,
				component: SaleFormView
			},
			{
				// Sale Edit
				name: "SaleEdit",
				path: "/sales/edit/:id",
				layout: DefaultLayout,
				auth: true,
				exact: true,
				component: SaleFormView
			},
			{
				// Installments
				name: "Installments",
				path: "/sales/installments",
				layout: DefaultLayout,
				auth: true,
				exact: true,
				component: InstallmentListView
			},
			{
				// Purchase
				name: "Purchase",
				path: "/purchases",
				layout: DefaultLayout,
				exact: true,
				auth: true,
				component: PurchaseListView
			},
			{
				// Purchase Create
				name: "PurchaseCreate",
				path: "/purchases/create",
				layout: DefaultLayout,
				exact: true,
				auth: true,
				component: PurchaseFormView
			},
			{
				// Sale Edit
				name: "PurchaseEdit",
				path: "/purchases/edit/:id",
				layout: DefaultLayout,
				auth: true,
				exact: true,
				component: PurchaseFormView
			},
			{
				// Product
				name: "Product",
				path: "/products",
				layout: DefaultLayout,
				exact: true,
				auth: true,
				component: ProductView
			},
			{
				// Product
				name: "ProductCreate",
				path: "/products/create",
				layout: DefaultLayout,
				auth: true,
				exact: true,
				component: ProductFormView
			},
			{
				// Product Receive
				name: "ProductReceive",
				path: "/products/receive",
				layout: DefaultLayout,
				auth: true,
				exact: true,
				component: ProductReceiveFormView
			},
			{
				// Product Balance
				name: "ProductBalance",
				path: "/products/balance",
				layout: DefaultLayout,
				auth: true,
				exact: true,
				component: ProductBalanceView
			},
			{
				// Product Edit
				name: "ProductEdit",
				path: "/products/edit/:id",
				layout: DefaultLayout,
				auth: true,
				exact: true,
				component: ProductFormView
			},
			{
				// Product Type
				name: "ProductType",
				path: "/producttypes",
				layout: DefaultLayout,
				exact: true,
				auth: true,
				component: ProductTypeView
			},
			{
				// Product Type Create
				name: "ProductTypeCreate",
				path: "/producttypes/create",
				layout: DefaultLayout,
				exact: true,
				auth: true,
				component: ProductTypeFormView
			},
			{
				// Product Type Edit
				name: "ProductTypeEdit",
				path: "/producttypes/edit/:id",
				layout: DefaultLayout,
				auth: true,
				exact: true,
				component: ProductTypeFormView
			},

			{
				// AccountingList
				name: "AccountingList",
				path: "/accountings",
				layout: DefaultLayout,
				auth: true,
				exact: true,
				component: AccountingListView
			},
			{
				// Accounting Ledger
				name: "Accountung Ledger",
				path: "/accountings/ledgers",
				layout: DefaultLayout,
				exact: true,
				auth: true,
				component: AccountingLedgerView
			},
			{
				// Accounting Number
				name: "Accountung Number",
				path: "/accountings/numbers",
				layout: DefaultLayout,
				exact: true,
				auth: true,
				component: AccountingNumberView
			},
			{
				// AccountingForm
				name: "AccountingForm",
				path: "/accountings/create",
				layout: DefaultLayout,
				auth: true,
				exact: true,
				component: AccountingFormView
			},

			{
				// AccountingForm Edit
				name: "AccountingFormEdit",
				path: "/accountings/edit/:id",
				layout: DefaultLayout,
				auth: true,
				exact: true,
				component: AccountingFormView
			},
			{
				// User
				name: "User",
				path: "/users",
				layout: DefaultLayout,
				exact: true,
				auth: true,
				component: UserView
			},
			{
				// User Create
				name: "UserCreate",
				path: "/users/create",
				layout: DefaultLayout,
				exact: true,
				auth: true,
				component: UserFormView
			},
			{
				// User Role Edit
				name: "UserRolesEdit",
				path: "/users/roles/:id/edit",
				layout: DefaultLayout,
				exact: true,
				auth: true,
				component: RoleFormView
			},
			{
				// User Role Create
				name: "UserRolesCreate",
				path: "/users/roles/create",
				layout: DefaultLayout,
				exact: true,
				auth: true,
				component: RoleFormView
			},
			{
				// User Role
				name: "UserRoles",
				path: "/users/roles",
				layout: DefaultLayout,
				exact: true,
				auth: true,
				component: RoleView
			},
			{
				// Product Type Edit
				name: "UserEdit",
				path: "/users/edit/:id",
				layout: DefaultLayout,
				auth: true,
				exact: true,
				component: UserFormView
			},
			{
				// Setting
				name: "Setting",
				path: "/settings",
				layout: DefaultLayout,
				exact: true,
				auth: true,
				component: SettingView
			},
		]
	},
];
