import React from 'react'
import { FormInput } from "shards-react";
import DatePicker from 'react-datetime'
import 'react-datetime/css/react-datetime.css'

const FieldDatePicker = ({label, name, value, error, handleChange}) => {

    return (
        <div className={`row form-group ${error ? 'has-error' : ''}`}>
            <div className="col-sm-4 control-label text-right">{label}</div>
            <div className="col-sm-8">
                <DatePicker value={value ? new Date(value) : new Date()} onChange={(v) => handleChange({target: {name: name, value: v.format("YYYY-MM-DD HH:mm:ss")}})}/>
                {error && <span className="help-block">{error}</span>}
            </div>
        </div>
    )
}

export default FieldDatePicker